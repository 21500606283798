import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { useForm } from "react-hook-form";
import { _ } from "../../locale";
import ReactTabs from "../../components/common/ReactTabs";

const DashboardChart = ({configureOption, classname=""}) => {

console.log("LineChartContributionOptions", configureOption)

  return (
    <div className="" style={{ maxWidth: classname ?  null : '550px' }}>
        <HighchartsReact highcharts={Highcharts} options={configureOption} />
 
    </div>
  );
};
export default DashboardChart;
