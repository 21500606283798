import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { limit } from "../../constants";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import PageHeading from "../../components/common/PageHeading";
import MerchantViewModal from "../../components/Modal/MerchentViewModal";
import MatchSkelton from "./MatchSkeleton";
import MatchSlide from "./MatcheSlide";
import AddEditMatches from "../../components/Modal/AddEditMatches";
import { getMatchData } from "../../actions/match";
import Pagination from "react-js-pagination";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { getUniversityListing } from "../../actions/University";
import ReactSelectWithInfiniteScroll from "../../components/FormFields/ReactInfiniteSingleSelect";

function ManageMatch() {
  const [showLoader, setShowLoader] = useState(false);
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const { control, setValue, handleSubmit } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  let search = searchParams.get("searchText");
  const [showMerchant, setShowMerchant] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  const navigate = useNavigate();

  const [id, setId] = useState();
  const initalFetchData = useRef(true);

  // console.log(activePage,"dsvhbsd")

  const modalProperty = (id) => {
    setId(id);
    setShowMerchant(true);
  };

  const fetchData = async (page, filters) => {
    try {
      const params = {
        ...filters,
        page,
        perPage: 20,
      };
      console.log(params, "sdcjsdhb");
      const resp = await getMatchData(params);
      setList(resp?.data?.responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };

  const fetchUniversityData = async (page, type) => {
    try {
      const resp = await getUniversityListing({ page, perPage: 10 });
      const newArray = resp?.data?.responseData?.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setUniversityList(newArray);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      // setShowLoader(false);
    }
  };

  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    if (data.searchByUniversity !== "") {
      filters.universityId = data.searchByUniversity;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
      if (filtersData.universityId) {
        queryParams.push(`universityId=${filtersData?.universityId}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";
    const universityId = searchParams.has("universityId")
      ? searchParams.get("universityId")
      : "";

    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }
    if (universityId !== "") {
      newFilters.universityId = universityId;
    }

    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
    setValue("searchByUniversity", "");
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  useEffect(() => {
    fetchUniversityData(1);
  }, []);
  // console.log(filtersData,"sdjhbfbds")

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const modalCloseHandler = () => {
    setShowMerchant(false);
    navigate("/dashboard/merchent");
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading heading={"GAMES"} count={list?.totalRecords || 0} />
              <div className="flex-shrink-0">
                <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("CREATE_GAME")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="leads-search-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="d-flex">
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by title")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
              {/* <Inputfield
                  control={control}
                  name={"searchByUniversity"}
                  placeholder={_("Search by University")}
                  normalize={(e) => e.replace(/^\s+/g, "")}
                  type="search"
                  inputClassName={"form-control"}
                  // rules={{
                  //   required: { value: false, message: _("NAME_REQUIRED") },
                  // }}
                /> */}
            </div>
            <div className="game-select-container">
              {/* <ReactSelectField
                
                      control={control}
                      name="searchByUniversity"
                      label={_("ROLE")}
                      placeholder={_("Select University")}
                      options={universityList}
                      optionValue="value"
                      optionLabel="label"
                      onChange={(selectedValue) =>
                        // setContribution((prevState) => ({
                        //   prevState,
                        //   contributionTo: selectedValue,
                        // }))
                        console.log("selectedValue", selectedValue)
                      }
                    /> */}
              <ReactSelectWithInfiniteScroll
                control={control}
                name="searchByUniversity"
                label={_("ROLE")}
                placeholder={_("Select University")}
                options={universityList}
                optionValue="value"
                optionLabel="label"
                onChange={(selectedValue) =>
                  // setContribution((prevState) => ({
                  //   prevState,
                  //   contributionTo: selectedValue,
                  // }))
                  console.log("selectedValue", selectedValue)
                }
                setDataListing={setUniversityList}
                fetchApiData={getUniversityListing}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("TITLE")}</th>
                        <th scope="col">{_("SPORT")}</th>
                        <th scope="col">{_("LOCATION")}</th>
                        <th scope="col">{_("START_DATE")}</th>
                        <th scope="col">{_("END_DATE")}</th>
                        <th scope="col">{_("ACTIONS")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <MatchSkelton count={8} />
                      ) : list && list?.data && list?.data?.length > 0 ? (
                        list &&
                        list?.data &&
                        list?.data?.length > 0 &&
                        list?.data?.map((obj, index) => (
                          <MatchSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            modalProperty={modalProperty}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {list && list?.totalRecords > 20 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={20}
                  totalItemsCount={list?.totalRecords ?? 1}
                  pageRangeDisplayed={6}
                  onChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <AddEditMatches
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          // setActivePage={setActivePage}
          activePage = {activePage}
          filtersData = {filtersData}
        />
      )}
      {showMerchant && (
        <MerchantViewModal
          show={showMerchant}
          onHide={modalCloseHandler}
          id={id}
        />
      )}
    </div>
  );
}
export default ManageMatch;
