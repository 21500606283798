import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { updateUiversityStatus } from "../../actions/University";
import { useState } from "react";
import { _ } from "../../locale";
import toast from "react-hot-toast";
import SuportsAddEditModal from "../../components/Modal/SuportsAddEditModal";
import { deleteSports } from "../../actions/Suports";

function SuportsSlide({ slideData, index, srno, fetchData, activePage, filtersData }) {
  const [status, setStatus] = useState(slideData?.status);
  const [isChecked, setIsChecked] = useState(slideData.status == "1");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  console.log(fetchData, "sdcvhsdcvjsd");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteSports(slideData?.id);
              fetchData(1);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == "1" ? 0 : 1;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateUiversityStatus(
                { status },
                slideData?.id
              );
              fetchData();
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
  };

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>
        <figure className="sports-img-wrapper">
          <img
            width={"40px"}
            height={"40px"}
            src={
              slideData?.attachment
                ? slideData?.attachment?.filePath
                : "/assets/images/sport-default-icon.svg"
            }
            alt=""
          />
        </figure>
      </td>
      <td>
        {(slideData?.name).charAt(0).toUpperCase() +
          (slideData?.name).slice(1) ?? null}
      </td>

      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Update"
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                deleteRecord(slideData?.id);
              }}
              className="btn btn-sm btn-soft-danger newBtnDel"
              title="Delete"
            >
              <i className="mdi mdi-delete-outline"></i>
            </Link>
          </li>
        </ul>
      </td>
      {show && (
        <SuportsAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          id={slideData?.id}
          activePage={activePage}
          filtersData = {filtersData}
        />
      )}
    </tr>
  );
}

export default SuportsSlide;
