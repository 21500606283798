import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { _ } from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import { login } from "../../actions/auth";
import Spinner from "react-bootstrap/Spinner";
import PasswordInputfield from "../../components/FormFields/PasswordInputFiled";

function LoginForm() {
  // use Hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const [getPassword, setGetPassword] = useState(true);
  const navigate = useNavigate();

  const onSubmit = async (formValues) => {
    console.log(formValues);
    // navigate("/dashboard/*");
    try {
      const resp = await login({ ...formValues });
      console.log("wewe", resp);
      let result = resp?.data?.responseData ?? null;
      console.log(result, "myresult====================>");

      // const fullAccessRoles = result.Roles;
      // const permissionCodes = fullAccessRoles.flatMap((role) =>
      //   role.Permissions.map((permission) => permission.code)
      // );
      const permissionCodes = result?.Permissions;
      console.log(permissionCodes, "permissionCodes");

      // const 
      // if (resp.status === 200) {
      localStorage.setItem("jwtToken", result?.token);
      localStorage.setItem("refreshToken", result?.refreshToken);

      localStorage.setItem("permissions", JSON.stringify(permissionCodes));
      // localStorage.setItem('roles', JSON.stringify(fullAccess.roles));
      // localStorage.setItem('hasAllAccess', fullAccess.hasAllAccess);
      localStorage.setItem("auth", JSON.stringify(result?.userProfile));
      navigate("/dashboard");
      // if (
      result?.Permissions.includes("admin") ||
        result?.Permissions.includes("building-users");
      // ) {
      // } else {
      // toast.error("This panel is only for super admin!");
      // }
      // } else {
      //   if (resp?.data?.responseData?.message)
      //     toast.error(resp?.data?.responseData?.message);
      //   else if (resp?.data?.message) toast.error(resp?.data?.message);
      //   else toast.error("Something went wrong");
      // }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const showPassword = () => {
    setGetPassword(!getPassword);
  };

  console.log("++==", getPassword);

  return (
    <div className="p-2">
      <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">
            {_("EMAIL")}
          </label>

          {
            <Inputfield
              control={control}
              name={"email"}
              placeholder={_("Enter email")}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type="email"
              inputClassName={"form-control"}
              labelField={"username"}
              rules={{
                required: { value: true, message: _("EMAIL_REQUIRED") },
                pattern: {
                  value:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                  message: _("EMAIL_VALID"),
                },
              }}
            />
          }
        </div>
        <div className="mb-3">
          <label className="form-label">{_("PASSWORD")}</label>
          <div className="input-group auth-pass-inputgroup">
            <PasswordInputfield
              control={control}
              name={"password"}
              label={_("PASSWORD")}
              placeholder={_("Enter password")}
              normalize={(e) => e.replace(/^\s+/g, "")}
              type={getPassword == true ? "password" : "input"}
              inputClassName={"form-control"}
              showPassword={showPassword}
              rules={{
                required: { value: true, message: _("PASSWORD_REQUIRED") },
                minLength: {
                  value: 4,
                  message: _("PASSWORD_VALIDATE"),
                },
              }}
            />
          </div>
        </div>
        <div className="form-check"></div>

        <div className="mt-3 d-grid">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn btn-dark waves-effect waves-light d-flex justify-content-center ${
              isSubmitting ? "btn-loader" : ""
            }`}
          >
            {isSubmitting && <Spinner animation="border spiner-border-style" />}
            {_("LOGIN")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
