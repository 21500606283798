import React, { useEffect, useRef, useState } from "react";
import { _ } from "../../locale";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import moment from "moment";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import ContributionSkeleton from "../Fan/ContributionSkeleton";
import ResponsivePagination from "react-responsive-pagination";
import { Link, useSearchParams } from "react-router-dom";
import { limit } from "../../constants";
import {
  getContributionDetails,
  getContributionExportData,
} from "../../actions/contribution";
import PageHeading from "../../components/common/PageHeading";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";

const ManageContribution = () => {
  const { control, handleSubmit, reset, setError } = useForm();
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(moment(new Date()));
  const [loader, setLoader] = useState(true);
  const [list, setList] = useState(true);
  const [filtersData, setFiltersData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const initalFetchData = useRef(true);

  const onSubmit = (data) => {
    console.log(data, "ddd");
    setActivePage(1);
    let obj = {};
    if (Object.keys(data)?.length > 0) {
      for (let key in data) {
        if (key === "startDate" || key === "endDate") {
          if (data[key]) {
            obj[`${key}`] =
              key === "startDate"
                ? moment(data[key]).startOf("day").utc().format()
                : moment(data[key]).endOf("day").utc().format();
          }
        }
      }
    }
    setFiltersData(obj);
  };

  const handleChange = (value) => {
    console.log("value", value);
  };
  const constructSearchParams = () => {
    // console.log("working....", filtersData);
    const queryParams = [];
    queryParams.push(`page=${activePage}`);

    if (Object.keys(filtersData)?.length > 0) {
      for (let key in filtersData) {
        if (key === "startDate" || key === "endDate") {
          queryParams.push(`${key}=${filtersData[key]}`);
        }
      }
    }
    const queryString = queryParams.join("&");
    setSearchParams(queryString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);


  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const fetchData = async (page, filters) => {
    
    try {
      // const params = {
      //   ...filters,
      //   page,
      //   perPage: 20,
      //   status: 5,
      // };
      // const resp = await getEventDetails(params);

      const param = {
        ...filters,
        page,
        perPage: 20,
      };
      const { data } = await getContributionDetails(param);
      // console.log("responce12345", data);

      setList(data?.responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };

  const exportContributionData = async (params) => {
    try {
      const { responseData } = await getContributionExportData(params);
      console.log("responseData++++", responseData);
      // setExportData(responseData);
      const expdata = exportToExcelContributionData(responseData);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  // useEffect(()=>{
  //   exportContributionData(filtersData)
  // },[])

  const handleResetFilter = () => {
    reset();

    setActivePage(1);
    setFiltersData({});
    setStartDates(null);
    setEndDates(null);
  };

  const exportToExcelContributionData = (data) => {
    let jsonData = [];
    const json =
      data?.length > 0 &&
      data?.map((json_data) => {
        jsonData.push({
          ["Date"]: moment(json_data?.date).format('lll'),
          ["Transaction Id"]: json_data?.transactionId,
          ["Athletes"]: json_data?.athleteName,
          ["Event Name"]: json_data?.eventName,
          ["Amount"]: json_data?.amount,
        });
      });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `ContributionData-${uuidv4()}.xlsx`);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* <h5 className="fs-4 mb-3">{_("CONTRIBUTION_DETAIL")}</h5> */}
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <PageHeading heading={_("NILDEALS")} />
                <div className="flex-shrink-0">
                  <Link
                    // onClick={() => exportToExcelContributionData(exportData)}
                    onClick={() => exportContributionData(filtersData)}
                    className="btn newBtn btn-dark"
                  >
                    {_("EXPORT_TRANSACTION")}
                  </Link>
                </div>
              </div>
              <form name="filter" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex align-items-center gap-4">
                  <div className="d-flex w-50 justify-content-between align-items-center gap-3">
                    <div className="mb-3 w-50">
                      <ReactDatePicker
                        control={control}
                        name="startDate"
                        label="Start Date"
                        onChangeDate={(selectedDate) => {
                          const selectedMoment = moment(selectedDate);
                          setStartDates(selectedMoment);
                          // handleChange(selectedDate);
                        }}
                        minDate={new Date("1900/01/01")}
                        maxDate={endDates ? endDates.toDate() : null}
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                    </div>
                    -
                    <div className="mb-3 w-50">
                      <ReactDatePicker
                        control={control}
                        name="endDate"
                        label="End Date"
                        onChangeDate={(val) => {
                          setEndDates(moment(val).endOf("day"));
                          handleChange(val);
                        }}
                        selectedEndDate={endDates}
                        rules={{
                          validate: (value) => {
                            const selectedMoment = moment(value);
                            if (
                              selectedMoment.isBefore(startDates) &&
                              !selectedMoment.isSame(startDates, "day")
                            ) {
                              return "End date should be after start date";
                            }

                            return true;
                          },
                        }}
                        minDate={
                          startDates
                            ? startDates.toDate()
                            : new Date("1900/01/01")
                        }
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                    </div>
                  </div>

                  <div className="my-4 d-flex align-items-center gap-4 pt-1">
                    <button
                      className="btn newBtn btn-dark applyBtn"
                      type="submit"
                    >
                      Apply Now
                    </button>
                    <button
                      className="btn btn-danger applyBtn resetButton"
                      type="reset"
                      onClick={handleResetFilter}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
              <div className="py-3">
                <h5 className="d-flex">
                  Total Amount Contributed:
                  <h5 className="ms-3">
                    {Number(list?.metaData?.totalAmount).toFixed(2)}
                  </h5>{" "}
                </h5>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive mt-2">
                        <table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                            <tr>
                              <th>#</th>
                              <th scope="col">{_("DATE")}</th>
                              <th scope="col">{_("TRANSACTION_ID")}</th>
                              <th scope="col">{_("ATHLETES")}</th>
                              <th scope="col">{_("EVENT_NAME")}</th>
                              <th scope="col">{_("AMOUNT")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {initalFetchData.current === true ? (
                              <ContributionSkeleton count={10} />
                            ) : list?.data?.length > 0 ? (
                              list?.data?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {parseInt(
                                        (activePage - 1) * limit +
                                          parseInt(index + 1)
                                      )}
                                    </td>
                                    <td>
                                      {item?.settlement?.createdAt
                                        ? moment(
                                            item?.settlement?.createdAt
                                          ).format("lll")
                                        : "-"}
                                    </td>
                                    <td className="text-wrap">
                                      {item?.settlement?.transactionId
                                        ? item?.settlement?.transactionId
                                        : "----"}
                                    </td>
                                    <td>
                                      {item?.athlete?.userProfile?.name
                                        ? item?.athlete?.userProfile?.name
                                        : "----"}
                                    </td>
                                    <td>
                                      {item?.title ? item?.title : "----"}
                                    </td>
                                    <td>
                                      {item?.settlement?.amount
                                        ? item?.settlement?.amount
                                        : "----"}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="12" className="fs-5 text-center">
                                  No record found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {list && list?.totalRecords > 20 && (
                    <ResponsivePagination
                      current={activePage}
                      total={list?.totalPages ?? 1}
                      onPageChange={handlePageChange}
                      maxWidth={20}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageContribution;
