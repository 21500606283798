import axios from "axios";

export const getSettingData = () => {
  return axios({
    method: "GET",
    url: "/settings",
  });
};

export const updateSettingData = (data) => {
    return axios({
      method: "PATCH",
      data: data,
      url: "/settings",
    });
  };
