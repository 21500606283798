import axios from "axios";
import {BASE_URL} from "../constants";

export const USER_LISTING = 'USER_LISTING';
// export const PLAN_DETAIL = 'PLAN_DETAIL';
// export const DELETE_PLAN = 'DELETE_PLAN';
// export const PLAN_STATUS = 'PLAN_STATUS';

export const getSuportsListing = (params) => {
    return axios({
        method: 'GET',
        params: params,
        url: `/sports`
    });
};

export const createSports = (data) => {
    return axios({
        method: 'POST',
        data: data,
        url: `/sport`
    });
};

export const getSportsById = (id) => {
    return axios({
        method: 'GET',
        url: `/sport/${id}`
    });
};
export const updateSports = (id,data) => {
    return axios({
        method: 'PATCH',
        data: data,
        url: `/sport/${id}`
    });
};

export const deleteSports = (id,data) => {
    return axios({
        method: 'DELETE',
        data: data,
        url: `/sport/${id}`
    });
};




