import { useController } from "react-hook-form";
import {useState, useEffect} from 'react';
import { errorType } from "../../constants";

function InputNumberField({
  control,
  rules,
  name,
  defaultValue,
  placeholder,
  type,
  label,
  onChange,
  autocomplete,
  mainClass,
  formInWidth,
  isMax,
  normalize,
  units,
  inputClass,
  maxLength
}) {
  // usehooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const [value, setValue] = useState(field?.value ?? '')

  useEffect(() => {
    if (/^\d*\.?\d*$/.test(field?.value)) {
      setValue(field?.value);
    }
  }, [field?.value])

  //   on change Input field
  // const onInputValueChange = (e) => {
  //   const {value} = e.target;
  //   if (/^\d*\.?\d*$/.test(value)) {
  //     setValue(value);
  //   }
  // };

  //   on change Input field
  const onInputChange = (e) => {
    // send value to onChange function
    if (onChange) onChange(normalize(e.target.value.replace(/  +/g, " ")));

    // send value to hook form
    field.onChange(normalize(e.target.value.replace(/  +/g, " ")));
  };

  // on Key down
  const onInputKeyDown = (e) => {
    if (type && type == "number") {
      if (e.keyCode == "189" || e.keyCode == "69") {
        e.preventDefault();
      }
    }
  };

  const onInputBlur = (e) => {
    setValue(e.target.value.replace(/\.$/, ''))
  };

  return (
    <>
      <div
        className={`form-in ${formInWidth ? formInWidth : "w-100"} ${
          mainClass ? mainClass : ""
        } ${error ? "required-field" : ""}`}
      >
        {label && <label className="f-label">{label}</label>}
        <div className="f-in w-100">
          <input
            {...field}
            name={name}
            autoComplete={autocomplete || "on"}
            className={inputClass ? inputClass + " form-control" : "form-control"}
            min={type ? 0 : ""}
            onBlur={onInputBlur}
            max={type && isMax ? "9999" : ""}
            placeholder={placeholder}
            type={type ? type : "text"}
            value = {value}
            maxLength={maxLength}
            //onInput={onInputValueChange}
            onChange={(e) => onInputChange(e)}
          />
          {
            units && <span className="units">{units}</span>
          }
        </div>
        {errorType?.map((type) => {
          if (error?.type === type && error?.message !== "") {
            return (
              <p key={type} className="newerror-msg error-msg">
                {error?.message}
              </p>
            );
          }
        })}
      </div>
    </>
  );
}

/**
 * @property defaults
 */
InputNumberField.defaultProps = {
  defaultValue: "",
  rules: {
    // Define validation rules here
    min: 0, // Minimum value allowed (0 or greater)
  },
  errorClass: "error-msg",
  onChange: (value) => value,
  normalize: (value) => value,
};

export default InputNumberField;