import axios from "axios";
export const USER_LISTING = 'USER_LISTING';

export const createEvent = (data) => {
    return axios({
        method: 'POST',
        data: data,
        url: `/event`
    });
};


export const updateEvent = (id,data) => {
    return axios({
        method: 'PATCH',
        data:data,
        url: `/event/${id}`
    });
};


export const getEventDetails = (params) => {
    return axios({
        method: 'GET',
        params:params,
        url: `/events`
    });
};

export const getSingalEvent = (id) => {
    return axios({
        method: 'GET',
        url: `/events/${id}`
    });
};
export const deleteEvent = (id) => {
    return axios({
        method: 'DELETE',
        url: `/event/${id}`
    });
};

export const publishEvent=(id,data)=>{
    return axios({
        method: 'PATCH',
        data:data,
        url: `/event/${id}/action`
    });

}


export const getEvenetDetailsById=(id,data)=>{
    return axios({
        method: 'GET',
        // data:data,
        url: `/events/${id}`
    });

}

export const updateEventStatus = async(eventId, obj) =>{
try{
 const response = await axios({
    method: 'PATCH',
    data: obj,
    url:`/event/${eventId}/action`
 })
 return response
}catch(error){
    console.log('something went wrong while updating the event status')
  return error
}
}

export const getContributionById = (params) => {
    return axios({
        method : "GET",
        params : params,
        // url : `/events/athlete/${id}`
        url : `/admin/contribution/events`
    })
}






