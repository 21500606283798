import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { updateEventStatus } from "../../actions/events";
import toast from "react-hot-toast";
import { _ } from "../../locale";
import MessageModal from "../../components/Modal/MessageModal";
import { EVENT_STATUS } from "../../constants";

const HambergerMenu = ({
  slideData,
  setShowEvent,
  handleModalShow,
  deleteRecord,
  fetchData,
  activePage,
}) => {
  const [show, setShow] = useState(false);
  const [showMsgModel, setShowMsgModel] = useState({
    showRejectMsgModel: false,
    showCancelMsgModel: false,
  });

  const HandleStatus = async (eventId, statusId, statusAction) => {
    console.log("statusId", statusId);
    console.log("statusAction", statusAction);
    try {
      let obj = {
        status: statusAction === "Published" ? 1 : 5,
      };
      const resp = await updateEventStatus(eventId, obj);

      if (resp?.status !== 200) {
        toast.error(resp?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000, // 5 seconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const message =
          EVENT_STATUS[`${statusId}`] === "Not Published"
            ? "Event published successfully"
            : "Proof accepted successfully";
        toast.success(message, {
          position: "top-right",
          autoClose: 5000, // 5 seconds
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        fetchData(activePage);
      }
    } catch ({ request, response }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const handleToggle = () => setShow(!show);
  return (
    <Dropdown placement="left" drop={"start"}>
      <Dropdown.Toggle variant="link" className="p-0 dropdownToggle">
        <img
          src="/assets/images/menu-icon.svg"
          alt=""
          id="myDIV"
          width={35}
          height={35}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropDownMenu">
        <Dropdown.Item
          className=""
          onClick={() => {
            setShowEvent(true);
          }}
        >
          <div className="menus d-flex align-items-center justify-content-between">
            <p className="mb-0 fs-12 grey3">View</p>
            <div>
              <img
                src="/assets/images/view-icon.svg"
                alt=""
                width={16}
                height={16}
              />
            </div>
          </div>
        </Dropdown.Item>

        {/* Edit button */}

        {EVENT_STATUS[`${slideData?.status}`] == _("NOT_PUBLISHED") ||
        EVENT_STATUS[`${slideData?.status}`] == _("PUBLISHED") ||
        EVENT_STATUS[`${slideData?.status}`] == _("ACCEPTED") ? (
          <>
            <Dropdown.Item
              className=""
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
            >
              <div className="menus d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-12 grey3">Edit</p>
                <div>
                  <img
                    src="/assets/images/edit-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            {/* <Dropdown.Item
          className="d-flex align-items-center"
          onClick={() => deleteRecord(slideData?.id)}
        >
          <p className="mb-0 ps-2 fs-12 grey3">Delete</p>
        </Dropdown.Item> */}
          </>
        ) : null}
        {/*  Delete button */}

        {EVENT_STATUS[`${slideData?.status}`] === _("NOT_PUBLISHED") ? (
          <>
            <Dropdown.Item
              className=""
              onClick={() => deleteRecord(slideData?.id)}
            >
              <div className="menus d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-12 grey3">Delete</p>
                <div>
                  <img
                    src="/assets/images/delete-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className=""
              onClick={() =>
                HandleStatus(slideData?.id, slideData?.status, "Published")
              }
            >
              <div className="menus d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-12 grey3">Publish</p>
                <div>
                  <img
                    src="/assets/images/view-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
          </>
        ) : null}

        {/*  Publish button */}

        {/* {EVENT_STATUS[`${slideData?.status}`] === 'NOT_PUBLISHED' ? (
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() =>
              HandleStatus(slideData?.id, slideData?.status, "Published")
            }
          >
            <p className="mb-0 ps-2 fs-12 grey3">Publish</p>
          </Dropdown.Item>
        ) : null} */}

        {EVENT_STATUS[`${slideData?.status}`] === _("PROOF_SUBMITTED") ? (
          <>
            <Dropdown.Item
              className=""
              onClick={() =>
                HandleStatus(slideData?.id, slideData?.status, "ProofAccepted")
              }
            >
              <div className="menus d-flex align-items-center justify-content-between">
              <p className="mb-0 fs-12 grey3">Accept Proof</p>
              <div>
                  <img
                    src="/assets/images/view-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              className=""
              // onClick={() =>  HandleStatus(slideData?.id, slideData?.status, 'ProofRejected')}
              onClick={() =>
                setShowMsgModel((prevState) => ({
                  ...prevState,
                  showRejectMsgModel: true,
                }))
              }
            >
              <div className="menus d-flex align-items-center justify-content-between">
              <p className="mb-0 fs-12 grey3">Reject Proof</p>
              <div>
                  <img
                    src="/assets/images/delete-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            {/* <Dropdown.Item
              className="d-flex align-items-center"
              // onClick={() =>  HandleStatus(slideData?.id, slideData?.status, 'ProofRejected')}
              onClick={() =>
                setShowMsgModel((prevState) => ({
                  ...prevState,
                  showCancelMsgModel: true,
                }))
              }
            >
              <p className="mb-0 ps-2 fs-12 grey3">Cancel</p>
            </Dropdown.Item> */}
          </>
        ) : null}

        {EVENT_STATUS[`${slideData?.status}`] === _("PUBLISHED") ||
        EVENT_STATUS[`${slideData?.status}`] === _("PROOF_SUBMITTED") ||
        EVENT_STATUS[`${slideData?.status}`] === _("PROOF_REJECTED") ||
        EVENT_STATUS[`${slideData?.status}`] === _("ACCEPTED") ? (
          <Dropdown.Item
            className=""
            // onClick={() =>  HandleStatus(slideData?.id, slideData?.status, 'ProofRejected')}
            onClick={() =>
              setShowMsgModel((prevState) => ({
                ...prevState,
                showCancelMsgModel: true,
              }))
            }
          >
              <div className="menus d-flex align-items-center justify-content-between">
            <p className="mb-0 ps2 fs-12 grey3">Cancel</p>
            <div>
                  <img
                    src="/assets/images/delete-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
          </Dropdown.Item>
        ) : null}
      </Dropdown.Menu>

      {(showMsgModel?.showRejectMsgModel ||
        showMsgModel?.showCancelMsgModel) && (
        <MessageModal
          showMsgModel={showMsgModel}
          setShowMsgModel={setShowMsgModel}
          eventId={slideData?.id}
          fetchData={fetchData}
          activePage={activePage}
        />
      )}
    </Dropdown>
  );
};

export default HambergerMenu;
