import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { _ } from "../../locale";
import { getAthleteById } from "../../actions/athlete";
import Skeleton from "react-loading-skeleton";

function MerchantViewModal({ show, onHide, id,userId }) {
  const [loader, setLoader] = useState(false)
  const [fanList, setFanList] = useState({})
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(userId,"sdcbhsdhjc")
  const getFanDetailListing = async (id) => {
    setLoader(true)
    try {
      const resp = await getAthleteById(id);
      setFanList(resp?.data?.responseData)
      setLoader(false)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false)
    }
  };
  

  useEffect(() => {
    if (id) {
      getFanDetailListing(id)
    }
  }, [id])
  useEffect(() => {
    setSearchParams(`userid=${id}`);
  }, []);

console.log(fanList?.userPreference?.isRoundUpEnabled,"sdfjbkcsdkjv")

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      // followup-modal modalUserInfo
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Modal.Header  >
          <div className="modal-heade-top">
        
            <div className="userimg-name">
            <div>
                {fanList?.userProfile?.profileImage !== null ? (
                  <div className="image-container">
                    <img src={fanList?.userProfile?.profileImage?.filePath} />
                  </div>
                ) : (
                  <div className="image-container">
                    <img src={"/assets/images/user-default-image.webp"} />
                  </div>
                )}
              </div>
              <div>
                <h2>{fanList?.userProfile?.name}</h2>
                <h6>Merchant Id #{fanList?.id}</h6>
              </div>
            </div>
            <h6 onClick={onHide}><img src="/assets/svg/cross.png"/></h6>

          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div className="popup-header">
              {/* <h3>{fanList.userProfile?.name}</h3> */}
            </div>

            <div>
              <table className="user-details">
                <tbody className="d-flex col-md-12">
                  <div className="d-flex col-md-12">
                    <div className="col-md-12 user-info-data">
                      <tr>
                        <td className="details-heading mw_100">{_("EMAIL")}:</td>
                        <td className="mw_100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : (
                            fanList?.email !==null ? fanList?.email : "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="details-heading mw_100">{_("LOCATION")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            fanList?.address[0]?.mapAddress
                          
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("MANAGER")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.userProfile?.managerName !== null ? (
                            fanList?.userProfile?.managerName
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("MOBILE")}:</td>
                        <td className="gap-1 mw_100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.mobile !== null ? (
                          
                            <>
                              <span>{fanList?.countryCode}</span>
                             <span>{fanList?.mobile}</span>
                            </>
                                 
                          ) : (
                            "-"
                          )}

                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("LINKED_BANK_ACCOUNT")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.userFlag?.isBankLinked !== null ? (
                            <span>
                              {fanList?.userFlag?.isBankLinked === true
                                ? "True"
                                : "False"}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("ROUNDUP_STATUS")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.userPreference ? (
                            fanList?.userPreference?.isRoundUpEnabled === true ? "Enabled" : "Disabled"
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("MULTIPLIER")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.userPreference ? (
                            fanList?.userPreference?.contributionMultiplier + "X"
                          ) : (
                            "-"
                          )}
                        
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("THRESHOLD_AMOUNT")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.userPreference ? (
                            fanList?.userPreference?.roundUpAmount === 0 ? "-" :   fanList?.userPreference?.roundUpAmount
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("ROUNDUP_BALANCE")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            "-"
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("CONTRIBUTION_BALANCE")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            "-"
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("REGISTRATION_DATE")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.createdAt ? (
                            moment(fanList?.createdAt).format("lll")
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="details-heading mw_100">{_("ACTIVATION_DATE")}</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.registeredAt ? (
                            moment(fanList?.registeredAt).format("lll")
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                    </div>
                  
                    </div>

                  {/* </div> */}
                  
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        {/* </form> */}
      </Modal>
    </>
  );
}

export default MerchantViewModal;
