import axios from "axios";

export const getPotentialMerchantList = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: "/plaid/potential-merchants",
  });
};

export const getMerchantTransactionById = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: `/plaid/merchant-transactions`,
  });
};

export const getTransactionDetailsByMerchantId = (props) => {
  console.log(props, "ppp");
  return axios({
    method: "GET",
    params: props,
    url: "/merchant/analytics/overview",
  });
};
