import React from "react";
import Modal from "react-bootstrap/Modal";
import { _ } from "../../locale";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";

const ProofEventModal = ({
  showProofEventModel,
  setProofEventModel,
  eventList,
  proofId,
}) => {
  const [eventProofImage, setEventProofImage] = useState([]);
  const [Loader, setLoader] = useState(false);
  const getEventProofDetails = async (eventList) => {
    setLoader(true);
    try {
      let proofSubmitt =
        eventList?.eventActions.length > 0 &&
        eventList.eventActions.filter((ev_data) => ev_data.id === proofId);
      if (proofSubmitt) {
        console.log("proofSubmitt+++", proofSubmitt);
        setEventProofImage(proofSubmitt[0]);
      }
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };
  useEffect(() => {
    getEventProofDetails(eventList);
  }, []);

  return (
    <>
      <Modal
      size={'lg'}
        show={showProofEventModel}
        onHide={() => setProofEventModel(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-dialog-margin bg-secondary"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top d-flex justify-content-between">
            <h3>Event Proof</h3>
            <h6 onClick={()=>setProofEventModel(false)}>
              <img src="/assets/svg/cross.png" />
            </h6>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <h5 className="mb-3">Proof Message - <span className="fw-normal">{eventProofImage?.message ? eventProofImage?.message : "----"}</span> </h5>
          {/* d-flex flex-wrap justify-content-between gap-4 */}
            <div className="image-gallery" >
              {eventProofImage?.proof?.length > 0 &&
                eventProofImage?.proof?.map((proofDetails) => {
                  return (
                    <div className="image-item">
                      <img
                        style={{ width: '100%', height:'400px' }}
                        src={proofDetails?.filePath}
                        alt={proofDetails?.fileName}
                      />
                    </div>
                  );
                })}
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProofEventModal;
