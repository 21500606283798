import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { _ } from "../../locale";
import { getAthleteById } from "../../actions/athlete";
import Skeleton from "react-loading-skeleton";

function FanViewModal({ show, onHide, id, userId }) {
  const [loader, setLoader] = useState(false);
  const [fanList, setFanList] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(userId, "sdcbhsdhjc");
  const getFanDetailListing = async (id) => {
    setLoader(true);
    try {
      const resp = await getAthleteById(id);
      setFanList(resp?.data?.responseData);
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFanDetailListing(id);
    }
  }, []);
  useEffect(() => {
    setSearchParams(`userid=${id}`);
  }, []);

  console.log(fanList?.address, "sdfjbkcsdkjv");

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
        // followup-modal modalUserInfo
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <Modal.Header>
          <div className="modal-heade-top">
            <div className="userimg-name">
              <div>
                {fanList?.userProfile?.profileImage !== null ? (
                  <div className="image-container">
                    <img src={fanList?.userProfile?.profileImage?.filePath} />
                  </div>
                ) : (
                  <div className="image-container">
                    <img src={"/assets/images/user-default-image.webp"} />
                  </div>
                )}
              </div>
              <div>
                <h2>{fanList?.userProfile?.name}</h2>
                <h6>Fan Id #{fanList?.id}</h6>
              </div>
            </div>
            <h6 onClick={onHide}>
              <img src="/assets/svg/cross.png" />
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div className="popup-header">
              {/* <h3>{fanList.userProfile?.name}</h3> */}
            </div>

            <div>
              <table className="user-details">
                <tbody className="d-flex col-md-12">
                  <div className="d-flex col-md-12">
                    <div className="col-md-12 user-info-data">
                      <tr>
                        <td className="details-heading mw_100">{_("EMAIL")}:</td>
                        <td className="mw_100"> 
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.email !== null ? (
                            fanList?.email
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("MOBILE")}:</td>
                        <td className="gap-1 mw_100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.mobile !== null ? (
                            <>
                              <span>{fanList?.countryCode}</span>
                              <span>{fanList?.mobile}</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("STATUS")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.status === 1 ? (
                            "Active"
                          ) : (
                            "Inactive"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">
                          {_("REGISTRATION_DATE")}:
                        </td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.createdAt ? (
                            moment(fanList?.createdAt).format("lll")
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">{_("ADDRESS")}:</td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            <>
                              <span>{fanList?.address?.[0]?.mapAddress}</span>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">
                          {_("LINKED_BANK_ACCOUNT")}:
                        </td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            <span>
                              {fanList?.userFlag?.isBankLinked === true
                                ? "True"
                                : "False"}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">
                          {_("ROUND_UP_STATUS")}:
                        </td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            <span>
                              {fanList?.userPreference?.isRoundUpEnabled ===
                              true
                                ? "Enabled"
                                : "Disabled"}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">
                          {_("MAXIMUM_CONTRIBUTION_AMOUNT")}:
                        </td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            <span>
                              {fanList?.userPreference?.roundUpAmount
                                ? fanList?.userPreference?.roundUpAmount
                                : "-"}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading mw_100">
                          {_("GIFT_CONTRIBUTION_AMOUNT")}:
                        </td>
                        <td className="mw_100 gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : fanList?.address ? (
                            <span>
                              {fanList?.userPreference?.giftContribution
                                ? fanList?.userPreference?.giftContribution
                                : "-"}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </div>

                    {/* <div className="col-md-6 user-info-data"> */}
                  </div>

                  {/* </div> */}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>

        {/* </form> */}
      </Modal>
    </>
  );
}

export default FanViewModal;
