


import React, { useState, useEffect } from 'react';

function AthleteViewDropdown({ athleteList, idReciveHandler, selectedAthleteIds }) {
    const [selectedIds, setSelectedIds] = useState(selectedAthleteIds || []);
    const [searchInput, setSearchInput] = useState('');

    const handleCheckboxChange = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    useEffect(() => {
        if (idReciveHandler) {
            idReciveHandler(selectedIds);
        }
    }, [selectedIds, idReciveHandler]);

    useEffect(() => {
        setSelectedIds(selectedAthleteIds || []);
    }, [selectedAthleteIds]);
    console.log(selectedIds,"dcsdhjbc")

    const filteredAthleteList = athleteList?.filter((athlete) =>
        athlete?.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    return (
        <div className="athleteViewBox">
            <div className="position-relative d-flex align-items-center search_input">
                <span className="search-icon">
                    <img src="/assets/svg/search-normal.svg" alt="" />
                </span>
                <input
                    className="form-control form-control-lg w-100 p-0 fw-600 h4_1"
                    type="text"
                    placeholder="Search athlete..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
            </div>
            <ul className="ps-0 pt-3 select-dropdown-style">
                {searchInput === '' ? (
                    // Display all athletes when searchInput is empty
                    athleteList?.map((athlete, index) => (
                        <li
                            key={index}
                            className="d-flex justify-content-around align-items-center my-2 checkbox-li"
                            onClick={() => handleCheckboxChange(athlete.id)}
                        >
                            <img
                                width="50px"
                                height="50px"
                                src={athlete.profileImage !== undefined ? athlete.profileImage : "/assets/images/user-default-image.webp"}
                                alt={``}
                                className="rounded-circle"
                            />
                            <span className="athleteName">{athlete.name}</span>
                            <label
                                className="form-check-label style-checkbox"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedIds?.includes(athlete.id)}
                                    onChange={() => handleCheckboxChange(athlete.id)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))
                ) : 
                filteredAthleteList.length > 0 ? (
                    // Display filtered athletes when searchInput is not empty
                    filteredAthleteList.map((athlete, index) => (
                        <li
                            key={index}
                            className="d-flex justify-content-around align-items-center my-2 checkbox-li"
                            onClick={() => handleCheckboxChange(athlete.id)}
                        >
                            <img
                                width="50px"
                                height="50px"
                                src={athlete.profileImage !== undefined ? athlete.profileImage : "/assets/images/user-default-image.webp"}
                                alt={``}
                                className="rounded-circle"
                            />
                            <span className="athleteName">{athlete.name}</span>
                            <label
                                className="form-check-label style-checkbox"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedIds?.includes(athlete.id)}
                                    onChange={() => handleCheckboxChange(athlete.id)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </li>
                    ))
                ) : (
                    // Display message when no athletes match the search
                    <h5 className="no-athlete-found-style">No Athlete Found</h5>
                )}
            </ul>
        </div>
    );
}

export default AthleteViewDropdown;



// import React, { useState, useEffect } from 'react';
// import { useController } from 'react-hook-form';
// import { errorType } from "../../constants";

// function AthleteViewDropdown({ athleteList, idReciveHandler, selectedAthleteIds,control,name ,rules,normalize}) {
//     const [selectedIds, setSelectedIds] = useState(selectedAthleteIds || []);
//     const [searchInput, setSearchInput] = useState('');
//     const {
//         field,
//         fieldState: { error },
//       } = useController({ name, control, rules });
//       const errorMessage = selectedIds?.length > 0 ? undefined : error ;



//     const handleCheckboxChange = (id) => {
//         if (selectedIds.includes(id)) {
//             setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
//         } else {
//             setSelectedIds([...selectedIds, id]);
//         }
//     };

//     useEffect(() => {
//         if (idReciveHandler) {
//             idReciveHandler(selectedIds);
//         }
//     }, [selectedIds, idReciveHandler]);

//     useEffect(() => {
//         setSelectedIds(selectedAthleteIds || []);
//     }, [selectedAthleteIds]);

//     const filteredAthleteList = athleteList?.filter((athlete) =>
//         athlete?.name.toLowerCase().includes(searchInput.toLowerCase())
//     );


//     return (
//         <>
//         <div className="athleteViewBox">
//             <div className="position-relative d-flex align-items-center search_input">
//                 <span className="search-icon">
//                     <img src="/assets/svg/search-normal.svg" alt="" />
//                 </span>
//                 <input
//                     className="form-control form-control-lg w-100 p-0 fw-600 h4_1"
//                     type="text"
//                     placeholder="Search athlete..."
//                     value={searchInput}
//                     onChange={(e) => setSearchInput(e.target.value)}
//                 />
//             </div>
//             <ul className="ps-0 pt-3 select-dropdown-style">
//                 {searchInput === '' ? (
//                     athleteList?.map((athlete, index) => (
//                         <li
//                             key={index}
//                             className="d-flex justify-content-around align-items-center my-2 checkbox-li"
//                             onClick={() => handleCheckboxChange(athlete.id)}
//                         >
//                             <img
//                                 width="50px"
//                                 height="50px"
//                                 src={athlete.profileImage !== undefined ? athlete.profileImage : "/assets/images/user-default-image.webp"}
//                                 alt={``}
//                                 className="rounded-circle"
//                             />
//                             <span className="athleteName">{athlete.name}</span>
//                             <label
//                                 className="form-check-label style-checkbox"
//                                 onClick={(e) => e.stopPropagation()}
//                             >
//                                 <input
//                                     {...field}
//                                     name={name}
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     checked={selectedIds?.includes(athlete.id)}
//                                     onChange={() => handleCheckboxChange(athlete.id)}
//                                 />
//                                 <span className="checkmark"></span>
//                             </label>
//                         </li>
//                     ))
//                 ) : filteredAthleteList.length > 0 ? (
//                     filteredAthleteList.map((athlete, index) => (
//                         <li
//                             key={index}
//                             className="d-flex justify-content-around align-items-center my-2 checkbox-li"
//                             onClick={() => handleCheckboxChange(athlete.id)}
//                         >
//                             <img
//                                 width="50px"
//                                 height="50px"
//                                 src={athlete.profileImage !== undefined ? athlete.profileImage : "/assets/images/user-default-image.webp"}
//                                 alt={``}
//                                 className="rounded-circle"
//                             />
//                             <span className="athleteName">{athlete.name}</span>
//                             <label
//                                 className="form-check-label style-checkbox"
//                                 onClick={(e) => e.stopPropagation()}
//                             >
//                                 <input
//                                     type="checkbox"
//                                     className="form-check-input"
//                                     checked={selectedIds?.includes(athlete.id)}
//                                     onChange={() => handleCheckboxChange(athlete.id)}
//                                 />
//                                 <span className="checkmark"></span>
//                             </label>
//                         </li>
//                     ))
//                 ) : (
//                     <h5 className="no-athlete-found-style">No Athlete Found</h5>
//                 )}
//             </ul>
                
    
//         </div>
        
//           {selectedIds?.length >0 ? null : errorType?.map((type) => {
//             if (error?.type === type && error?.message !== "") {
//               return (        
//                 <span key={type} className={"error-msg"}>
//                   {errorMessage?.message}
//                 </span>
//               );
//             }
//           })}
//         </>
//     );
// }

// AthleteViewDropdown.defaultProps = {
//     defaultValue: "",
//     rules: {},
//     errorClass: "error-msg",
//     onChange: (value) => value,
//     normalize: (value) => value,
//   };

// export default AthleteViewDropdown;









