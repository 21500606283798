import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
import { getFollowListById } from "../../actions/athlete";
import toast from "react-hot-toast";
import { _ } from "../../locale";

function FanListModal({ show, onHide, id , fanName}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [fanList, setFanList] = useState({});
  const fetchrecord = async () => {
    try {
      const response = await getFollowListById(id);
      setFanList(response?.data?.responseData);
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchrecord(id);
    }
  }, [id]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <div className="userimg-name">
              <h3>{`${fanName}'s favourite athlete / team`}</h3>
            </div>
            <h6 onClick={onHide}>
              <img src="/assets/svg/cross.png" />
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div className="popup-header">
              <h4>Athletes</h4>
              <div className="d-flex flex-wrap gap-3">
                {fanList?.athleteList?.filter((item) => item?.isFollowed === 1)
                  ?.length > 0 ? (
                  fanList?.athleteList
                    ?.filter((item) => item?.isFollowed === 1)
                    .map((data, index) => (
                      <div
                        key={index}
                        className="d-flex flex-wrap align-items-center justify-content-center card_athlete"
                      >
                        <div>
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src={
                                data?.userProfile?.profileImage
                                  ? data?.userProfile?.profileImage?.filePath
                                  : "/assets/images/user-img.jpeg"
                              }
                              alt=""
                              width={"45px"}
                              height={"45px"}
                              className="rounded-circle"
                            />
                          </div>
                          <div>
                            <h5 className="mb-0 mt-1 text-center select-card-text">
                              {data?.userProfile?.name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <h6>No athlete is followed by the fan</h6>
                )}
              </div>
              <h4 className="mt-3">Teams</h4>
              <div className="d-flex flex-wrap gap-3">
                {fanList?.teamList?.filter((item) => item?.isFollowed === 1)
                  ?.length > 0 ? (
                  fanList?.teamList
                    ?.filter((item) => item?.isFollowed === 1)
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-wrap align-items-center justify-content-center card_athlete"
                        >
                          <div>
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                src={item?.attachment?.filePath}
                                alt=""
                                width={"45px"}
                                height={"45px"}
                                className="rounded-circle"
                              />
                            </div>
                            <div>
                              <h5 className="mb-0 mt-1 text-center select-card-text">
                                {item?.name}
                              </h5>
                            </div>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <h6>No team is followed by the fan</h6>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FanListModal;
