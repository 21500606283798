import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import ReactPhoneInput from "../FormFields/ReactPhoneInput";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react"
import ReactSelectField from "../FormFields/ReactSelectField";

import { createUniversity, getSingalUniversityList, getUniversityListing, updateUniversity } from "../../actions/University";
import { getSuportsListing } from "../../actions/Suports";
import { getTeamListing } from "../../actions/teams";
import { createAthlete, getAthleteById, updateAthlete } from "../../actions/athlete";
import { confirmAlert } from "react-confirm-alert";
import ReactSelectWithInfiniteScroll from "../FormFields/ReactInfiniteSingleSelect";
function AthleteAddEditModal({ fetchData, show, onHide, id,setActivePage, activePage, filtersData }) {

  console.log(activePage, id, "dsjhbcsdhjcs")
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({
  });
  const [defaultValue, setDefaultValue] = useState()
  const [universityList, setUniversityList] = useState([])
  const [sportListing, setSportListing] = useState([])
  const [teamListing, setTeamListing] = useState([])
  const [univerValue, setUniversValue] = useState()
  const [ID, setId] = useState()
  const [n, setN] = useState()

  const onSubmit = async (formData) => {
    let payload ={}
    if(id){
     payload = {
        ...formData,
        teams: formData?.teamsId,
      }
    }else{
       payload = {
        ...formData,
        teams: formData?.teamsId,
        roleCode: "athlete",
      }
    }
    delete payload?.teamsId;
    delete payload?.sportId

    try {
      let resp;
      if (id) {
        resp = await updateAthlete(id, payload);
      } else {
        resp = await createAthlete(payload);
      }

      if (resp?.status === 200) {
        // Fetch data and hide the form
        onHide();
        fetchData(activePage, filtersData);
        setActivePage(1);
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const fetchSingalData = async (id) => {
    // setShowLoader(true);
    try {
      const resp = await getAthleteById(id);
      const data = resp?.data?.responseData
      let roleList = [];
      if (data?.userTeams?.length) {
        data?.userTeams &&
        data?.userTeams?.map((ork, index) => {
            roleList.push(ork.id);
          });
      }
      console.log(roleList,"sdvhbsjdv")
      setValue("name", data?.userProfile?.name)
      setValue("email", data?.email)
      setValue("universityId", data?.userProfile?.university?.id)
      setValue("sportId", data?.userSports?.[0]?.id)
      setValue("countryCode", data?.contactCountryCode)
      setValue("imageId", data?.attachment)
      setValue("teamsId", roleList)
      setId(data?.userProfile?.university?.id)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
    }
  };

  const fetchUniversityData = async (page, type) => {
    try {
      const resp = await getUniversityListing({ page, perPage: 10 });
      const response = await getSuportsListing({ page, perPage: 20 });
      const newArray = resp?.data?.responseData?.data.map(({ name, id }) => ({ label: name, value: id }));
      const sportArray = response?.data?.responseData?.data.map(({ name, id }) => ({ label: name, value: id }));
      setUniversityList(newArray)
      setSportListing(sportArray)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      // setShowLoader(false);
    }
  };

  const fetchTeamsData = async (univerValue) => {
    // setShowLoader(true);
    try {
      const resp = await getTeamListing({ universityId: univerValue, page:1, perPage:50 });
      const newArray = resp?.data?.responseData?.data.map(({ name, id, sport }) => ({
        label: `${name}  (${sport.name})`, // Include the sport name in the label
        value: id
      }));
      setTeamListing(newArray)

    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      // setShowLoader(false);
    }
  };

  const askForDelete = () => {
    confirmAlert({
      title: "Confirm",
      message:
        // slideData.status == "1"
        // ? _("CONFIRM_DEACTIVATE")
        _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              onHide()
            } catch ({ request, response }) {
              console.log(request)
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  }

  // const onHidee = () => {
  //   askForDelete()
  // }

  useEffect(() => {
    if (univerValue || ID) {
      fetchTeamsData(univerValue || ID)
    }
  }, [univerValue, ID])


  useEffect(() => {
    if (id) {
      fetchSingalData(id)
    }
  }, [id, defaultValue])

  useEffect(() => {
    fetchUniversityData()
  }, [])

  console.log(teamListing, "dfvhbcdfc")



  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{(id !== null && id !== undefined) ? _("EDIT_ATHLETE") : _("ADD_ATHLETE")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="mb-3">
                    <label className="form-label">{_("NAME") + '*'}</label>
                    <Inputfield
                      control={control}
                      name={"name"}
                      placeholder={_("E.g. Usain Bolt..")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("ENTER_NAME"),
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{_("EMAIL") + '*'}</label>
                    <Inputfield
                      control={control}
                      name={"email"}
                      placeholder={_("E.g. example@gmail.com")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="email"
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("ENTER_EMAIL"),
                        },
                        pattern: {
                          value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                          message: _("INVALID_EMAIL_FORMAT"),
                        },
                      }}
                    />

                  </div>

                  <div className="">
                    <label className="form-label">{_("UNIVERSITY_NAME") + '*'}</label>
                    <div className="mb-3">
                      {/* <ReactSelectField
                        control={control}
                        name="universityId"
                        label={_("ROLE")}
                        placeholder={_("E.g. Stanford university..")}
                        options={universityList}
                        optionValue="value"
                        optionLabel="label"
                        onSelect={(selectedValue) => {
                          // Do something with the selected value
                          setUniversValue(selectedValue)
                        }}
                        // multi="true"
                        rules={{
                          required: {
                            value: true,
                            message: _("ENTER_UNIVERSITY"),
                          },
                        }}
                        
                      /> */}
                      <ReactSelectWithInfiniteScroll
                        control={control}
                        name="universityId"
                        label={_("ROLE")}
                        placeholder={_("E.g. Stanford university..")}
                        options={universityList}
                        optionValue="value"
                        optionLabel="label"
                        onSelect={(selectedValue) => {
                          // Do something with the selected value
                          setUniversValue(selectedValue)
                        }}
                        // multi="true"
                        rules={{
                          required: {
                            value: true,
                            message: _("ENTER_UNIVERSITY"),
                          },
                        }}
                        setDataListing={setUniversityList}
                        fetchApiData={getUniversityListing}
                        />
                    </div>
                  </div>

                  {/* <div className="">
                    <label className="form-label">{_("SPORTS") + '*'}</label>
                    <div className="mb-3">
                      <ReactSelectField
                        control={control}
                        name="sportId"
                        label={_("ROLE")}
                        placeholder={_("SPORTS")}
                        options={sportListing}
                        optionValue="value"
                        optionLabel="label"
                        // load={load}
                        // setLoad={setLoad}
                        onSelect={(selectedValue) => {
                          // Do something with the selected value
                          setSportValue(selectedValue)
                        }}

                        rules={{
                          required: {
                            value: true,
                            message: _("ENTER_SPORTS"),
                          },
                        }}
                      />


                    </div>
                  </div> */}

                  <div className="">
                    <label className="form-label">{_("TEAMS") + '*'}</label>
                    <div className="mb-3">
                      <ReactSelectField
                        control={control}
                        name="teamsId"
                        label={_("ROLE")}
                        placeholder={_("E.g. BasketBall..")}
                        options={teamListing}
                        optionValue="value"
                        optionLabel="label"
                        multi="true"
                        rules={{
                          required: {
                            value: true,
                            message: _("ENTER_TEAMS"),
                          },
                        }}

                      />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${isSubmitting && "btn-loader"}`}
                    >
                      {isSubmitting && <Spinner animation="border spiner-border-style" />}
                      {(id ) ? (

                        <span>{_("UPDATE")}</span>
                      ) : (
                        _("ADD_ATHLETE")
                      )}
                    </button>
                    {/* <Link onClick={onHidee} className="btn btn-secondary waves-effect"> */}
                    <Link onClick={onHide} className="btn btn-secondary waves-effect">
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AthleteAddEditModal;
