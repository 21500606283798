import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import ImageUploaderField from "../FormFields/ImageUploderFiled";
import { getSuportsListing } from "../../actions/Suports";
import ReactSelectFiled from "../FormFields/ReactSelectField";
import { useSelector } from "react-redux";
import { createTeam, getSingalTeam, updateTeam } from "../../actions/teams";
import ReactSelectWithInfiniteScroll from "../FormFields/ReactInfiniteSingleSelect";
function TeamAddEditModal({
  fetchData,
  show,
  onHide,
  teamId,
  setActivePage,
  activePage,
  filtersData,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({});
  const { universityListing } = useSelector((state) => {
    return state?.university;
  });
  console.log(teamId, "sdgcvsdghjvcsd");

  const { id } = useParams();

  const universityName = universityListing?.data?.filter((elm) => {
    // Assuming each element has an 'id' property
    return elm.id === Number(id);
  });
  const [sportListing, setSportListing] = useState();
  console.log(universityName?.[0]?.name, "sdcvsdjhbvckjsd");

  const onSubmit = async (formData) => {
    const payload = {
      ...formData,
      attachmentId: formData?.imageId?.id,
      universityId: Number(id),
    };
    delete payload?.location;
    delete payload?.contactName;
    delete payload?.imageId;
    delete payload?.universityName;

    console.log(teamId, "sdvhbcsdjhv");
    try {
      let resp;
      if (teamId) {
        // If id exists, update the university
        resp = await updateTeam(teamId, payload);
      } else {
        // If id doesn't exist, create a new university
        resp = await createTeam(payload);
      }

      if (resp?.status === 200) {
        console.log("hiiiiii");
        // Fetch data and hide the form
        fetchData(activePage, filtersData);
        onHide();
        // setActivePage(1)
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const fetchSportsData = async (page) => {
    // setShowLoader(true);
    try {
      const resp = await getSuportsListing({ page, perPage: 500 });
      console.log(resp, "dsjbdsjkvd");
      const newArray = resp?.data?.responseData?.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      console.log(newArray, "dshsjhbc");
      setSportListing(newArray);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      // setShowLoader(false);
    }
  };

  const fetchSingalData = async (id) => {
    // setShowLoader(true);
    try {
      const resp = await getSingalTeam(id);
      console.log(resp, "dshbcvhjdsbc");
      const data = resp?.data?.responseData;
      setValue("name", data?.name);
      setValue("imageId", data?.attachment);
      setValue("sportId", data?.sport?.id);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      fetchSingalData(teamId);
    }
  }, [teamId]);

  useEffect(() => {
    fetchSportsData(1);
  }, []);

  console.log(teamId, "hjhjscds");

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {teamId !== null && teamId !== undefined
              ? _("EDIT_TEAM")
              : _("ADD_TEAM")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">
                        {_("UNIVERSITY_NAME") + "*"}
                      </label>
                      <Inputfield
                        control={control}
                        name={"universityName"}
                        placeholder={_("NAME")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        defaultValue={universityName?.[0]?.name}
                        inputClassName={"form-control"}
                        disabled={true}
                        rules={{
                          required: {
                            value: false,
                            message: _("NAME_REQUIRED"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("NAME") + "*"}</label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_("NAME")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("NAME_REQUIRED"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <ImageUploaderField
                        control={control}
                        label="Add Team logo*"
                        name="imageId"
                        uploadType="image"
                        setValue={setValue}
                        changeLabel={"logo"}
                        uploadText="Upload logo"
                        extensionTypes={["jpeg", "jpg", "png"]}
                        rules={{
                          required: {
                            value: true,
                            message: "Upload logo",
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <label className="form-label">{_("SPORTS") + "*"}</label>
                    <div className="mb-3">
                      {/* <ReactSelectFiled
                                                control={control}
                                                name="sportId"
                                                label={_("ROLE")}
                                                placeholder={_("SELECT_SPORTS")}
                                                options={sportListing}
                                                optionValue="value"
                                                optionLabel="label"
                                                // multi="true"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: _("ENTER_SPORTS"),
                                                    },
                                                }}

                                            /> */}
                      <ReactSelectWithInfiniteScroll
                        control={control}
                        name="sportId"
                        label={_("ROLE")}
                        placeholder={_("SELECT_SPORTS")}
                        options={sportListing}
                        optionValue="value"
                        optionLabel="label"
                        // multi="true"
                        rules={{
                          required: {
                            value: true,
                            message: _("ENTER_SPORTS"),
                          },
                        }}
                        setDataListing={setSportListing}
                        fetchApiData={getSuportsListing}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting && (
                        <Spinner animation="border spiner-border-style" />
                      )}
                      {teamId ? (
                        <span>{_("UPDATE")}</span>
                      ) : (
                        <span>{_("ADD_TEAM")}</span>
                      )}
                    </button>
                    <Link
                      onClick={onHide}
                      className="btn btn-secondary waves-effect"
                    >
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TeamAddEditModal;
