import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import ReactPhoneInput from "../FormFields/ReactPhoneInput";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react"
import AsyncReactSelectField from "../FormFields/AsyncReactSelectField";
import ImageUploaderField from "../FormFields/ImageUploderFiled";
import AutoCompleteField from "../../actions/AutoComplete";
import { createUniversity, getSingalUniversityList, getUniversityListing, updateUniversity } from "../../actions/University";
import { createSports, getSportsById, updateSports } from "../../actions/Suports";
function SuportsAddEditModal({ fetchData, show, onHide, id ,setActivePage, activePage, filtersData}) {

  console.log(id, "dsjhbcsdhjcs")
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
  });
  const [defaultValue, setDefaultValue] = useState()

  const onSubmit = async (formData) => {
    const payload = {
      ...formData,
      attachmentId: formData?.imageId?.id
    }
    delete payload?.location;
    delete payload?.contactName;
    delete payload?.imageId

    // console.log(payload, "sdvhbcsdjhv")
    try {
      let resp;
      if (id) {
        // If id exists, update the university
        resp = await updateSports(id, payload);
      } else {
        // If id doesn't exist, create a new university
        resp = await createSports(payload);
      }

      if (resp?.status === 200) {
        // Fetch data and hide the form
        fetchData(activePage, filtersData);
        onHide();
        setActivePage(1)
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const fetchSingalData = async (id) => {
    // setShowLoader(true);
    try {
      const resp = await getSportsById(id);
      const data = resp?.data?.responseData
      setValue("name", data?.name)
      setValue("imageId", data?.attachment)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingalData(id)
    }
  }, [id])

  const kGoogleKey = 'AIzaSyBj2sDQqTe7MWv6F71KfmfUsh3LyaXhxdg';

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{(id !== null && id !== undefined) ? _("EDIT_SPORT") : _("ADD_SPORT")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("NAME") + '*'}</label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_("E.g. Football..")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("Enter sport name"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">

                      <ImageUploaderField
                        control={control}
                        label="Add sport logo"
                        name="imageId"
                        uploadType="image"
                        setValue={setValue}
                        changeLabel={"logo"}
                        uploadText="Upload logo"
                        extensionTypes={["jpeg", "jpg", "png"]}
                        rules={{
                          required: {
                            value: true,
                            message: "Upload logo",
                          },
                        }}
                      />
                    </div>
                  </div>





                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${isSubmitting && "btn-loader"
                        }`}
                    >
                        {isSubmitting && <Spinner animation="border spiner-border-style" />}
                      {id ? (
                        <span>{_("UPDATE")}</span>
                      ) :(
                        <span>{_("ADD_SPORT")}</span>
                      ) }

                    </button>
                    <Link onClick={onHide} className="btn btn-secondary">
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SuportsAddEditModal;
