import axios from "axios";
import { BASE_URL } from "../constants";

export const USER_LISTING = "USER_LISTING";
// export const PLAN_DETAIL = 'PLAN_DETAIL';
// export const DELETE_PLAN = 'DELETE_PLAN';
// export const PLAN_STATUS = 'PLAN_STATUS';

export const getAthleteListing = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: `/users`,
  });
};

export const getFollowListById = (id) => {
  return axios({
    method: "GET",
    url: `/following/${id}`,
  });
};

export const createAthlete = (data) => {
  return axios({
    method: "POST",
    data: data,
    url: `/user/invite`,
  });
};

export const getAthleteById = (id) => {
  return axios({
    method: "GET",
    url: `/user/${id}`,
  });
};

export const updateAthlete = (id, data) => {
  return axios({
    method: "PATCH",
    data: data,
    url: `/user/${id}`,
  });
};

export const updateAthleteStatus = (id, data) => {
  return axios({
    method: "PATCH",
    data: data,
    url: `/user/${id}/status`,
  });
};

export const deleteAthlete = (id) => {
  return axios({
    method: "DELETE",
    url: `/user/${id}`,
  });
};

export const userListing = (params) => {
  return axios({
    method: "GET",
    params: params,
    url: `/users/all`,
  });
};

export const getAthleteListingByTeam = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: `/users`,
  });
};

export const getAthleteListingData = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: `/users`,
  });
};

export const getContributionByFanId = (id, params) => {
  return axios({
    method: "GET",
    params: params,
    url: `/user/${id}/contribution/settemants`,
  });
};

export const getTransactionByAthleteId = (params) => {
  return axios({
    method: "GET",
    params: params,
    url: "/admin/contributions",
  });
};

export const getSampleImportFile = (params) => {
  return axios({
    method: "GET",
     params: params,
    url: "/sample-attachment/download",
    responseType: 'blob',
  });
};