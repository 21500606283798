import axios from "axios";
import {BASE_URL} from "../constants";

export const getTransactionListing = async(props) => {
    console.log("transaction", props)
    try{
      
        const {data} = await axios({
          method: 'GET',
          params: props,
          url: `admin/contribution/settemants`
      });
      return data
      
        
    }catch(error){
      console.log(error)
    }
};

export const getTransactionViewDetails = async(id) => {
  try{
      const {data} = await axios({
        method: 'GET',
        url: `admin/contribution/settemant/${id}`
    });
    return data   
  }catch(error){
    console.log(error)
  }
};

export const getTransactionExportData = async(params) => {
  try{
      const {data} = await axios({
        method: 'GET',
        params: params,
        url: `admin/settemants/exports`
    });
    return data   
  }catch(error){
    console.log(error)
  }
};


