import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useController } from 'react-hook-form';

const AutoCompleteField = ({
    control,
    name,
    defaultValue,
    placeholder,
    apiKey,
    onChange,
    handlePlaceSelected,
    menuIsOpen
}) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        rules: {
            required: defaultValue ? false : 'Enter location',
        },
        menuIsOpen: true,
    });
    
    const randomNumber = Math.floor(Math.random() * 1000) + 1;
    const [inputKey, setInputKey] = useState(randomNumber);

    useEffect(() => {
        setTimeout(() => {
            setInputKey((prev) => prev + 1);
        }, 300);
    }, []);

    const handleChange = (result) => {
        const location = {
            label: result.label,
        };

        geocodeByAddress(result?.label)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                console.log('Successfully got latitude and longitude', { lat, lng });

                location.latitude = lat;
                location.longitude = lng;

                field.onChange(location);

                if (onChange) {
                    onChange(location);
                }

                if (handlePlaceSelected) {
                    handlePlaceSelected(location);
                }
            })
            .catch(error => {
                console.error('Error fetching geolocation:', error);
            });
    };
    console.log(defaultValue, "sdcbhsdc")

    return (
        <>
            <div className='google-search-box-style'>
                <GooglePlacesAutocomplete
                // {...field}
                key={inputKey}
                apiKey={apiKey}
                className="google-search-style"
                defaultValue={defaultValue}
                selectProps={{
                    defaultInputValue: defaultValue,
                    placeholder: 'Search Location...',
                    onChange: (value) => {
                        handleChange(value);
                    },
                }}
            />
            </div>

            {error && (
                <span className="error-msg">
                    {error.message}
                </span>
            )}
        </>
    );
};

export default AutoCompleteField;

