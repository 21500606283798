import React, { useEffect } from "react";
import PageHeading from "../../components/common/PageHeading";
import { _ } from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import Spinner from "react-bootstrap/esm/Spinner";
import { getSettingData, updateSettingData } from "../../actions/setting";
import toast from "react-hot-toast";

const Settings = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const fetchRecord = async () => {
    try {
      const res = await getSettingData();
      const data = res?.data?.responseData;
      setValue("minRoundUp", data?.minRoundUp)
      setValue("minDirect", data?.minDirect)
      setValue("minGift", data?.minGift)
    } catch (error) {}
  };
  const onSubmit = async(values) => {
    try {
     const res =  await updateSettingData(values)
     if(res?.status === 200){
      toast.success('Data updated successfully', {
        position: "right-top",
        autoClose: 5000, // 5 seconds
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
     }
    } catch (error) {
      toast.error('Something went wrong, Please try again', {
        position: "right-top",
        autoClose: 5000, // 5 seconds
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRecord();
  },[])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading heading={_("SETTING")} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <label className="form-label">
                      {_("Minimum Roundup Contribution Amount($)") + "*"}
                    </label>
                    <Inputfield
                      control={control}
                      name={"minRoundUp"}
                      placeholder={_("E.g. 30")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      // type="text"
                      type="number" 
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("Enter amount"),
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-5 mb-3">
                    <label className="form-label">
                      {_("Minimum Direct Contribution Amount($)") + "*"}
                    </label>
                    <Inputfield
                      control={control}
                      name={"minDirect"}
                      placeholder={_("E.g. 30")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      // type="text"
                      type="number" 
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("Enter amount"),
                        },
                      }}
                    />
                  </div>
                  <div className="col-md-5 mb-3">
                    <label className="form-label">
                      {_("Minimum Gift Amount($)") + "*"}
                    </label>
                    <Inputfield
                      control={control}
                      name={"minGift"}
                      placeholder={_("E.g. 12")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      // type="text"
                      type="number" 
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("Enter amount"),
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-2 mt-3">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn newBtn btn-dark  waves-light ${
                      isSubmitting && "btn-loader"
                    }`}
                  >
                    {isSubmitting && (
                      <Spinner animation="border spiner-border-style" />
                    )}
                    {/* {id ? <span>{_("UPDATE")}</span> :  */}
                    <span>{_("SAVE")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
