import LoginForm from "./LoginForm";
import {_} from '../../locale';
import { useEffect } from "react";
import { isAuthenticated } from "../../utilities";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (isAuthenticated(localStorage.getItem("jwtToken"))) {
  //     navigate("/dashboard");
  //   }
  // }, []);

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden">
              <div className="login-dark-background">
                <div className="row">
                  <div className="col-12">
                    <div className="text-primary p-4 logo-design">
                      <img className="logo-img-style" src="/assets/svg/Logo.svg"/>
                      <p style={{textAlign:"center"}} className="text-white">{_('SIGN_IN_CONTINUE')}.</p>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="card-body"> 
                <LoginForm />
              </div>
            </div>
            <div className="mt-5 text-center">
              <div>
                {/* <p>© <script>document.write(new Date().getFullYear())</script> {_('CRAFTED_WITH')} <i className="mdi mdi-heart text-danger"></i> {_('BY_ILLUMINZ')}</p> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    
  );
}

export default Login;
