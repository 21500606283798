import React, { useEffect, useRef, useState } from "react";
import { _ } from "../../locale";
import { useForm } from "react-hook-form";
import moment from "moment";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import { useParams, useSearchParams } from "react-router-dom";
import {
  getMerchantTransactionById,
  getTransactionDetailsByMerchantId,
} from "../../actions/potentialMerchants";
import ResponsivePagination from "react-responsive-pagination";
import { limit } from "../../constants";
import ContributionSkeleton from "../Fan/ContributionSkeleton";
import Skeleton from "react-loading-skeleton";

const MerchantTransactionDetails = () => {
  const params = useParams();
  const merchantId = params.id;
  const initalFetchData = useRef(true);
  const { control, handleSubmit, setValue, reset } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionList, setTransactionList] = useState({});
  const [donorsRecord, setDonorsRecord] = useState({});
  const [filtersData, setFiltersData] = useState({});
  let search = searchParams.get("searchText");
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );

  const fetchRecord = async (activePage, filters) => {
    try {
      const params = {
        ...filters,
        merchantId: merchantId,
        page: activePage,
        perPage: 20,
      };
      const res = await getMerchantTransactionById(params);
      const resp = res?.data?.responseData;
      setTransactionList(resp);
      initalFetchData.current = false;
    } catch (error) {
      console.log(error, "error");
      initalFetchData.current = false;
    }
  };

  const fetchTransactionRecord = async () => {
    setIsLoading(true);
    try {
      const res = await getTransactionDetailsByMerchantId({
        merchantId: merchantId,
      });
      const data = res?.data?.responseData;
      setDonorsRecord(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    setActivePage(1);
    let obj = {};
    if (Object.keys(data)?.length > 0) {
      for (let key in data) {
        if (key === "startDate" || key === "endDate") {
          if (data[key]) {
            obj[`${key}`] =
              key === "startDate"
                ? moment(data[key]).startOf("day").format()
                : moment(data[key]).endOf("day").format();
          }
        }
      }
    }
    console.log("obj+++", obj);
    setFiltersData(obj);
  };

  const handleChange = (value) => {};

  const handlePageChange = (page) => {
    setActivePage(page);
  };
  const constructSearchParams = () => {
    const queryParams = [];
    queryParams.push(`page=${activePage}`);

    if (Object.keys(filtersData)?.length > 0) {
      for (let key in filtersData) {
        if (key === "startDate" || key === "endDate") {
          queryParams.push(`${key}=${filtersData[key]}`);
        }
      }
    }
    const queryString = queryParams.join("&");
    setSearchParams(queryString);
  };

  const handleResetFilter = () => {
    reset();
    setActivePage(1);
    setFiltersData({});
    setStartDates(null);
    setEndDates(null);
  };
  useEffect(() => {
    if (merchantId) {
      fetchRecord(activePage, filtersData);
    }
  }, [merchantId, activePage, filtersData]);

  useEffect(() => {
    if (merchantId) {
      fetchTransactionRecord();
    }
  }, [merchantId]);

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h5 className="fs-4 mb-3">
                {"Transaction details"} ({params?.name})
              </h5>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex align-items-center gap-4 mt-4">
                  <div className="d-flex w-50 justify-content-between align-items-center gap-3">
                    <div className="mb-3 w-50">
                      <ReactDatePicker
                        control={control}
                        name="startDate"
                        label="Start Date"
                        onChangeDate={(selectedDate) => {
                          const selectedMoment = moment(selectedDate);
                          setStartDates(selectedMoment);
                        }}
                        minDate={new Date("1900/01/01")}
                        maxDate={endDates ? endDates.toDate() : null}
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                    </div>
                    -
                    <div className="mb-3 w-50">
                      <ReactDatePicker
                        control={control}
                        name="endDate"
                        label="End Date"
                        onChangeDate={(val) => {
                          setEndDates(moment(val).endOf("day"));
                          handleChange(val);
                        }}
                        selectedEndDate={endDates}
                        rules={{
                          validate: (value) => {
                            const selectedMoment = moment(value);
                            if (
                              selectedMoment.isBefore(startDates) &&
                              !selectedMoment.isSame(startDates, "day")
                            ) {
                              return "End date should be after start date";
                            }

                            return true;
                          },
                        }}
                        minDate={
                          startDates
                            ? startDates.toDate()
                            : new Date("1900/01/01")
                        }
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-4 pt-1">
                    <button
                      className="btn newBtn btn-dark applyBtn"
                      type="submit"
                    >
                      Apply Now
                    </button>
                    <button
                      className="btn btn-danger applyBtn resetButton"
                      type="reset"
                      onClick={handleResetFilter}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
              <div className="col-md-6">
                <div className="row align-items-center">
                  <div className="col-5 fs-5">Referred Donors:</div>
                  <div className="col-2">
                    {isLoading ? (
                      <Skeleton width={70} height={20} />
                    ) : (
                      donorsRecord?.totalUsers
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row align-items-center">
                  <div className="col-5 fs-5"> Referred Donors Purchase:</div>
                  <div className="col-2">
                    {isLoading ? (
                      <Skeleton width={70} height={20} />
                    ) : (
                      `$${donorsRecord?.totalPurchase.toFixed(2)}`
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table align-middle table-nowrap table-hover">
                          <thead className="table-light">
                            <tr>
                              <th>#</th>
                              <th scope="col" className="text-uppercase">
                                {_("DATE")}
                              </th>
                              <th scope="col" className="text-uppercase">
                                {_("USER_SPENT")}
                              </th>
                              <th scope="col" className="text-uppercase">
                                {_("ROUNDUP_VALUE")}
                              </th>
                              <th scope="col" className="text-uppercase">
                                {_("MULTIPLIER")}
                              </th>
                              <th scope="col" className="text-uppercase">
                                {_("CONTRIBUTION_AMOUNT")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {initalFetchData.current === true ? (
                              <ContributionSkeleton count={6} />
                            ) : transactionList &&
                              transactionList?.data &&
                              transactionList?.data?.length > 0 ? (
                              transactionList &&
                              transactionList?.data &&
                              transactionList?.data?.length > 0 &&
                              transactionList?.data?.map((obj, index) => {
                                return (
                                  <tr>
                                    <td>
                                      {parseInt(
                                        (activePage - 1) * limit +
                                          parseInt(index + 1)
                                      )}
                                    </td>
                                    <td>{moment(obj.date).format("lll")}</td>
                                    <td>{obj?.amount ? obj?.amount : 0}</td>
                                    <td>
                                      {obj?.merchantRoundOff
                                        ? obj?.merchantRoundOff
                                        : "-"}
                                    </td>
                                    <td>
                                      {obj?.merchantMultiplier
                                        ? obj?.merchantMultiplier
                                        : 1}
                                    </td>
                                    <td>
                                      {obj?.merchantRoundOff *
                                        (obj?.merchantMultiplier
                                          ? obj?.merchantMultiplier
                                          : 1)}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr className="text-center">
                                <td colspan="12">
                                  <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                                    No Record Found
                                  </h5>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {transactionList && transactionList?.totalRecords > 20 && (
                      <ResponsivePagination
                        current={activePage}
                        total={transactionList?.totalPages ?? 1}
                        onPageChange={handlePageChange}
                        maxWidth={20}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MerchantTransactionDetails;
