import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { _ } from "../../locale";
import { getAthleteById } from "../../actions/athlete";
import Skeleton from "react-loading-skeleton";
import { getMatchById } from "../../actions/match";
import MatchShow from "../../pages/Matches/MatchShow";

function MatchViewModal({ show, onHide, id,userId }) {
  const [loader, setLoader] = useState(false)
  const [matchList, setMatchList] = useState({})
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(userId,"sdcbhsdhjc")
  const getFanDetailListing = async (id) => {
    setLoader(true)
    try {
      const resp = await getMatchById(id);
      setMatchList(resp?.data?.responseData)
      setLoader(false)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false)
    }
  };
  

  useEffect(() => {
    if (id) {
      getFanDetailListing(id)
    }
  }, [id])
  useEffect(() => {
    setSearchParams(`userid=${id}`);
  }, []);

console.log(matchList?.address,"sdfjbkcsdkjv")

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-900 addLeadModal"
        centered
      >

        <Modal.Header  >
          <div className="modal-heade-top">
        
            <div className="userimg-name">
              {/* <div>
                {matchList?.attachment !== null ? (
                  <div className="image-container">
                    <img src={matchList?.userProfile?.profileImage?.filePath} />
                  </div>
                ) : (
                  <div className="image-container">
                    <img src={"/assets/images/user-default-image.webp"} />
                  </div>
                )}
              </div> */}
              <div>
                <h2>{matchList?.title}</h2>
                <h6>Match Id #{matchList?.id}</h6>
              </div>
            </div>
            <h6 onClick={onHide}><img src="/assets/svg/cross.png"/></h6>

          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div className="popup-header">
            </div>

            <div>
              <table className="user-details">
                <tbody className="d-flex col-md-12">
                  <div className="d-flex col-md-12">
                    <div className="col-md-12 user-info-data">
                      <tr>
                        <td className="details-heading">{_("Address")}:</td>
                        <td className="mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : (
                            matchList?.mapAddress !==null ? matchList?.mapAddress : "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading">{_("Start date & time")}:</td>
                        <td className="gap-1 mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : matchList?.mobile !== null ? (
                          
                            <>
                            {  matchList?.startDate !==null ? moment(matchList?.startDate).format('lll') : "-"}
                            </>
                                 
                          ) : (
                            "-"
                          )}


                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading">{_("End date & time")}:</td>
                        <td className=" gap-1">
                        {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : matchList?.mobile !== null ? (
                          
                            <>
                            {  matchList?.endDate !==null ? moment(matchList?.endDate).format('lll') : "-"}
                            </>
                                 
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td className="details-heading">{_("Description")}:</td>
                        <td className=" gap-1 mw-100">
                        {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : matchList?.mobile !== null ? (
                          
                            <>
                            {  matchList?.description !==null ? matchList?.description : "-"}
                            </>
                                 
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>


                      <tr>
                        <td className="details-heading">{_("Sports")}:</td>
                        <td className="mw-100 gap-1">
                        {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : matchList?.sport !== null ? (
                          
                            matchList?.sport?.name
                                 
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <div className="d-flex user-info-data">

                      <div className="col-md-12">
                      {/* <td className="details-heading">{_("Team A")}</td> */}
                      <MatchShow matchList={matchList}/>
                        </div>
                      {/* <div className="col-md-6">
                      <td className="details-heading">{_("Team B")}</td>
                      <MatchShow matchList={matchList}/>
                        </div> */}
                    </div>
                    </div>
                  
                    </div>        
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MatchViewModal;
