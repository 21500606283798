import { v4 as uuidv4 } from "uuid";

export const DownLoadCSVFile = async (fileUrl, sectionName) => {

    const blob = new Blob([fileUrl?.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Export${sectionName}List-${uuidv4()}`; // Set the filename here
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };