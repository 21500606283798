import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { _ } from "../../locale";
import { limit } from "../../constants";
import AthleteSkelton from "./AthleteSkeleton";
import { getPostListing } from "../../actions/posts";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import PostSlide from "./PostSlide";
// import AthleteAddEditModal from "../../components/Modal/AthleteAddEditModal";
// import AthleteViewModal from "../../components/Modal/AthleteViewModal";
import PageHeading from "../../components/common/PageHeading";
import Pagination from "react-js-pagination";
// import FileUploadPopup from "../../components/Modal/FileUploadPopup";
// import { uploadFileId } from "../../actions/fileupload";
// import ImportErrorModel from "../../components/Modal/ImportErrorModel";
// import SuccessModal from "../../components/Modal/SuccessModel";
import { v4 as uuidv4 } from "uuid";
// import { DownLoadCSVFile } from "../../actions/common";

function ManagePosts() {
  // local variables
  const [postList, setPostList] = useState(null);
  // const [showUserModal, setShowUserModal] = useState(false);
  const [show, setShow] = useState(false);
  // const [showImportCSV, setShowImportCSV] = useState(false);
  const { control, setValue, handleSubmit } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const [sortParameter, setSorParameter] = useState("id");
  const [sortValue, setSortValue] = useState("desc");

  let search = searchParams.get("searchText");

  const initalFetchData = useRef(true);

  const fetchData = async (page, filters) => {
    try {
      const params = {
        page,
        sortParameter,
        sortValue,
        perPage: 20,
        ...filters, // Include filters in the request parameters
      };
      const resp = await getPostListing(params);
      setPostList(resp?.data?.responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };
  // console.log(athleteList, "sdcvbsdhjbcjsd");

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  // const handleSetShowUser=()=>{
  //   setShowUserModal(true)
  // }
  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };

  const setOrder = (type) => {
    setSorParameter(type);
    let orderMode = "";
    if (sortValue == "asc") {
      orderMode = "desc";
    } else {
      orderMode = "asc";
    }
    setSortValue(orderMode);

    fetchData();
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  console.log("======", postList);

  let showOrder = "";
  if (sortValue == "asc") {
    showOrder = "sorting sorting_asc";
  } else {
    showOrder = "sorting sorting_desc";
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading
                heading={"POSTS"}
                count={postList?.totalRecords || 0}
              />
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by athlete name,email")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("USER_NAME")}</th>
                        <th scope="col">{_("EMAIL")}</th>
                        <th scope="col">{_("DESCRIPTION")}</th>

                        <th
                          scope="col"
                          className={
                            sortParameter == "spamCount" ? showOrder : "sorting"
                          }
                          onClick={() => setOrder("spamCount")}
                        >
                          <div className="filter-table">
                            <span className="th-name">{_("SPAM_COUNT")}</span>
                            <span className="ic-sort"></span>
                          </div>
                        </th>

                        <th scope="col">{_("STATUS")}</th>
                        <th scope="col">{_("ATTACHMENTS")}</th>
                        <th scope="col">{_("DATE")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {initalFetchData.current === true ? (
                        <AthleteSkelton count={8} />
                      ) : postList &&
                        postList?.data &&
                        postList?.data?.length > 0 ? (
                        postList &&
                        postList?.data &&
                        postList?.data?.length > 0 &&
                        postList?.data?.map((obj, index) => (
                          <PostSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            activePage={activePage}
                            filtersData={filtersData}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pagination">
                {postList && postList?.totalRecords > 20 && (
                  <ResponsivePagination
                    current={activePage}
                    total={postList?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                    maxWidth={20}
                    totalItemsCount={postList?.totalRecords ?? 1}
                    itemsCountPerPage={20}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManagePosts;
