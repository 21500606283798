import { useEffect, useState } from "react";
import { isAuthenticated } from "../../utilities";
import { useNavigate } from "react-router-dom";
import {
  getDashaboardCategoryContribution,
  getDashaboardChartDetails,
  getMonthlyEventContribution,
  getDashaboardEventStatusDetails,
  getDashaboardOverviewDetails,
  getSystemDetails,
  getTopAthleteByContribution,
  getTopAthleteByFollower,
} from "../../actions/dashboard";
import toast from "react-hot-toast";
import { _ } from "../../locale";
import {
  contributionHeader,
  contributionModes,
  headers,
} from "../../constants";
import { Table } from "react-bootstrap";
import ReactTable from "../../components/common/ReactTable";
import ReactTabs from "../../components/common/ReactTabs";
import DashboardChart from "./DashboardChart";
import {
  AmountContributionChart,
  EventBasedAthleteContributionOptions,
  EventsBasedEventStatusOptions,
} from "./ChartData";

import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { useForm } from "react-hook-form";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import moment from "moment";
import setMinutes from "date-fns/setMinutes";
import { chart } from "highcharts";
import Skeleton from "react-loading-skeleton";
import ReactSelectWithInfiniteScroll from "../../components/FormFields/ReactInfiniteSingleSelect";
import { getUniversityListing } from "../../actions/University";

function Dashboard() {
  const navigate = useNavigate();
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({
    activeEntities: {},
    topAthletesByFollowers: [],
    topTeamsByFollowers: [],
    topAthletesByContribution: [],
    topTeamsByContribution: [],
  });
  const [eventData, setEventData] = useState([]);
  const [universityList, setUniversityList] = useState([])
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      contributionStartDate: moment(oneMonthAgo).startOf("day"),
      contributionEndDate: moment(new Date()).endOf("day"),
    },
  });

  const chartDetails = async (params = {}) => {
    try {
      const { data: CategoryContribution } =
        await getDashaboardCategoryContribution(params);

      setChartData((prevData) => ({
        ...prevData,
        contributionAmounts:
          CategoryContribution?.responseData?.contributionAmounts,
      }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getEventStatus = async()=>{
    const { data: EventStatus } = await getDashaboardEventStatusDetails();
    setChartData((prevData) => ({
      ...prevData,
      eventStats: EventStatus?.responseData?.eventStats,
    }));
  }
  const getDashboardOverViewData = async()=>{
    const { data: overviewData } = await getDashaboardOverviewDetails();
    setChartData((prevData) => ({
      ...prevData,
      activeEntities: overviewData?.responseData?.overview,
    }));
  }

  const getTopAthletesAndTeamsByContribution = async (params = {}) => {
    try {

      const { data: AthleteByContribution } = await getTopAthleteByContribution(params);
      let athleteByContribution = [];
      let teamsByContribution = [];

      const athleteDataByContribution =
        AthleteByContribution?.responseData?.byContributions
          ?.topAthletesByContribution?.length > 0 &&
        AthleteByContribution?.responseData?.byContributions?.topAthletesByContribution?.map(
          (fw_data) => {
            athleteByContribution.push([
              fw_data?.userProfile?.name,
              (fw_data?.userProfile?.contributionReceived).toFixed(2),
            ]);
          }
        );
      const teamsDataByContribution =
        AthleteByContribution?.responseData?.byContributions
          ?.topTeamsByContribution?.length > 0 &&
        AthleteByContribution?.responseData?.byContributions?.topTeamsByContribution?.map(
          (fw_data) => {
            teamsByContribution.push([
              fw_data?.name,
              (fw_data?.contributions).toFixed(2),
            ]);
          }
        );

      setChartData((prevData) => ({
        ...prevData,
        
        topAthletesByContribution: [...athleteByContribution],
        topTeamsByContribution: [...teamsByContribution],
      }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const getTopAthletesAndTeamsByFollower = async (params = {}) => {
    try {

      const { data: AthleteByFollower } = await getTopAthleteByFollower(params);
      let athleteByFollower = [];
      let teamsByFollower = [];

      const athleteDataByFollower =
        AthleteByFollower?.responseData?.byFollowers?.topAthletesByFollowers
          ?.length > 0 &&
        AthleteByFollower?.responseData?.byFollowers?.topAthletesByFollowers?.map(
          (fw_data) => {
            athleteByFollower.push([
              fw_data?.userProfile?.name,
              fw_data?.userProfile?.followers,
            ]);
          }
        );
      const teamsDataByFollower =
        AthleteByFollower?.responseData?.byFollowers?.topTeamsByFollowers
          ?.length > 0 &&
        AthleteByFollower?.responseData?.byFollowers?.topTeamsByFollowers?.map(
          (fw_data) => {
            teamsByFollower.push([fw_data?.name, fw_data?.followers]);
          }
        );

      setChartData((prevData) => ({
        ...prevData,
        topAthletesByFollowers: [...athleteByFollower],
        topTeamsByFollowers: [...teamsByFollower],
      }));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const fetchEventRecord = async () => {
    try {
      const resp = await getMonthlyEventContribution();
      const data = resp.data.responseData;
      const sortedData = data
        .slice()
        .sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
      // sortedData.map((item) =>
      //   eventMonthlyContributionArray.push({
      //     monthData: `${item.month} ${item.year}`,
      //     value: item.roundUp,
      //   })
      // );
      setEventData(sortedData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const fetchUniversityData = async (page, type) => {
    try {
      const resp = await getUniversityListing({ page, perPage: 10 });
      const newArray = resp?.data?.responseData?.data.map(({ name, id }) => ({ label: name, value: id }));
      setUniversityList(newArray)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_('NO_INTERNET'));
      }
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    const params = {
      startDate: moment(oneMonthAgo).startOf("day"),
      endDate: moment(new Date()).endOf("day"),
    };
    if (!isAuthenticated(localStorage.getItem("jwtToken"))) {
      navigate("/");
    }

    chartDetails(params);
    fetchEventRecord();
    fetchUniversityData()
    getTopAthletesAndTeamsByContribution()
    getTopAthletesAndTeamsByFollower()
    getEventStatus()
    getDashboardOverViewData()
  }, []);

  const onSubmit = (data) => {
    const params = {
      startDate: moment(data?.contributionStartDate).format(""),
      endDate: moment(data?.contributionEndDate).format(""),
    };
    const fun = chartDetails(params);
  };
  const TopAthletesFollowerSubmit = async(data) => {
      const params = {
        universityId: data.university
       };
       const topAthletesAndTeams = await getTopAthletesAndTeamsByFollower(params)

    };
    const TopAthletesContributionSubmit = async(data) => {
        const params = {
          universityId: data.universityId
         };
         const topAthletesAndTeams = await getTopAthletesAndTeamsByContribution(params) 
      };
  
  const handleChange = (value) => {
    console.log("value", value);
  };
  const formReset = async(section) => {
    if(section === 'byContribution'){
      setValue("universityId", "")
      await getTopAthletesAndTeamsByContribution()
    }else{
      setValue("university", "");
      await getTopAthletesAndTeamsByFollower()
    }  
  };
  const Data = [
    {
      id: 1,
      value: chartData?.activeEntities?.activeAthletes,
      label: _("ACTIVE_ATHLETES"),
      icon: "/assets/images/dashboardIcons/athlete-icon.svg",
    },
    {
      id: 2,
      value: chartData?.activeEntities?.activeTeams,
      label: _("ACTIVE_TEAM"),
      icon: "/assets/images/dashboardIcons/team-icon.svg",
    },
    {
      id: 3,
      value: chartData?.activeEntities?.activeUniversities,
      label: _("ACTIVE_UNIVERSITY"),
      icon: "/assets/images/dashboardIcons/university-icon.svg",
    },
    {
      id: 4,
      value: `$ ${chartData?.activeEntities?.totalContributions}`,
      label: _("TOTAL_CONTRIBUTION"),
      icon: "/assets/images/dashboardIcons/contribution-icon.svg",
    },
    {
      id: 5,
      value: `$ ${chartData?.activeEntities?.transferAmount}`,
      label: _("TRANSFER_AMOUNT"),
      icon: "/assets/images/dashboardIcons/amount-icon.svg",
    },
    {
      id: 6,
      value: `$ ${chartData?.activeEntities?.holdAmount}`,
      label: _("HOLD_AMOUNT"),
      icon: "/assets/images/dashboardIcons/hold-amount-icon.svg",
    },
  ];

  const tabsByFollower = [
    {
      eventKey: "tab1",
      title: "Top Athletes",
      content: (
        <ReactTable
          isLoading={isLoading}
          headers={headers}
          data={chartData?.topAthletesByFollowers}
          activeTab={"1"}
        />
      ),
    },
    {
      eventKey: "tab2",
      title: "Top Teams",
      content: (
        <ReactTable
          isLoading={isLoading}
          headers={headers}
          data={chartData?.topTeamsByFollowers}
          activeTab={"2"}
        />
      ),
    },
  ];
  const TabsByContribution = [
    {
      eventKey: "tab1",
      title: "Top Athletes",
      content: (
        <ReactTable
          isLoading={isLoading}
          headers={contributionHeader}
          data={chartData?.topAthletesByContribution}
          activeTab={"1"}
        />
      ),
    },
    {
      eventKey: "tab2",
      title: "Top Teams",
      content: (
        <ReactTable
          isLoading={isLoading}
          headers={contributionHeader}
          data={chartData?.topTeamsByContribution}
          activeTab={"2"}
        />
      ),
    },
  ];

  console.log(eventData, "cccc");
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="">
          <div className="d-flex flex-column gap-4">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
            </div>

            {chartData?.activeEntities ? (
              <>
                <div className="d-flex justify-content-between mb-3">
                  {Data.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between bg-white dashboard-card"
                    >
                      <div>
                      {!isLoading? (
                        // {item.value ? (
                          <p className="mb-0">{item.value}</p>
                        ) : (
                          <Skeleton width={75} height={22} className="mb-2" />
                        )}
                        <h6 className="mb-0">{item.label}</h6>
                      </div>
                      <div style={{ width: "20px", height: "20px" }}>
                        <img src={item?.icon} alt="" width={20} height={20} />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="d-flex gap-4">
                  <div className="w-50 shadow-sm p-3 rounded bg-white">
                    <form
                    name="topAthletefilter"
                    className="chk-filter"
                    onSubmit={handleSubmit(TopAthletesFollowerSubmit)}
                    >
                    <div className="d-flex justify-content-end gap-2 mb-4">
                      <div className="event-filter">
                      <ReactSelectWithInfiniteScroll
                        control={control}
                        name="university"
                        placeholder={_("Select University")}
                        options={universityList}
                        optionValue="value"
                        optionLabel="label"
                        onSelect={(selectedValue) => {
                         // setUniversValue(selectedValue)
                         console.log("selected value", selectedValue)
                        }}
                        setDataListing={setUniversityList}
                        fetchApiData={getUniversityListing}
                        />
                      </div>
                      
                         <button
                        className="btn btn-dark newBtn filter-apply-btn"
                        type="submit"
                      >
                        Apply
                      </button>
                      <button
                        className="btn btn-danger resetButton newBtn filter-apply-btn"
                        type="reset"
                        onClick={()=>formReset('byFollower')}
                      >
                        Reset
                      </button>
                    </div>
                    </form>
                    <h5 className="fs-4 mb-3">{_("BY_FOLLOWER")}</h5>
                    <ReactTabs tabs={tabsByFollower} />
                  </div>
                  <div className="w-50 shadow-sm p-3 rounded bg-white">
                  <form
                    name="topAthleteByContributionfilter"
                    className="chk-filter"
                    onSubmit={handleSubmit(TopAthletesContributionSubmit)}
                    >
                    <div className="d-flex justify-content-end gap-2 mb-4">
                      <div className="event-filter">
                      <ReactSelectWithInfiniteScroll
                        control={control}
                        name="universityId"
                        placeholder={_("Select University")}
                        options={universityList}
                        optionValue="value"
                        optionLabel="label"
                        onSelect={(selectedValue) => {
                         // setUniversValue(selectedValue)
                         console.log("selected value", selectedValue)
                        }}
                        setDataListing={setUniversityList}
                        fetchApiData={getUniversityListing}
                        />
                      </div>
                      
                         <button
                        className="btn btn-dark newBtn filter-apply-btn"
                        type="submit"
                      >
                        Apply
                      </button>
                      <button
                        className="btn btn-danger resetButton newBtn filter-apply-btn"
                        type="reset"
                        onClick={()=>formReset('byContribution')}
                      >
                        Reset
                      </button>
                    </div>
                    </form>
                    <h5 className="fs-4 mb-3">{_("BY_CONTRIBUTION")}</h5>
                    <ReactTabs tabs={TabsByContribution} />
                  </div>
                </div>
                <div className="d-flex flex-column gap-4 border shadow-sm bg-white">
                  <form
                    name="filter"
                    className="chk-filter"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="d-flex justify-content-left align-items-center gap-4 p-4">
                      <ReactDatePicker
                        control={control}
                        name="contributionStartDate"
                        label="Start Date"
                        onChangeDate={(selectedDate) => {
                          const selectedMoment =
                            moment(selectedDate).startOf("day");
                          setStartDates(selectedMoment);
                          handleChange(selectedDate);
                        }}
                        minDate={new Date("1900/01/01")}
                        maxDate={endDates ? endDates.toDate() : null}
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                      <ReactDatePicker
                        control={control}
                        name="contributionEndDate"
                        label="End Date"
                        onChangeDate={(val) => {
                          setEndDates(moment(val).endOf("day"));
                          handleChange(val);
                        }}
                        selectedEndDate={endDates}
                        minDate={
                          startDates
                            ? startDates.toDate()
                            : new Date("1900/01/01")
                        }
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                        setDefaultEndDate={new Date()}
                      />
                      <button
                        className="btn btn-dark newBtn applyBtn mt-4"
                        type="submit"
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                  <div className="d-flex justify-content-between">
                    <div className="w-50">
                      <DashboardChart
                        configureOption={AmountContributionChart(
                          chartData?.contributionAmounts,
                          "linechart"
                        )}
                        classname="line-chart"
                      />
                    </div>
                    <div className="w-50">
                      <DashboardChart
                        configureOption={AmountContributionChart(
                          chartData?.contributionAmounts,
                          "piechart"
                        )}
                        classname="pie-chart"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <DashboardChart
                    configureOption={EventBasedAthleteContributionOptions(
                      eventData
                    )}
                    classname={"sline-chart"}
                  />
                </div>
                <div className="">
                  {/* <div
                    className="w-50 shadow-sm p-3 rounded"
                    style={{ backgroundColor: "white" }}
                  > */}
                  {/* <ReactTabs  tabs={matchesTabs}/>  */}
                  {/* </div> */}
                  <DashboardChart
                    configureOption={EventsBasedEventStatusOptions(
                      chartData?.eventStats
                    )}
                    classname={"sline-chart"}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
