import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { limit } from "../../constants";
import SuportsSlide from "./SuportsSlide";
import SuportsSkelton from "./SuportsSkeleton";
import { getSuportsListing } from "../../actions/Suports";
import SuportsAddEditModal from "../../components/Modal/SuportsAddEditModal";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import PageHeading from "../../components/common/PageHeading";
import Pagination from "react-js-pagination";

function ManageSuports() {
  const [suportsList, setSuportsList] = useState([]);
  const [show, setShow] = useState(false);
  const { control, setValue, handleSubmit } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  let search = searchParams.get("searchText");
  const initalFetchData = useRef(true);

  const fetchData = async (page, filters) => {
    try {
      const params = {
        page,
        perPage: 20,
        ...filters,
      };
      const resp = await getSuportsListing(params);
      setSuportsList(resp?.data?.responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };

  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading
                heading={"SPORTS"}
                count={suportsList?.totalRecords || 0}
              />
              <div className="flex-shrink-0">
                <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_SPORT")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by sports name")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("LOGO")}</th>
                        <th scope="col">{_("SUPORTS_NAME")}</th>
                        <th scope="col">{_("ACTION")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <SuportsSkelton count={8} />
                      ) : suportsList &&
                        suportsList?.data &&
                        suportsList?.data?.length > 0 ? (
                        suportsList &&
                        suportsList?.data &&
                        suportsList?.data?.length > 0 &&
                        suportsList?.data?.map((obj, index) => (
                          <SuportsSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            activePage={activePage}
                            filtersData = {filtersData}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {suportsList && suportsList?.totalRecords > 20 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={20}
                  totalItemsCount={suportsList?.totalRecords ?? 1}
                  pageRangeDisplayed={6}
                  onChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <SuportsAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          setActivePage={setActivePage}
        />
      )}
    </div>
  );
}
export default ManageSuports;
