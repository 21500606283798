import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { limit } from "../../constants";
import {
  UNIVERSITY_LISTING,
  getUniversityListing,
} from "../../actions/University";
import UniversitySlide from "./UniversitySlide";
import UniversitySkelton from "./UniversitySkelton";
import UniversityAddModal from "../../components/Modal/UniversityAddModal";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import { useDispatch } from "react-redux";
import PageHeading from "../../components/common/PageHeading";
import { getSampleImportFile } from "../../actions/athlete";
import { v4 as uuidv4 } from "uuid";
import { uploadFileId, uploadFileIdForUniversity } from "../../actions/fileupload";
import { DownLoadCSVFile } from "../../actions/common";
import FileUploadPopup from "../../components/Modal/FileUploadPopup";
import ImportErrorModel from "../../components/Modal/ImportErrorModel";
import SuccessModal from "../../components/Modal/SuccessModel";

function University() {
  // local variables
  const [activePage, setActivePage] = useState(1);
  const [universityList, setUniversityList] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [show, setShow] = useState(false);
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showImportCSV, setShowImportCSV] = useState(false);
  const [importModel, setImportModel] = useState({
    showImportErrorModel: false,
    showSuccessModel: false,
    importResponse: [],
  });
  let search = searchParams.get("searchText");
  console.log(search, "Search");
  const { control, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const initalFetchData = useRef(true);

  const fetchData = async (page, filters) => {
    try {
      const params = {
        page,
        ...filters, // Include filters in the request parameters
      };
      const resp = await getUniversityListing(params);
      setUniversityList(resp?.data?.responseData);
      dispatch({ type: UNIVERSITY_LISTING, payload: resp?.data?.responseData });
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };
  console.log(universityList, "sdcvbsdhjbcjsd");

  // const handlePageChange = (page) => {
  //   setActivePage(page);
  // };

  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    console.log(activePage, "sdvghsdvc");
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };
  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  const handleClosePopup = () => {
    setShowImportCSV(false);
  };
  // file upload on server
  const handleUploadFile = async (fileId) => {
    console.log("Uploading file:", fileId);
    const { data } = await uploadFileIdForUniversity(fileId);
    setShowImportCSV(false);
    const isSuccess =
      data?.responseData?.length > 0 &&
      data?.responseData?.every((res) => res.status === 200);
    if (isSuccess) {
      setImportModel((prevState) => ({
        ...prevState,
        importResponse: [],
        showImportErrorModel: false,
        showSuccessModel: true,
      }));
    } else {
      // setImportModel(prevState=>({...prevState, importResponse: [], showImportErrorModel: false, showSuccessModel: true}))
      setImportModel((prevState) => ({
        ...prevState,
        importResponse: [...data?.responseData],
        showImportErrorModel: true,
        showSuccessModel: false,
      }));
    }
  };

  const handlePopup = (e) => {
    setShowImportCSV((prev) => !prev);
  };
  // download file functionality
  const handleDownLoadCSV = async () => {
    const Params = {
      type:'university'
    }
    const fileUrl = await getSampleImportFile(Params);
    const res = DownLoadCSVFile(fileUrl, 'University')
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              {/* <h4 className="mb-sm-0 font-size-18">{_('UNIVERSITIES')}</h4> */}
              <PageHeading
                heading={"UNIVERSITIES"}
                count={universityList?.totalRecords || 0}
              />
              {/* <div className="flex-shrink-0">
                <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_UNIVERISTY")}
                </Link>
              </div> */}
              <div className="flex-shrink-0 g-4">
                  <>
                    <button
                      onClick={handlePopup}
                      className="btn newBtn btn-dark mr-20 "
                    >
                      {_("IMPORT_FILE")}
                    </button>
                    <button
                      onClick={handleDownLoadCSV}
                      className="btn newBtn btn-dark mr-20 "
                    >
                      {_("DOWNLOAD_FILE")}
                    </button>
                  </>
                  <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_UNIVERISTY")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_(
                  "Search by university name, contact person, email and phone..."
                )}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("UNIVERSITY_NAME")}</th>
                        <th scope="col">{_("LOCATION")}</th>
                        <th scope="col">{_("CONTACT_PERSON_NAME")}</th>
                        <th scope="col">{_("EMAIL")}</th>
                        <th scope="col">{_("PHONE")}</th>
                        <th scope="col">{_("STATUS")}</th>
                        <th scope="col">{_("ACTION")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <UniversitySkelton count={8} />
                      ) : universityList &&
                        universityList?.data &&
                        universityList?.data?.length > 0 ? (
                        universityList &&
                        universityList?.data &&
                        universityList?.data?.length > 0 &&
                        universityList?.data?.map((obj, index) => (
                          <UniversitySlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            activePage = {activePage}
                            filtersData = {filtersData}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {universityList && universityList?.totalRecords > 20 && (
                //   <Pagination
                //   activePage={activePage}
                //   itemsCountPerPage={20}
                //   totalItemsCount={universityList?.totalPages ?? 1}
                //   pageRangeDisplayed={6}
                //   onChange={handlePageChange}
                // />
                <ResponsivePagination
                  current={activePage}
                  total={universityList?.totalPages ?? 1}
                  onPageChange={handlePageChange}
                  maxWidth={20}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <UniversityAddModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          setActivePage={setActivePage}
        />
      )}
      {showImportCSV ? (
        <FileUploadPopup
          showImportCSV={showImportCSV}
          onClose={handleClosePopup}
          onUpload={handleUploadFile}
        />
      ) : (
        <></>
      )}
      {importModel?.showImportErrorModel ? (
        <ImportErrorModel
          importModel={importModel}
          setImportModel={setImportModel}
          fetchData={fetchData}
          section='university'
        />
      ) : (
        <></>
      )}
      {importModel?.showSuccessModel ? (
        <SuccessModal
          importModel={importModel}
          setImportModel={setImportModel}
          fetchData={fetchData}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default University;
