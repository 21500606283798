import React, { useEffect, useRef, useState } from "react";
import PageHeading from "../../components/common/PageHeading";
import { Link, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { getStaffList } from "../../actions/staff";
import StaffSkeleton from "./StaffSkeleton";
import StaffSlide from "./StaffSlide";
import ResponsivePagination from "react-responsive-pagination";
import { limit } from "../../constants";
import { useForm } from "react-hook-form";
import Inputfield from "../../components/FormFields/InputField";

const ManageStaff = () => {

  const [staffList, setStaffList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  let search = searchParams.get("searchText");
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const { control, handleSubmit, setValue } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  
  const initalFetchData = useRef(true);

  const onSubmit = (data) => {
    console.log(data, "ddd");
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);
  const handlePageChange= (page) =>{
    setActivePage(page)
  }
  const fetchData = async (page, filters) => {
    try {
      const params = {
        page, 
        perPage:20,
        ...filters,
      }
      const res = await getStaffList(params);
      setStaffList(res?.data?.responseData);
      initalFetchData.current = false;
    } catch (error) {
      initalFetchData.current = false;
    }
  };
  useEffect(() => {
    fetchData(activePage,filtersData);
  }, [activePage,filtersData]);
  const handleReset = () => {
    // console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <PageHeading heading={"STAFF"} />
                <div className="flex-shrink-0">
                  <Link
                    to={"/dashboard/staff/add-staff"}
                    className="btn newBtn btn-dark"
                  >
                    {_("ADD_STAFF")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by Staff name, email and phone number...")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap table-hover">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{_("NAME")}</th>
                          <th scope="col">{_("ROLE")}</th>
                          <th scope="col">{_("EMAIL")}</th>
                          <th scope="col">{_("PHONE")}</th>
                          <th scope="col">{_("STATUS")}</th>
                          <th scope="col">{_("ACTION")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {initalFetchData.current === true ? (
                          <StaffSkeleton count={8} />
                        ) : staffList &&
                          staffList?.data &&
                          staffList?.data?.length > 0 ? (
                          staffList &&
                          staffList?.data &&
                          staffList?.data?.length > 0 &&
                          staffList?.data?.map((obj, index) => {
                            return (
                              <StaffSlide
                                slideData={obj}
                                key={obj.id}
                                index={index}
                                srno={(activePage - 1) * limit}
                                fetchData={fetchData}
                                activePage = {activePage}
                                filtersData={filtersData}
                              />
                            );
                          })
                        ) : (
                          <tr className="text-center">
                            <td colspan="12">
                              <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                                No Record Found
                              </h5>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {staffList && staffList?.totalRecords > 20 && (
                <ResponsivePagination
                  current={activePage}
                  total={staffList?.totalPages ?? 1}
                  onPageChange={handlePageChange}
                  maxWidth={20}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageStaff;
