import React from "react";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { _ } from "../../locale";
import { deleteRole } from "../../actions/roles";

const RoleSlide = ({ slideData, index, srno, fetchData, activePage }) => {
  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteRole(slideData?.id);
              fetchData(activePage);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  return (
    <>
      <tr>
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          {slideData?.name?.charAt(0).toUpperCase() +
            slideData?.name?.slice(1) ?? null}
        </td>
        <td className="text-wrap w-50">
          {slideData?.Permissions?.length > 0
            ? slideData?.Permissions?.map((data, index) => {
                return (
                  <div key={index} className="pe-2 d-inline-flex">
                    {data?.name}{" "}
                    {index !== slideData.Permissions.length - 1 && ","}
                  </div>
                );
              })
            : "-"}
        </td>
        <td>
          {slideData?.isDefault === true ? (
            ""
          ) : (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to={`/dashboard/role/edit-role/${slideData?.id}`}
                  className="btn btn-sm btn-soft-info newBtnEdit"
                  title="Update"
                >
                  <i className="mdi mdi-pencil-outline"></i>
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    deleteRecord(slideData?.id);
                  }}
                  className="btn btn-sm btn-soft-danger newBtnDel"
                  title="Delete"
                >
                  <i className="mdi mdi-delete-outline"></i>
                </Link>
              </li>
            </ul>
          )}
        </td>
      </tr>
    </>
  );
};

export default RoleSlide;
