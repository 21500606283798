import React from "react";

export default function Bradcrum({ stringValue, navUrl, matchedValue }) {
  console.log(stringValue, "sdchsjd");
  return (
    <>
      {stringValue?.[0] === matchedValue &&
        stringValue.length > 0 &&
        stringValue.map((elm, index) => (
          <React.Fragment key={index}>
            {index > 0 && index !== stringValue.length - 1 && (
              <i className="fa fa-fw fa-chevron-right slash-style"></i>
            )}
            {index === 0 &&
            stringValue.length > 1 &&
            stringValue[0] === matchedValue ? (
              <p
                className="bradcrum-style"
                onClick={() => {
                  navUrl(matchedValue);
                }}
              >
                {matchedValue.charAt(0).toUpperCase() + matchedValue.slice(1)}
              </p>
            ) : index === 0 &&
              stringValue.length > 1 &&
              stringValue[0] !== matchedValue &&
              stringValue[1] !== "" ? (
              <p
                className="bradcrum-style"
                onClick={() => {
                  navUrl(elm);
                }}
              >
                {elm.charAt(0).toUpperCase() + elm.slice(1)}
              </p>
            ) : index !== stringValue.length - 1 ? (
              <p
                className="bradcrum-style"
                onClick={() => {
                  navUrl(elm);
                }}
              >
                {elm.charAt(0).toUpperCase() + elm.slice(1)}
              </p>
            ) : null}
          </React.Fragment>
        ))}
    </>
  );
}
