// API end points
// export const BASE_URL = "http://54.176.169.179:3009";
export const BASE_URL = process.env.REACT_APP_API_URL;

export const limit = 20;
export const imagesToAccept = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
export const VIDEO_MAX_SIZE = 8;
// Field validations
export const errorType = [
  "manual",
  "required",
  "pattern",
  "validate",
  "minLength",
  "maxLength",
  "max",
  "min",
  "positive",
  "lessThanTen",
  "greaterThan",
  "checkUrl",
];

export const POST_TYPE = [
  { label: "Post", value: "post" },
  { label: "Page", value: "page" },
];

export const CONFIG = {
  deviceType: "WEB",
  contentLanguage: "en",
};

export const SUBSCRIPTION_PLAN = [{ label: "razorpay", value: "razorpay" }];

// export const formatPhoneNumber = (phoneNumer) => {
//   const phoneNumber = phoneNumer?.replace(/[^\d]/g, '');
//   const phoneNumberLength = phoneNumber?.length;
//   console.log(phoneNumberLength,"phonelength")
//   if (phoneNumberLength < 4) {
//   return phoneNumber;
//   } else if (phoneNumberLength < 7) {
//   return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3)}`;
//   } else {
//   return `(${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(
//       3,
//       6,
//   )}-${phoneNumber?.slice(6, 10)}`;
//   }
// };

export const formatPhoneNumber = (phoneNumber) => {
  // Check if phoneNumber is undefined or empty
  if (!phoneNumber) {
    return "";
  }


  const sanitizedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");
  const phoneNumberLength = sanitizedPhoneNumber.length;
  if (phoneNumberLength < 4) {
    return sanitizedPhoneNumber;
  } else if (phoneNumberLength < 7) {
    return `(${sanitizedPhoneNumber.slice(0, 3)}) ${sanitizedPhoneNumber.slice(
      3
    )}`;
  } else {
    return `(${sanitizedPhoneNumber.slice(0, 3)}) ${sanitizedPhoneNumber.slice(
      3,
      6
    )}-${sanitizedPhoneNumber.slice(6, 10)}`;
  }
};

export const formatPhone = (phoneNumber) => {
  if (!phoneNumber) {
    return "";
  }
  const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

  return sanitizedPhoneNumber;
};

export const EVENT_STATUS = {
  0: "Not Published",
  1: "Published",
  2: "Accepted",
  3: "Rejected",
  4: "Proof Submitted",
  5: "Proof Accepted",
  6: "Proof Rejected",
  7: "Not Responded",
  8: "Cancelled",
};

export const USER_TYPE = {
  1: "Admin",
  2: "Fan",
  3: "Athlete",
  4: "Merchant",
};

export const CONTRIBUTION_TYPE = {
  1: "Direct",
  2: "Round up",
  3: "Games",
  4: "Settlement",
};
export const contributionModes = [
  {
    value: 1,
    label: "Direct",
  },
  {
    value: 2,
    label: "Round up",
  },
  {
    value: 3,
    label: "Games",
  },
];

export const contributionTo = [
  {
    value: 2,
    label: "Fan",
  },
  {
    value: 4,
    label: "Merchant",
  },
];
export const roundUpStatus = [
  {
    value: true,
    label: "Enabled",
  },
  {
    value: false,
    label: "Disabled",
  },
];

export const EventStatusList = [
  {
    value: 0,
    label: "Not Published",
  },
  {
    value: 1,
    label: "Published",
  },
  {
    value: 2,
    label: "Accepted",
  },
  {
    value: 3,
    label: "Rejected",
  },
  {
    value: 4,
    label: "Proof Submitted",
  },
  {
    value: 5,
    label: "Proof Accepted",
  },
  {
    value: 6,
    label: "Proof Rejected",
  },
  {
    value: 7,
    label: "Not Responded",
  },
  {
    value: 8,
    label: "Cancelled",
  }
]


export const headers = ['Name', 'Followers'];
export const contributionHeader = ['Name', 'Amount contributed ($)'];
