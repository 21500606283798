import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useState } from "react";
import { _ } from "../../locale";
import toast from "react-hot-toast";
import Form from "react-bootstrap/Form";
import { deleteTeam, updateTeamStatus } from "../../actions/teams";
import TeamAddEditModal from "../../components/Modal/TeamAddEditModal";

function TeamSlide({
  slideData,
  index,
  srno,
  fetchData,
  activePage,
  filtersData,
}) {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  console.log(fetchData, "sdcvhsdcvjsd");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteTeam(slideData?.id);
              fetchData(1);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == "1" ? 0 : 1;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateTeamStatus(
                { status },
                slideData?.id
              );
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
  };

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>
        <Link to={`/dashboard/universities/team/athlete/${slideData?.id}`}>
          {(slideData?.name).charAt(0).toUpperCase() +
            (slideData?.name).slice(1) ?? null}
        </Link>
      </td>
      <td>{slideData?.sport?.name ?? null}</td>
      {/* <td>
        {slideData?.university?.name ?? null}
      </td> */}
      <td>
        {
          <Form.Check
            type="switch"
            id={`custom-switch-${slideData?.id}`}
            onChange={() => updateStatus(slideData?.id)}
            checked={slideData.status == 1}
          />
        }
      </td>

      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Update Password"
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
          {/* <li>
            <Link onClick={()=>{deleteRecord(slideData?.id)}}  className="btn btn-sm btn-soft-danger newBtnDel" title="Update Password">
              <i className="mdi mdi-delete-outline"></i>
            </Link>
          </li> */}
        </ul>
      </td>
      {show && (
        <TeamAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          teamId={slideData?.id}
          activePage = {activePage}
          filtersData = {filtersData}
        />
      )}
    </tr>
  );
}

export default TeamSlide;
