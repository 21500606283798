import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import ImageUploaderField from "../FormFields/ImageUploderFiled";
import AutoCompleteField from "../../actions/AutoComplete";
import {
  createUniversity,
  getSingalUniversityList,
  updateUniversity,
} from "../../actions/University";
import NewPhoneInputField from "../FormFields/NewPhoneInputFiled";
import { formatPhone } from "../../constants";
function UniversityAddModal({
  fetchData,
  show,
  onHide,
  id,
  setActivePage,
  activePage,
  filtersData,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({});
  const [defaultValue, setDefaultValue] = useState();
  const [defaultLatLng, setDefaultLatLng] = useState();
  const kGoogleKey = "AIzaSyBj2sDQqTe7MWv6F71KfmfUsh3LyaXhxdg";

  const onSubmit = async (formData) => {
    const payload = {
      ...formData,
      attachmentId: formData?.imageId?.id,
      contactCountryCode: "+1",
      mapAddress: formData?.location?.label,
      latitude: defaultLatLng?.lat
        ? defaultLatLng?.lat?.toString()
        : formData?.location?.latitude?.toString(),
      longitude: defaultLatLng?.lng
        ? defaultLatLng?.lng?.toString()
        : formData?.location?.longitude?.toString(),
    };

    delete payload?.location;
    delete payload?.imageId;
    delete payload?.countryCode;
    delete payload?.mobile;
    console.log(payload, "dchvdhjsc");

    try {
      let resp;
      if (id) {
        if (formData?.contactEmail)
          payload["contactEmail"] = formData?.contactEmail;
        if (formData?.contactName)
          payload["contactName"] = formData?.contactName;
        if (formData?.contactPhone)
          payload["contactPhone"] = formatPhone(formData?.contactPhone);
        resp = await updateUniversity(id, payload);
      } else {
        delete payload?.contactName;
        delete payload?.contactPhone;
        delete payload?.contactEmail;
        if (formData?.contactEmail !== "")
          payload["contactEmail"] = formData?.contactEmail;
        if (formData?.contactName !== "")
          payload["contactName"] = formData?.contactName;
        if (formData?.contactPhone !== "")
          payload["contactPhone"] = formatPhone(formData?.contactPhone);

        resp = await createUniversity(payload);
      }

      if (resp?.status === 200) {
        // Fetch data and hide the form
        onHide();
        fetchData(activePage, filtersData);
        setActivePage(1);
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const fetchSingalData = async (id) => {
    // setShowLoader(true);
    try {
      const resp = await getSingalUniversityList(id);
      console.log(resp, "dshbcvhjdsbc");
      const data = resp?.data?.responseData;
      setValue("name", data?.name);
      const locationDefaultValue = {
        lat: data?.latitude,
        lng: data?.longitude,
      };
      console.log(data?.userTeams, "sdjhcbsdjhbc");
      setDefaultLatLng(locationDefaultValue);

      // Update the defaultValue for the AutoCompleteField
      setDefaultValue(data?.mapAddress);

      // setValue("location", );
      setValue("contactName", data?.contactName);
      setValue("contactEmail", data?.contactEmail);
      setValue("contactPhone", data?.contactPhone);

      // setValue("countryCode", data?.contactCountryCode)
      setValue("imageId", data?.attachment);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      // setShowLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSingalData(id);
    }
    setValue("countryCode", "+1");
  }, [id]);
  console.log(id, "hvhjvhjj");

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {id !== null && id !== undefined
              ? _("EDIT_UNIVERSITY")
              : _("ADD_UNIVERISTY")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">
                        {_("UNIVERSITY_NAME") + "*"}
                      </label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_("E.g. Stanford University..")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("Enter university name"),
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="new-class">
                    <div className="mb-3">
                      <label className="form-label">
                        {_("UNIVERSITY_LOCATION") + "*"}
                      </label>
                      {defaultValue && id && (
                        <AutoCompleteField
                          control={control}
                          name="location"
                          placeholder="Enter location"
                          apiKey={kGoogleKey}
                          defaultValue={defaultValue}
                        />
                      )}

                      {defaultValue === undefined && id === undefined && (
                        <AutoCompleteField
                          control={control}
                          name="location"
                          placeholder="Enter location"
                          apiKey={kGoogleKey}
                        />
                      )}
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <ImageUploaderField
                        control={control}
                        label="Add university logo*"
                        name="imageId"
                        uploadType="image"
                        setValue={setValue}
                        changeLabel={"logo"}
                        uploadText="Upload logo"
                        extensionTypes={["jpeg", "jpg", "png"]}
                        rules={{
                          required: {
                            value: true,
                            message: "Upload logo",
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <b>{_("CONTACT_PERSON_DETAILS")}</b>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("NAME")}</label>
                      <Inputfield
                        control={control}
                        name={"contactName"}
                        placeholder={_("E.g. John..")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        // rules={{
                        //   required: {
                        //     value: true,
                        //     message: _("NAME_REQUIRED"),
                        //   },
                        // }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("EMAIL")}</label>
                      <Inputfield
                        control={control}
                        name={"contactEmail"}
                        placeholder={_("E.g. email@example.com")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          // required: {
                          //   value: true,
                          //   message: _("EMAIL_REQUIRED"),
                          // },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: _("INVALID_EMAIL_FORMAT"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3 ">
                      <label className="form-label">{_("Phone Number")}</label>

                      <div className="d-flex">
                        <div>
                          <Inputfield
                            control={control}
                            name={"countryCode"}
                            // placeholder={_("email@example.com")}
                            normalize={(e) => e.replace(/^\s+/g, "")}
                            type="text"
                            disabled={true}
                            value={"+1"}
                            inputClassName={"form-control country-code-style"}
                            // rules={{
                            //   required: {
                            //     value: false,
                            //     message: _("EMAIL_REQUIRED"),
                            //   },
                            // }}
                          />
                          <div>
                            <img
                              className="country-code-icon-stle"
                              src="/assets/images/usicon.webp"
                            />
                          </div>
                        </div>
                        <NewPhoneInputField
                          control={control}
                          // labelText={_("mobileNumber")}
                          name="contactPhone"
                          inputType="text"
                          placeholder={_("E.g. (123) 234-1234")}
                          formInWidth="w-100"
                          maxLength="14"
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: _("Enter phone number")
                          //   },
                          // }}
                          rules={{
                            pattern: {
                              value: /^.{10,}$/, // Minimum length of 10 characters
                              message:
                                "Phone number must be at least 10 characters long",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting && (
                        <Spinner animation="border spiner-border-style" />
                      )}
                      {id ? (
                        <span>{_("UPDATE")}</span>
                      ) : (
                        <span>{_("ADD_UNIVERSITY")}</span>
                      )}
                    </button>
                    <Link
                      onClick={onHide}
                      className="btn btn-secondary waves-effect"
                    >
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UniversityAddModal;
