import { Link, useSearchParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  deleteUniversity,
  updateUiversityStatus,
} from "../../actions/University";
import { useEffect, useState } from "react";
import { _ } from "../../locale";
import Form from "react-bootstrap/Form";
import AthleteAddEditModal from "../../components/Modal/AthleteAddEditModal";
import toast from "react-hot-toast";
import moment from "moment";
import { deleteAthlete, updateAthleteStatus } from "../../actions/athlete";
import FanViewModal from "../../components/Modal/FanViewModal";
import FanListModal from "../../components/Modal/FanFollowList";

function FanSlide({
  slideData,
  index,
  srno,
  fetchData,
  modalProperty,
  show,
  setShow,
  activePage,
  filtersData,
}) {
  const [showModal, setShowModal] = useState(false);
  const [fanFollowId, setFanFollowId] = useState(null);
  const [id, setId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(fetchData, "sdcvhsdcvjsd");
  const userId = searchParams.get("userid");
  console.log(userId, "dcvgsdhjc");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteAthlete(slideData?.id);
              fetchData(1);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == 1 ? false : true;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateAthleteStatus(slideData?.id, {
                status,
              });
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
    modalProperty(id);
  };
  const handleShow = (id) => {
    setShowModal(true);
    // setId(id);
    setFanFollowId(id);
    // modalProperty(id);
  };

  console.log(slideData?.userPreference?.isRoundUpEnabled);
  return (
    <>
      <tr>
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <Link
            onClick={() => {
              handleModalShow(slideData?.id);
            }}
          >
            {slideData?.userProfile?.name !== null
              ? (slideData?.userProfile?.name).charAt(0).toUpperCase() +
                (slideData?.userProfile?.name).slice(1)
              : "-" ?? null}
          </Link>
        </td>
        <td>
          {slideData?.countryCode ?? null} {slideData?.mobile ?? null}
        </td>
        <td>
          {
            <Form.Check
              type="switch"
              id={`custom-switch-${slideData?.id}`}
              onChange={() => updateStatus(slideData?.id)}
              checked={slideData.status == 1}
            />
          }
        </td>
        <td>
          {slideData?.userPreference?.isRoundUpEnabled === true
            ? "Enabled"
            : "Disabled"}
        </td>
        <td>{moment(slideData.createdAt).format("lll")}</td>

        <td>
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <Link
                onClick={() => {
                  handleModalShow(slideData?.id);
                }}
                className="btn btn-sm btn-soft-info newBtnEdit"
                title="View detail"
              >
                <i className="mdi mdi-eye-outline"></i>
              </Link>
            </li>
            <li>
              <Link
                to={`/dashboard/fan/transaction-detail/${slideData?.id}`}
                className="btn btn-sm btn-soft-info newBtnEdit"
                title="history"
              >
                <img
                  src="/assets/images/transaction-icon.svg"
                  alt=""
                  width={12}
                  height={12}
                  className=""
                />
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  handleShow(slideData?.id);
                }}
                className="btn btn-sm btn-soft-info newBtnEdit"
                title="Follow List"
              >
                <img
                  src="/assets/images/follow-icon.svg"
                  alt=""
                  width={11}
                  height={11}
                />
              </Link>
            </li>
          </ul>
        </td>
      </tr>
      {showModal && (
        <FanListModal
          show={showModal}
          onHide={() => setShowModal(false)}
          id={fanFollowId}
          fanName={slideData?.userProfile?.name}
        />
      )}
    </>
  );
}

export default FanSlide;
