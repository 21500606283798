import axios from "axios";
import {BASE_URL} from "../constants";

export const uploadFile = async (formData) =>{
    try{
        let {data} = await axios({
            method: 'POST',
            data: formData,
            url: `/attachment/upload`
        });
        return data
    }catch(error){
      console.log(error)
    }
}
export const uploadFileId = async(fileId) =>{
    let obj = {id: fileId}
    return axios({
        method: 'POST',
        data: obj,
        url: `/user/bulk`
    });
}

export const uploadFileIdForTeam = async(fileId) =>{
    let obj = {id: fileId}
    return axios({
        method: 'POST',
        data: obj,
        url: `/team/bulk`
    });
}
export const uploadFileIdForUniversity = async(fileId) =>{
    let obj = {id: fileId}
    return axios({
        method: 'POST',
        data: obj,
        url: `/university/bulk`
    });
}