import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useState } from "react";
import { _ } from "../../locale";
import toast from "react-hot-toast";
import { updateAthleteStatus } from "../../actions/athlete";
import moment from "moment";
import Form from "react-bootstrap/Form";
import EventAddEditModal from "../../components/Modal/EventAddEditModal";
import { deleteEvent, publishEvent } from "../../actions/events";
import MatchViewModal from "../../components/Modal/MatchViewModal";
import EvenetViewModal from "../../components/Modal/EventViewModal";
import HambergerMenu from "./Hamberger";
import { EVENT_STATUS } from "../../constants";

function EventSlide({
  slideData,
  index,
  srno,
  fetchData,
  modalProperty,
  activePage,
  filtersData,
}) {
  const [isChecked, setIsChecked] = useState(slideData.status == "1");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [showEvent, setShowEvent] = useState(false);
  console.log(slideData?.id, "sdcvhsdcvjsd");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteEvent(slideData?.id);
              fetchData(activePage, filtersData);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  // const updateStatus = () => {
  //   let status = slideData.status == 1 ? 0 : 1;
  //   let chkStatus = slideData.status == "1" ? 0 : 1;
  //   confirmAlert({
  //     title: "Confirm",
  //     message:
  //       slideData.status == "1"
  //         ? _("CONFIRM_DEACTIVATE")
  //         : _("CONFIRM_ACTIVATE"),
  //     buttons: [
  //       {
  //         label: _("YES"),
  //         onClick: async () => {
  //           try {
  //             const response = await publishEvent(

  //               slideData?.id,
  //               { status }
  //             );
  //             fetchData();
  //           } catch ({request,response}) {
  //             if (response) {
  //               toast.error(response?.data?.message);
  //             } else if (request) {
  //               toast.error(_('NO_INTERNET'));
  //             }
  //           }
  //         },
  //       },
  //       {
  //         label: _("NO"),
  //       },
  //     ],
  //   });
  // };

  const handleModalShow = (id) => {
    setShow(true);

    setId(id);
  };

  console.log(slideData?.startDate, slideData?.endDate, "sdcdsfsdfhsd");
  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>
        {slideData?.title?.charAt(0).toUpperCase() +
          slideData?.title?.slice(1) ?? null}
      </td>

      <td>{slideData?.merchant?.userProfile?.name}</td>

      <td>
        {
          <>
            <li className="d-flex align-items-center my-2">
              <img
                width={"25px"}
                height={"25px"}
                src={
                  slideData?.athlete?.userProfile?.profileImage !== null
                    ? slideData?.athlete?.userProfile?.profileImage?.filePath
                    : "/assets/images/user-default-image.webp"
                }
                className="rounded-circle"
              />
              <span className=" ms-3">
                {slideData?.athlete?.userProfile?.name}
              </span>
            </li>
          </>
        }
      </td>

      <td>{slideData?.mapAddress !== null ? slideData?.mapAddress : "-"}</td>

      {/* <td>
        {
          <Form.Check
            type="switch"
            id={`custom-switch-${slideData?.id}`}
            onChange={() => updateStatus(slideData?.id)}
            checked={isChecked}
          />
        }
      </td> */}
      <td>{EVENT_STATUS[`${slideData?.status}`] ?? null}</td>

      <td>
        {slideData?.startDate !== null
          ? moment(slideData?.startDate).format("lll")
          : "-"}
      </td>
      <td>
        {slideData?.endDate !== null
          ? moment(slideData?.endDate).format("lll")
          : "-"}
      </td>

      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          {/* <li>
            <Link onClick={()=>{setShowEvent(true)}}  className="btn btn-sm btn-soft-info newBtnEdit" title="View detail">
            <i className="mdi mdi-eye-outline eyeIcon"></i>
            </Link>
          </li> 
      
          <li>
            <Link onClick={() => { handleModalShow(slideData?.id) }} className="btn btn-sm btn-soft-info newBtnEdit" title="Update">
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>

          <li>
            <Link onClick={() => { deleteRecord(slideData?.id) }} className="btn btn-sm btn-soft-danger newBtnDel" title="Delete">
              <i className="mdi mdi-delete-outline"></i>
            </Link>
          </li> */}

          <li>
            <HambergerMenu
              slideData={slideData}
              setShowEvent={setShowEvent}
              handleModalShow={handleModalShow}
              deleteRecord={deleteRecord}
              fetchData={fetchData}
              activePage={activePage}
              filtersData={filtersData}
            />
          </li>
        </ul>
      </td>
      {show && (
        <EventAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          id={slideData?.id}
          activePage={activePage}
          filtersData={filtersData}
        />
      )}
      {showEvent && (
        <EvenetViewModal
          fetchData={fetchData}
          show={showEvent}
          onHide={() => {
            setShowEvent(false);
          }}
          id={slideData?.id}
          slideData={slideData}
        />
      )}
    </tr>
  );
}

export default EventSlide;
