import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import { _ } from "../../locale";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import AthleteContributionSkeleton from "./ContributionSkeleton";
import { getContributionById } from "../../actions/events";
import PageHeading from "../../components/common/PageHeading";
import ResponsivePagination from "react-responsive-pagination";

const AthleteContributionHistory = () => {
  const params = useParams();
  const id = params?.id;
  const { control, handleSubmit, reset, setValue } = useForm();
  const [loader, setLoader] = useState(false);
  const [contributionList, setContributionList] = useState({});
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(moment(new Date()));
  const [activeTab, setActiveTab] = useState("transferAmountDetail");
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersData, setFiltersData] = useState({});
  let search = searchParams.get("searchText");
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );

  const onSubmit = (data) => {
    // console.log(data, "ddd");
    setActivePage(1);
    let obj = {};
    if (Object.keys(data)?.length > 0) {
      for (let key in data) {
        if (key === "startDate" || key === "endDate") {
          if (data[key]) {
            obj[`${key}`] =
              key === "startDate"
                ? moment(data[key]).startOf("day").format()
                : moment(data[key]).endOf("day").format();
          }
        }
      }
    }
    console.log("obj+++", obj);
    setFiltersData(obj);
  };

  const constructSearchParams = () => {
    const queryParams = [];
    queryParams.push(`page=${activePage}`);

    if (Object.keys(filtersData)?.length > 0) {
      for (let key in filtersData) {
        if (key === "startDate" || key === "endDate") {
          queryParams.push(`${key}=${filtersData[key]}`);
        }
      }
    }
    const queryString = queryParams.join("&");
    setSearchParams(queryString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  const handleChange = (value) => {};
  const handlePageChange = (page) => {
    setActivePage(page);
  };
  const handleResetFilter = () => {
    reset();
    setActivePage(1);
    setFiltersData({});
    setStartDates(null);
    setEndDates(null);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const fetchRecord = async (page, filters) => {
    try {
      const params = {
        ...filters,
        page,
        perPage: 20,
        athleteId: id,
        type:activeTab === 'transferAmountDetail' ? 'completed'  : 'hold'
      };
      const res = await getContributionById(params);
      const responseData = res?.data?.responseData;
      setContributionList(responseData);
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      fetchRecord(activePage);
    }
  }, [activePage, activeTab]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <PageHeading heading={_("NILDEALS")} />
              </div>
            </div>

            <div className="d-flex flex-wrap gap-2 mb-4">
              <div
                className={`finsTabs ${
                  activeTab === "transferAmountDetail" ? "activeTabs" : ""
                }`}
                onClick={() => handleTabClick("transferAmountDetail")}
              >
                <h6 className="mb-0">Transfer Amount Details</h6>
              </div>
              <div
                className={`finsTabs ${
                  activeTab === "holdAmountDetail" ? "activeTabs" : ""
                }`}
                onClick={() => handleTabClick("holdAmountDetail")}
              >
                <h6 className="mb-0">Hold Amount Details</h6>
              </div>
            </div>
            {activeTab === "transferAmountDetail" ? (
              <>
                <form name="filter" onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex align-items-center gap-4">
                    <div className="d-flex w-50 justify-content-between align-items-center gap-3">
                      <div className="mb-3 w-50">
                        <ReactDatePicker
                          control={control}
                          name="startDate"
                          label="Start Date"
                          onChangeDate={(selectedDate) => {
                            const selectedMoment = moment(selectedDate);
                            setStartDates(selectedMoment);
                            // handleChange(selectedDate);
                          }}
                          minDate={new Date("1900/01/01")}
                          maxDate={endDates ? endDates.toDate() : null}
                          showTimeSelect={false}
                          placeholder="MM DD, YYYY"
                        />
                      </div>
                      -
                      <div className="mb-3 w-50">
                        <ReactDatePicker
                          control={control}
                          name="endDate"
                          label="End Date"
                          onChangeDate={(val) => {
                            setEndDates(moment(val).endOf("day"));
                            handleChange(val);
                          }}
                          selectedEndDate={endDates}
                          rules={{
                            validate: (value) => {
                              const selectedMoment = moment(value);
                              if (
                                selectedMoment.isBefore(startDates) &&
                                !selectedMoment.isSame(startDates, "day")
                              ) {
                                return "End date should be after start date";
                              }

                              return true;
                            },
                          }}
                          minDate={
                            startDates
                              ? startDates.toDate()
                              : new Date("1900/01/01")
                          }
                          showTimeSelect={false}
                          placeholder="MM DD, YYYY"
                        />
                      </div>
                    </div>

                    <div className="my-4 d-flex align-items-center gap-4 pt-1">
                      <button
                        className="btn newBtn btn-dark applyBtn"
                        type="submit"
                      >
                        Apply Now
                      </button>
                      <button
                        className="btn btn-danger applyBtn resetButton"
                        type="reset"
                        onClick={handleResetFilter}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
                <div className="py-3">
                  <h5 className="d-flex">
                    Total Amount Contributed:<h5 className="ms-4">{contributionList?.metaData?.totalAmount}</h5>{" "}
                  </h5>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" className="text-uppercase">
                              {_("CONTRIBUTED_DATE")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("EVENT_NAME")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("EVENT DATE")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("AMOUNT")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loader ? (
                            <AthleteContributionSkeleton count={5} />
                          ) : contributionList?.data?.length > 0 ? (
                            contributionList?.data
                              // ?.filter((obj) => obj.status === 5)
                              ?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {data?.contributedDate
                                        ? moment(data?.contributedDate).format(
                                            "lll"
                                          )
                                        : "-"}
                                    </td>
                                    <td>{data?.title}</td>
                                    <td>
                                      {data?.startDate
                                        ? moment(data?.startDate).format("lll")
                                        : "-"}
                                    </td>
                                    <td>{data?.amount}</td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td colSpan="12" className="fs-5 text-center">
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {contributionList && contributionList?.totalRecords > 20 && (
                  <ResponsivePagination
                    current={activePage}
                    total={contributionList?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                    maxWidth={20}
                  />
                )}
              </>
            ) : (
              <>
                <div className="pb-3 pt-2">
                  <h5 className="d-flex">
                    Hold Amount:<h5 className="ms-4">{contributionList?.metaData?.totalAmount}</h5>{" "}
                  </h5>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            {/* <th scope="col" className="text-uppercase">
                              {_("CONTRIBUTED_DATE")}
                            </th> */}
                            <th scope="col" className="text-uppercase">
                              {_("EVENT_NAME")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("EVENT DATE")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("AMOUNT")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loader ? (
                            <AthleteContributionSkeleton count={5} />
                          ) : contributionList?.data?.length > 0 ? (
                            contributionList?.data
                              // ?.filter(
                              //   (obj) =>
                              //     obj.status === 1 ||
                              //     obj.status === 2 ||
                              //     obj.status === 4 ||
                              //     obj.status === 6
                              // )
                              ?.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    {/* <td>
                                      {data?.contributedDate
                                        ? moment(data?.contributedDate).format(
                                            "lll"
                                          )
                                        : "-"}
                                    </td> */}
                                    <td>{data?.title}</td>
                                    <td>
                                      {data?.startDate
                                        ? moment(data?.startDate).format("lll")
                                        : "-"}
                                    </td>
                                    <td>{data?.amount}</td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td colspan="12" className="fs-5 text-center">
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {contributionList && contributionList?.totalRecords > 20 && (
                  <ResponsivePagination
                    current={activePage}
                    total={contributionList?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                    maxWidth={20}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AthleteContributionHistory;
