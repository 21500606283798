import React, { useEffect, useRef, useState } from "react";
import PageHeading from "../../components/common/PageHeading";
import { _ } from "../../locale";
import { Link, useSearchParams } from "react-router-dom";
import { getRolesList } from "../../actions/roles";
import RoleSlide from "./RoleSlide";
import RoleSkeleton from "./RoleSkeleton";
import Pagination from "react-js-pagination";
import { limit } from "../../constants";

const ManageRole = () => {
  const [roleList, setRoleList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const initalFetchData = useRef(true);
  const fetchData = async (page) => {
    try {
      const params = {
        page,
        perPage: 20,
      };
      const response = await getRolesList(params);
      const data = response?.data?.responseData;
      setRoleList(data);
      // setShowLoader(false);
      initalFetchData.current = false;
    } catch (error) {
      console.log(error);
      // setShowLoader(false);
      initalFetchData.current = false;
    }
  };
  const handlePageChange = (page) => {
    setActivePage(page);
  };

  useEffect(() => {
    fetchData(activePage);
  }, [activePage]);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading heading={"ROLES"} />
              <div className="flex-shrink-0">
                <Link
                  to={"/dashboard/role/add-role"}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_ROLE")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{_("NAME")}</th>
                        <th scope="col">{_("PERMISSIONS")}</th>
                        <th scope="col">{_("ACTION")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <RoleSkeleton count={8} />
                      ) : roleList &&
                        roleList?.data &&
                        roleList?.data?.length > 0 ? (
                        roleList &&
                        roleList?.data &&
                        roleList?.data?.length > 0 &&
                        roleList?.data?.map((obj, index) => (
                          <RoleSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            activePage={activePage}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pagination">
                {roleList && roleList?.totalRecords > 20 && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={20}
                    totalItemsCount={roleList?.totalRecords ?? 1}
                    pageRangeDisplayed={6}
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageRole;
