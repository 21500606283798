import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import moment from "moment";

function PotentialMerchantViewModal({ show, onHide, data, id }) {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setSearchParams(`userid=${id}`);
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <div className="userimg-name">
              <div>
                {data?.[0]?.merchantObject?.logo_url !== null ? (
                  <div className="image-container">
                    <img src={data?.[0]?.merchantObject?.logo_url} alt=""/>
                  </div>
                ) : (
                  <div className="image-container">
                    <img src={"/assets/images/user-default-image.webp"} alt="" />
                  </div>
                )}
              </div>
              <div>
                <h2>{data?.[0]?.merchantObject?.name}</h2>
                <h6>Id #{data?.[0]?.id}</h6>
              </div>
            </div>
          </div>
          <h6 onClick={onHide}>
            <img src="/assets/svg/cross.png" alt="" />
          </h6>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div>
              <table className="user-details">
                <tbody className="d-flex col-md-12">
                  <div className="d-flex col-md-12">
                    <div className="col-md-12 user-info-data">
                      <tr>
                        <td className="details-heading mw_100">
                          {_("MERCHANT_ID")}:
                        </td>
                        <td className="mw_100">
                          {data?.[0]?.merchantObject?.entity_id !== null
                            ? data?.[0]?.merchantObject?.entity_id
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="details-heading mw_100">
                          {_("PHONE_NO")}:
                        </td>
                        <td className="mw_100">
                          {data?.[0]?.merchantObject?.phone_number !== null
                            ? data?.[0]?.merchantObject?.phone_number
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="details-heading mw_100">
                          {_("TRANSACTION_COUNT")}:
                        </td>
                        <td className="mw_100">
                          {data?.[0]?.transactionCount !== null
                            ? data?.[0]?.transactionCount
                            : 0}
                        </td>
                      </tr>
                      <tr>
                        <td className="details-heading mw_100">
                          {_("CREATED_DATE")}:
                        </td>
                        <td className="mw_100">
                          {data?.[0]?.createdAt !== null
                            ? moment(data?.[0]?.createdAt).format("lll")
                            : "-"}
                        </td>
                      </tr>
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PotentialMerchantViewModal;
