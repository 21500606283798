export const locale = {
  //start english
  en: {
    SIGN_IN_CONTINUE: "Sign in to continue to Athlete Plus.",
    CRAFTED_WITH: "Invoicely. Crafted with",
    BY_ILLUMINZ: "by Illuminz",
    NO_INTERNET: "Server is busy please refersh in few minutes",
    EMAIL: "Email",
    EMAIL_REQUIRED: "Enter email",
    EMAIL_VALID: "Please enter valid email address",
    PASSWORD: "Password",
    PASSWORD_REQUIRED: "Enter password",
    PASSWORD_VALIDATE: "Password must be more than four characters",
    LOGIN: "Log In",
    PROJECTS: "Projects",
    NAME: "Name",
    STORE_NAME: "Store Name",
    DESCRIPTION: "Description",
    ACTION: "Actions",
    ADD_MILESSTONE_ITEAM: "ADD MILESSTONE ITEAM",
    ADD: "Add",
    CATEGORY_TYPE_INFO: "Set basic info for category type",
    TITLE: "Title",
    TITLE_REQUIRED: "Title is equired",
    DESCRIPTION_REQUIRED: "Description is required",
    SAVE: "Save",
    CANCEL: "Cancel",
    STATUS: "Status",
    ACTIVE: "Active",
    NOT_ACTIVE: "Not Active",
    EDIT_CATEGORY_TYPE: "Edit Category Type",
    EDIT: "Edit",
    CATEGORY: "Category",
    ADD_CATEGORY: "Add Category",
    IMAGE: "Image",
    PARENT_CATEGORY: "Parent Category",
    CONFIRM_DELETE: "Are you sure to delete this record?",
    YES: "Yes",
    NO: "No",
    CONFIRM_DEACTIVATE: "Are you sure to deactivate this record?",
    CONFIRM_ACTIVATE: "Are you sure to activate this record?",
    CATEGORY_INFO: "Set basic info for category",
    Menu: "Menu",
    MANAGEMENT: "Management",
    DASHBOARD: "Dashboard",
    MILESSTONE_ITEAMS: "MilesStone Iteams",
    ADD_EMAIL: "Add Email",
    CODE: "Code",
    SUBJECT: "Subject",
    EMAIL_TEMPLATE_INFO: "Set basic info for email template",
    SUBJECT_REQUIRED: "Subject is required",
    CODE_REQUIRED: "Code is required",
    REPLACEMENTS: "Replacements",
    REPLACEMENTS_REQUIRED: "Replacements is required",
    PLAN: "Plan",
    PLAN_LISTING: "Plan listing",
    CREATE_PLAN: "Create Plan",
    PLAN_INFO: "Set basic info for plan",
    // 'NAME_REQUIRED': 'Name is required',
    PAYMENT_GATEWAY: "Payment gateway",
    PAYMENT_GATEWAY_REQUIRED: "Payment gateway name is required",
    PLAN_ID: "Plan id",
    PLAN_ID_REQUIRED: "Plan id is required",
    DATE: "Date",
    POST: "Post",
    POST_LISTING: "Post listing",
    CREATE_POST: "Create post",
    POST_INFO: "Set basic info for post",
    EXCERPT: "Excerpt",
    EXCERPT_REQUIRED: "Excerpt is required",
    POST_TYPE: "Post type",
    POST_TYPE_REQUIRED: "Post type is required",
    CREATED_BY: "Created by",
    UPDATED_BY: "Updated by",
    BY: "By:",
    PAGE: "Page",
    EDIT_POST: "Edit post",
    PAGE_LISTING: "Page listing",
    CREATE_PAGE: "Create page",
    PAGE_INFO: "Set basic info for page",
    EDIT_PAGE: "Edit page",
    VIEW: "View",
    SUB_CATEGORY: "Sub category",
    ACCESS_CONTROL: "Access control",
    PERMISSIONS: "Permissions",
    ADD_PERMISSION: "Add permission",
    PERMISSION: "Permission",
    POST_CATEGORY: "Post category",
    ADD_POST_CATEGORY: "Add post category",
    VIEW_SUBCATEGORY: "View subcategory",
    EDIT_CATEGORY: "Edit category",
    CREATE_PERMISSION: "Create permission",
    ADMINONLY: "Admin only",
    PERMISSION_CODE: "Permission code",
    PERMISSION_CODE_REQUIRED: "Permission code required",
    PERMISSION_INFO: "Set basic info for permission",
    ROLES: "Roles",
    ROLE: "Role",
    ADD_ROLE: "Add Role",
    EDIT_ROLE: "Edit Role",
    ADD_NEW_ROLE: "Add New Role",
    ROLE_INFO: "Set basic info for role",
    STAFF: "Staff",
    ADD_NEW: "Add New",
    ADD_NEW_STAFF: "Add New Staff",
    ADD_STAFF: "Add Staff",
    PAGE_CATEGORY: "Page category",
    ADD_PAGE_CATEGORY: "Add page category",
    SUBSCRIPTION_PLAN: "Subscription plan",
    STORAGE_REQUIRED: "STORAGE_REQUIRED",
    STORAGE: "Storage",
    MB: "mb",
    CLINTS: "Clints",
    ADD_DIRECTORY: "Add directory",
    VIEW_SUBDIRECTORY: "View subdirectory",
    SYSTEM_DEFAULT: "System default",
    DIRECTORY_INFO: "Set basic info for directory",
    DIRECTORY_IMAGE: "Directory image",
    EDIT_DIRECTORY: "Edit directory",
    SIGNUP_TO_CONTINUE: "Sigup to continue to mono space",
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    BUILDING_USER: "Building user",
    ADD_BUILDING: "Add building user",
    BUILDINGS: "Building users",
    MOBILE: "Mobile",
    ADD_BUILDING_USER: "Add building user",
    CHNAGE_PASWWORD: "Change password",
    BUILDING: "Building",
    USERNAME: "Username",
    USERNAME_REQUIRED: "Username is required",
    PHONE_REQUIRED: "Enter phone number",
    BUILDING_DETAILS: "Building Details",
    BUILDINGS_DETAILS: "Building Details",
    TOTAL_BUILDINGS: "Total Buildings",
    PERSONAL_DETAILS: "Personal Details",
    JOINING_DATE: "Joining Date",
    PHONE: "Phone",
    BUILDING_NAME: "Building Name",
    BUILDING_ADDRESS: "Building Address",
    USER: "User",
    TENENT: "Tenent",
    EVENT: "Event",
    NEWS: "News",
    TICKET: "Ticket",
    BUILDING_MANAGERS: "Building Managers",
    ADD_BUILDING_MANAGERS: "Add Building Manager",

    UNIVERSITIES: "Universities",
    ADD_UNIVERISTY: "Add University",
    UNIVERSITY_NAME: "University",
    UNIVERSITY_LOCATION: "University location",
    CONTACT_PERSON_DETAILS: "Contact person details",
    ADD_UNIVERSITY: "Add university",
    SEARCH_UNIVERSITY: "Search university",
    UNIVERSITY_NAME_REQUIRED: "Enter university name",
    UNIVERSITY_IMAGE_REQUIRED: "Enter image university",
    UNIVERSITY_LOCATION_REQUIRED: "Enter university location",
    NAME_REQUIRED: "Enter name",
    EDIT_UNIVERSITY: "Edit University",

    ATHLETE_NAME: "Athlete",
    HOMETOWN: "Hometown",
    CONTRIBUTION_AMOUNT: "Contribution amount",
    CONTRIBUTED_TO: "Contributed To",
    // 'CONTRIBUTION_VIA': 'Contribution Via',
    FUNDS: "Balance Funds",
    REGISTRATION_DATE: "Registration date",
    ACTIVATION_DATE: "Activation date",
    ASSOCIATED_DATE: "Associated date",
    ATHLETES: "Athletes",
    LOGO: "Logo",
    SUPORTS_NAME: "Sports",
    ADD_SUPORTS: "Add suport",
    ADD_ATHLETE: "Add Athlete",
    ADD_SPORT: "Add Sport",
    EDIT_SPORT: "Edit Sport",
    ADD_TEAMS: "Add teams",
    ADD_TEAM: "Add Team",
    EDIT_TEAM: "Edit Team",
    SPORTS: "Sports",
    ENTER_SPORTS: "Enter sports",
    TEAM_NAME: "Team",
    TEAMS: "Teams",
    ENTER_EMAIL: "Enter email",
    ENTER_NAME: "Enter name",
    EDIT_ATHLETE: "Edit Athlete",
    FAN: "Fan",
    FANS: "Fans",
    ADD_FAN: "Add Fan",
    ADDRESS: "Address",

    LINKED_BANK_ACCOUNT: "Linked bank account",
    ROUND_UP_STATUS: "Round up status",
    DONATION_LIMIT: "Donation limit",
    MAXIMUM_CONTRIBUTION_AMOUNT: "Maximum contribution amount ($)",
    GIFT_CONTRIBUTION_AMOUNT: "Gift contribution amount ($)",
    ADD_SPORTS: "Add sport",
    UPDATE: "Update",
    INVALID_EMAIL_FORMAT: "Invalid email format",
    ENTER_LOCATION: "Enter location",
    ENTER_TEAMS: "Enter team(s)",
    ENTER_UNIVERSITY: "Enter university",
    ENTER_UNIVERSITY_1: "Enter university 1",
    ENTER_UNIVERSITY_2: "Enter university 2",
    BIO: "Bio",
    BALANCE_FUNDS: "Balance funds",
    INSTARGRAM: "Instagram",
    TIKTOK: "Tik Tok",
    FOLLOWER_COUNT: "Follower count",
    HOME_TOWN: "Home town",
    LOCATION: "Location",
    CONTACT_PERSON_NAME: "Contact person",
    MERCHANT: "Merchants",
    ADD_MERCHENT: "Add Merchant",
    POTENTIAL_MERCHANT: "Potential Merchants",
    MERCHANT_ID: "Merchant ID",
    MERCHANT_NAME: "Merchant Name",
    TRANSACTION_COUNT: "Transaction Count",
    CREATED_DATE: "Created Date",
    CONTRIBUTION_HISTORY: "Contribution History",
    CONTRIBUTION_DETAIL: "Contribution Details",
    EDIT_MERCHENT: "Edit Merchant",
    PHONE_NO: "Phone no.",
    MANAGER_NAME: "Manager",
    ROUND_STATUS: "RoundUp Status",
    ROUND_UP_BALANCE: "RoundUp Balance",
    ACTIONS: "Actions",
    MANAGER: "Manager",
    ROUNDUP_STATUS: "RoundUp Status",
    MULTIPLIER: "Multiplier",
    THRESHOLD_AMOUNT: "Threshold Amount",
    AMOUNT: "Amount",
    ROUNDUP_BALANCE: "RoundUp Balance",
    ROUNDUP_VALUE: "RoundUp Value",
    CONTRIBUTION_BALANCE: "Contribution Balance",
    USER_SPENT: "User Spent",
    // 'REGISTRATION_DATE' : 'Registration Date',
    ADD_MERCHANT: "Add Merchant",
    MATCHES: "Games",
    CREATE_GAME: "Create Game",
    GAME_LOCATION: "Game Location",
    ADD_MATCH: "Add Game",
    EDIT_MATCH: "Edit Game",
    ATHLETE: "Athlete",
    TEAM: "Team",
    UNIVERSITY: "University",
    TRANSACTION: "Transactions",
    START_DATE: "Start Date & Time",
    END_DATE: "End Date & Time",
    EVENTS: "Events",
    CREATE_EVENT: "Create Event",
    ADD_EVENT: "Add Event",
    EDIT_EVENT: "Edit Event",
    PUBLISH: "Publish",
    NAME_REQUIRED_1: "Enter team 1 name",
    NAME_REQUIRED_2: "Enter team 2 name",
    UPLOD_IMAGE_1: "Upload team 1 image",
    UPLOD_IMAGE_2: "Upload team 2 image",
    SPORT: "Sport",
    EVENT_LOCATION: "Event Location",
    EVENT_ID: "Event Id",
    EVENT_NAME: "Event Name",
    SELECT_MERCHANT: "Select Merchant",
    CONTRUBUTED_BY: "Contributed by",
    CONTRIBUTION_VIA: "Contribution via",
    CONTRIBUTION_TO: "Contribution to",
    TRANSACTION_AMOUNT: "Amount",
    EXPORT_TRANSACTION: "Export",

    EVENT_CREATED_AT: "Event Created At",
    EVENT_STATUS: "Event Status",
    SELECT_SPORTS: "Select Sports",
    // 'TEAMS' : 'Team(s)',
    CONNECTED: "Connected",
    NOT_CONNECTED: "Not Connected",
    SELECT_ATHLETE: "Select Athlete",
    PROOFIMAGE: "Proof Submit Image",
    USER_TYPE: "User type",
    ROUND_UP: "Round up",
    CONTRIBUTION_BY: "Contribution By",
    ID: "id",
    MESSAGE: "Message",
    NILDEALS: "NIL Deals",
    SETTING: "Settings",
    CONTRIBUTED_DATE: "Contributed Date",
    ACTIVE_ATHLETES: "Active Athletes",
    ACTIVE_TEAM: "Active Teams",
    ACTIVE_UNIVERSITY: "Active Universities",
    TOTAL_CONTRIBUTION: "Total Contribution",
    TRANSFER_AMOUNT: "Settled Amount",
    HOLD_AMOUNT: "Hold Amount",
    BY_CONTRIBUTION: "Top Athletes / Teams By Contributions",
    BY_FOLLOWER: "Top Athletes / Teams By Followers",
    REJECTED: "Rejected",
    SETTLED_AMOUNT: "Settled Amount",
    TRANSACTION_ID: "Transaction Id",
    SELECT_EVENT_STATUS: "Select Event Status",
    NOT_PUBLISHED: "Not Published",
    PUBLISHED: "Published",
    ACCEPTED: "Accepted",
    PROOF_SUBMITTED: "Proof Submitted",
    PROOF_REJECTED: "Proof Rejected",
    IMPORT_FILE: "Import File",
    DOWNLOAD_FILE: "Download File",
    POSTS: "Posts",
    USER_NAME: "User name",
    SPAM_COUNT: "Spam count",
    REASON: "Reason",
    MARKED_REASON: "Marked reason",
    COMMENTED_BY: "Commented by",
    ATTACHMENTS: "Attachments",
    INACTIVE: "Inactive",
    AUTO_HIDE: "Auto hide",
    DEACTIVE_POST: "Deactivate post",
  },
};
