import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { _ } from "../../locale";
import { getAthleteById } from "../../actions/athlete";
import Skeleton from "react-loading-skeleton";
import { getEvenetDetailsById, updateEventStatus } from "../../actions/events";
import { EVENT_STATUS } from "../../constants";
import MessageModal from "./MessageModal";
import { useRef } from "react";
import ProofImage from "../../pages/Events/ProofImage";
import ProofEventModal from "./ProofEventModel";
function EvenetViewModal({
  show,
  onHide,
  id,
  userId,
  slideData,
  fetchData,
  activePage,
}) {
  const [loader, setLoader] = useState(false);
  const [eventList, setEventList] = useState({});
  const [showProofEventModel, setProofEventModel] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitProofData, setSubmittProofData] = useState();
  const [proofId, setProofId] = useState("");
  const [showTextArea, setShowTextArea] = useState({
    showCancelTextArea: false,
    showRejectTextArea: false,
  });
  const [proofSubmitDetails, setProofSubmitDetails] = useState({
    createdAt: "",
    updatedAt: "",
    attachments: [],
    message: "",
  });
  const [reason, setReason] = useState({});
  const navigate = useNavigate();
  console.log(userId, "sdcbhsdhjc");
  const getFanDetailListing = async (id) => {
    setLoader(true);
    try {
      const resp = await getEvenetDetailsById(id);
      setEventList(resp?.data?.responseData);
      let proofSubmitt =
        resp?.data?.responseData?.eventActions.length > 0 &&
        resp?.data?.responseData?.eventActions.filter(
          (ev_data) => ev_data.proof?.length > 0
        );
      if (proofSubmitt) {
        setProofSubmitDetails((prevState) => ({
          ...prevState,
          createdAt: proofSubmitt[0]?.createdAt,
          updatedAt: proofSubmitt[0]?.updatedAt,
          attachments: proofSubmitt[0]?.proof,
          message: proofSubmitt[0]?.message,
        }));
      }
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFanDetailListing(id);
    }
  }, [id]);
  useEffect(() => {
    setSearchParams(`userid=${id}`);
  }, []);

  const handleCancelText = () => {
    setShowTextArea((prevState) => ({
      ...prevState,
      showCancelTextArea: false,
      showRejectTextArea: false,
    }));
    setReason((prevState) => ({}));
  };

  const handleEventProofMessage = async (eventId, eventStatus = "") => {
    let obj;
    try {
      if (
        showTextArea?.showRejectTextArea ||
        showTextArea?.showCancelTextArea
      ) {
        obj = {
          status: showTextArea?.showRejectTextArea ? 6 : 8,
          message: showTextArea?.showRejectTextArea
            ? reason?.rejectEventReason
            : reason?.cancelEventReason,
        };
        setShowTextArea((prevState) => ({
          ...prevState,
          showCancelTextArea: false,
          showRejectTextArea: false,
        }));
        const resp = await updateEventStatus(eventId, obj);
      } else {
        obj = {
          status: EVENT_STATUS[`${eventStatus}`] === "Not Published" ? 1 : 5,
        };
        const resp = await updateEventStatus(eventId, obj);
        if(resp?.status !== 200){
          toast.error(resp?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000, // 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }else{
          const message = EVENT_STATUS[`${eventStatus}`] === "Not Published" ? 'Event published successfully' : 'Proof accepted successfully'
          toast.success(message, {
            position: "top-right",
            autoClose: 5000, // 5 seconds
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          fetchData(activePage);
        }
      }
      fetchData(activePage);
      getFanDetailListing(id);
    } catch ({ request, response }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const handleMessageChange = (e, eventName) => {
    let value = e.target.value;
    if (showTextArea?.showRejectTextArea) {
      setReason((prevState) => ({ ...prevState, rejectEventReason: value }));
    } else {
      setReason((prevState) => ({ ...prevState, cancelEventReason: value }));
    }
  };
  const handleEventProof = (id) => {
    setProofId(id);
    setProofEventModel(true);
  };

  const getStatusMessage = (event_action) => {
    let message;
    switch (EVENT_STATUS[`${event_action?.status}`]) {
      case "Cancelled":
        message = (
          <>
            <tr className="d-flex">
              <td className="details-heading">Cancelled By</td>
              <td className="event-proof mw-100">
                {event_action?.userId === eventList?.athlete?.id
                  ? "Athlete"
                  : "Admin"}
              </td>
            </tr>
            <tr className="d-flex">
              <td className="details-heading">Reason</td>
              <td className="event-proof mw-100">
                {event_action?.message ? event_action?.message : "----"}
              </td>
            </tr>
            <hr class="border-bottom  border-light" />
          </>
        );
        break;
      case "Rejected":
        message = (
          <>
            <tr className="d-flex">
              <td className="details-heading">Reason</td>
              <td className="event-proof mw-100">
                {event_action?.message ? event_action?.message : "----"}
              </td>
            </tr>
            <hr class="border-bottom  border-light" />
          </>
        );
        break;
      default:
        return null; // Render nothing for other cases
    }
    return message;
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <div className="userimg-name">
              <div>
                {eventList?.attachment !== null ? (
                  <div className="image-container">
                    <img src={eventList?.attachment?.filePath} />
                  </div>
                ) : (
                  <div className="image-container">
                    <img src={"/assets/images/user-default-image.webp"} />
                  </div>
                )}
              </div>
              <div>
                <h2>{eventList?.title}</h2>
                <h6>Event Id #{eventList?.id}</h6>
                {EVENT_STATUS[`${slideData?.status}`] === "Not Published" ? (
                  <button
                    className="mt-2 btn btn-secondary"
                    onClick={() =>
                      handleEventProofMessage(slideData?.id, slideData?.status)
                    }
                  >
                    Publish
                  </button>
                ) : null}
              </div>
            </div>
            <h6 onClick={onHide}>
              <img src="/assets/svg/cross.png" />
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div className="popup-header">
              {/* <h3>{fanList.userProfile?.name}</h3> */}
            </div>

            <div>
              <table className="user-details">
                <tbody className="d-flex col-md-12">
                  <div className="d-flex col-md-12">
                    <div className="col-md-12 user-info-data">
                      <tr className="d-flex">
                        <td className="details-heading">{_("Description")}:</td>
                        <td className="text-break gap-1 mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : eventList?.description ? (
                            eventList?.description
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">
                          {_("Start Date & Time")}:
                        </td>
                        <td className=" gap-1 mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : eventList?.startDate !== null ? (
                            moment(eventList?.startDate).format("lll")
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">
                          {_("End Date & Time")}:
                        </td>
                        <td className=" gap-1 mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : eventList?.startDate !== null ? (
                            moment(eventList?.endDate).format("lll")
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">{_("Merchant")}:</td>
                        <td className="mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : eventList?.merchant !== null ? (
                            eventList?.merchant?.userProfile?.name
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">
                          {_("Event Location")}:
                        </td>
                        <td className="gap-1 mw-100">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : eventList?.mobile !== null ? (
                            eventList?.mapAddress !== null ? (
                              eventList?.mapAddress
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">{_("Amount ($)")}:</td>
                        <td className=" gap-1">
                          {loader ? (
                            <Skeleton width={300} height={15} />
                          ) : eventList?.amount ? (
                            eventList?.amount
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">{_("Athlete")}:</td>
                        <td className=" gap-1 mw-100">
                          {
                            <div className="athlete-img-style">
                              <div className="mb-3">
                                <li className="d-flex align-items-center my-2">
                                  <img
                                    width={"50px"}
                                    height={"50px"}
                                    src={
                                      eventList?.athlete?.userProfile
                                        ?.profileImage?.filePath
                                        ? eventList?.athlete?.userProfile
                                            ?.profileImage?.filePath
                                        : "/assets/images/user-default-image.webp"
                                    }
                                    alt={``}
                                    className="rounded-circle"
                                  />
                                  <span className=" ms-3">
                                    {eventList?.athlete?.userProfile?.name}
                                  </span>
                                </li>
                              </div>
                            </div>
                          }
                        </td>
                      </tr>

                      <tr className="d-flex">
                        <td className="details-heading">
                          {_("EVENT_CREATED_AT")}:
                        </td>
                        <td className="mw-100">
                          {moment(slideData?.createdAt).format("lll")}
                        </td>
                      </tr>
                      <tr className="d-flex">
                        <td className="details-heading">
                          {_("EVENT_STATUS")}:
                        </td>
                        <td className="mw-100">
                          {EVENT_STATUS[slideData?.status]}
                        </td>
                      </tr>
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
            <div>
              <h2 className="ps-2">Event History</h2>
              <table className="user-details">
                <tbody className="d-flex col-md-12">
                  <div className="d-flex col-md-12">
                    <div className="col-md-12 user-info-data">
                      {eventList?.eventActions?.length > 0 ?(
                        eventList?.eventActions?.map((event_action, index) => {
                          return (
                            <>
                              <tr className="d-flex">
                                <td className="details-heading">
                                  {EVENT_STATUS[`${event_action?.status}`]}
                                </td>
                                <td className="event-proof mw-100">
                                  {/* {event_action?.updatedAt
                                    ? moment(
                                        `${event_action?.updatedAt}`
                                      ).format("lll")
                                    : moment(
                                        `${event_action?.createdAt}`
                                      ).format("lll")} */}
                                      {moment(
                                        `${event_action?.createdAt}`
                                      ).format("lll")}
                                </td>
                              </tr>
                              {EVENT_STATUS[`${event_action?.status}`] ===
                                "Proof Rejected" ||EVENT_STATUS[`${event_action?.status}`] ===
                                "Rejected" || EVENT_STATUS[`${event_action?.status}`] ===
                                "Cancelled" ||
                              EVENT_STATUS[`${event_action?.status}`] ===
                                "Proof Submitted" ? (null):(
                                  eventList?.eventActions?.length-1 !== index && (
                                  <hr class="border-bottom  border-light" />
                                  ))
                              }
                              
                              {/* {EVENT_STATUS[`${event_action?.status}`] === 'Cancelled' ? (<>
                               <tr className="d-flex">
                               <td className="details-heading">
                                CancelledBy
                               </td>
                               <td className="event-proof mw-100">
                                 {event_action?.userId === eventList?.athlete?.id ? 'Athlete' : 'Admin'}
                               </td>
                             </tr>
                               <tr className="d-flex">
                               <td className="details-heading">
                                 Reason
                               </td>
                               <td className="event-proof mw-100">
                                {event_action?.message ? event_action?.message : '----'}
                               </td>
                             </tr>
                             </>
                              ):null} */}
                              {getStatusMessage(event_action)}
                              {EVENT_STATUS[`${event_action?.status}`] ===
                                "Proof Rejected" ||
                              EVENT_STATUS[`${event_action?.status}`] ===
                                "Proof Submitted" ? (<>
                                <tr className="d-flex">
                                  <td className="details-heading">
                                    {EVENT_STATUS[`${event_action?.status}`] ===
                                    "Proof Rejected"
                                      ? "Reason"
                                      : "Message"}
                                  </td>

                                  <td className="event-proof mw-100">
                                    {EVENT_STATUS[`${event_action?.status}`] ===
                                    "Proof Rejected" ? (
                                      `${event_action?.message}`
                                    ) : (
                                      <>
                                        {loader ? (
                                          <Skeleton width={300} height={15} />
                                        ) : (
                                          <div className="athlete-img-style">
                                            <div className="">
                                              <div className="d-flex flex-column ">
                                                <div
                                                  onClick={() =>
                                                    handleEventProof(
                                                      event_action?.id
                                                    )
                                                  }
                                                >
                                                  <img
                                                    width={"50px"}
                                                    height={"50px"}
                                                    src={
                                                      event_action?.proof[0]
                                                        ?.filePath
                                                    }
                                                    alt={
                                                      event_action?.proof[0]
                                                        ?.fileName
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    // onClick={()=>navigate('/dashboard/events/proofImage')}
                                                    className="rounded-circle"
                                                  />
                                                  <span>
                                                    {event_action?.proof
                                                      ?.length > 1
                                                      ? `+${
                                                          event_action?.proof
                                                            ?.length - 1
                                                        }`
                                                      : null}
                                                  </span>
                                                </div>
                                                <p
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {event_action?.message}
                                                </p>
                                              </div>
                                              <div>
                                                {EVENT_STATUS[
                                                  `${slideData?.status}`
                                                ] === "Proof Submitted" &&
                                                proofSubmitDetails?.createdAt ===
                                                  event_action?.createdAt ? (
                                                  showTextArea?.showCancelTextArea ||
                                                  showTextArea?.showRejectTextArea ? (
                                                    <div>
                                                      <textarea
                                                      placeholder={`Please enter reason for ${showTextArea?.showCancelTextArea ? 'cancellation' : 'rejection'}`}
                                                        className="form-control"
                                                        rows="5"
                                                        onChange={(e) =>
                                                          handleMessageChange(
                                                            e,
                                                            "Cancel"
                                                          )
                                                        }
                                                      ></textarea>
                                                      <div className="mt-2 d-flex gap-2">
                                                        <button
                                                          className="btn btn-success"
                                                          onClick={() =>
                                                            handleEventProofMessage(
                                                              slideData?.id
                                                            )
                                                          }
                                                        >
                                                          Submit Text
                                                        </button>
                                                        <button
                                                          className="btn btn-danger ml-2"
                                                          onClick={
                                                            handleCancelText
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <div className="d-flex gap-2">
                                                      <button
                                                        type="submit"
                                                        className="btn newBtn btn-dark waves-light"
                                                        onClick={() =>
                                                          handleEventProofMessage(
                                                            slideData?.id
                                                          )
                                                        }
                                                      >
                                                        Accept
                                                      </button>
                                                      <button
                                                        type="submit"
                                                        className="btn newBtn btn-dark waves-light"
                                                        onClick={() =>
                                                          setShowTextArea(
                                                            (prevState) => ({
                                                              ...prevState,
                                                              showRejectTextArea: true,
                                                            })
                                                          )
                                                        }
                                                      >
                                                        Reject
                                                      </button>
                                                      <button
                                                        type="submit"
                                                        className="btn newBtn btn-dark waves-light"
                                                        onClick={() =>
                                                          setShowTextArea(
                                                            (prevState) => ({
                                                              ...prevState,
                                                              showCancelTextArea: true,
                                                            })
                                                          )
                                                        }
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  )
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>  
                                 {eventList?.eventActions?.length-1 !== index ? (
                                  <hr class="border-bottom  border-light mt-0" />
                                 ): null}
                                  
                                </>

                              ) : null}
                            </>
                          );
                        })):(
                        <tr >
                        <td colspan="12">
                          <h5 className="mt-2 noUser-found text-truncate mb-4">
                          No action taken on the event yet
                          </h5>
                        </td>
                      </tr>
                        )}
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        {showProofEventModel && (
          <ProofEventModal
            showProofEventModel={showProofEventModel}
            setProofEventModel={setProofEventModel}
            eventList={eventList}
            proofId={proofId}
          />
        )}
      </Modal>
    </>
  );
}

export default EvenetViewModal;
