import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {clearSession} from "../../utilities";

function UserInfo() {
   const [showInfo, setShowInfo] = useState(null);
   const [userDetail, setUserDetail] = useState(null);
   const dropdownRef = useRef(null);

   const showPanel = (info) => {
    if(info){
      setShowInfo(null);
    } else {
      setShowInfo('show');
    }
  }

  const userInfo = () => {
    if (localStorage.getItem("auth")) {
      const user = localStorage.getItem("auth");
      setUserDetail(JSON.parse(user));
    }
  };

  const logoutSystem = () => {
    clearSession();
  }

  useEffect(() => {
    userInfo();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowInfo(null);
    }
  };

  return (
    <div ref={dropdownRef} className="dropdown d-inline-block" onClick={() => showPanel(showInfo)} >
      <button type="button" className={"btn header-item  " + showInfo} id="page-header-user-dropdown"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img className="rounded-circle header-profile-user header-profile" src="/assets/images/user-img.jpeg"
            alt="Header Avatar" />
        <span className="d-none d-xl-inline-block ms-1" key="t-henry">{userDetail?.name ?? 'Guest'}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
      </button>
      <div className={"dropdown-menu dropdown-menu-end "+ showInfo}>
      <Link className="dropdown-item" to="/dashboard/profile"> <i className="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></Link>
        {/*}
        <a className="dropdown-item d-block" href="#"><span className="badge bg-success float-end">11</span><i className="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Settings</span></a>
        <a className="dropdown-item" href="#"><i className="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a>
        */}
        <div className="dropdown-divider"></div>
        <a className="dropdown-item text-danger" href="javascript:void(0)" onClick={logoutSystem} ><i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></a>
      </div>
    </div>
  );
}

export default UserInfo;
