import { useController } from "react-hook-form";
import Select from "react-select";
import { errorType } from "../../constants";

function ReactSelectField({
  name,
  control,
  rules,
  defaultValue,
  label,
  multi,
  prefixClass,
  containerClass,
  optionValue,
  optionLabel,
  readOnly,
  options,
  onSelect,
  placeholder,
  normalize,
  onChange,
  section='',
  classname='',
  maxHeight,
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  // handle value on change
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    console.log(value,"Sdcsbdhj")
    if (multi) {
        let multiData = value.map((_value) =>
          optionValue !== undefined ? _value[optionValue] : _value?.value
        );
        field.onChange(multiData);
    }else{

      console.log('vvvv', value);

      if (onSelect) {
        if (normalize) onSelect(normalize(value?.value));
        else onSelect(value?.value);
      }
  
      // send value to hook form
      if (normalize) field.onChange(normalize(value?.value));
      else field.onChange(value?.value);
  
    }
  };

  // handling value according to selected one
  const handleValue = (value) => {
    console.log(value,"sdchjbdc")
  
    if (field?.value !== "") {
      if (multi) {
        return options?.filter((c) =>
          field?.value?.includes?.(
            optionValue !== undefined ? c[optionValue] : c.value
          )
        );
      } else {
        
        return options?.find((c) =>
          optionValue
            ? c[optionValue] === field?.value
            : c.value === field?.value
        );
        
      }
    
    } else {
      return "";
    }
  };


  const handleSelect = (val) => {
    alert(val, "Hello")
  }

  return (
    <>
      
              <Select
                inputRef={field.ref}
                isDisabled={readOnly}
                value={handleValue()}
                onChange={(val) => handleChange(val)} 
                onSelect={(val) => console.log(val, "HeuJh")}
                options={options}
                placeholder={placeholder ? placeholder : label}
                isMulti={multi}
                getOptionLabel={(opt) => opt[optionLabel]}
                getOptionValue={(opt) => opt[optionValue]}
                className={containerClass || ""}
                classNamePrefix={prefixClass || ""}
                isClearable={section? true : false}
                maxMenuHeight={maxHeight ? maxHeight : ''}
              />
            
            {errorType?.map((type) => {
              if (error?.type === type && error?.message !== "")
                return <span key={type} className="error-msg">{error?.message}</span>;
            })}
          
    </>
  );
}

export default ReactSelectField;
