import { Route, Routes } from "react-router-dom";
import Header from "../../components/common/Header";
import LeftNavigation from "../../components/common/LeftNavigation";
import Dashboard from "../dashboard/Dashboard";
import Profile from "../profile/Profile";
import PageNotFound from "../Page not found/PageNotFound";
import University from "../University/University";
import ManageAthlete from "../Athlete/ManageAthlete";
import ManageSuports from "../Suports/ManageSuports";
import ManageTeam from "../Teams/ManageTeam";
import ManageFan from "../Fan/ManageFan";
import ManageMerchent from "../Merchent/ManageMerchent";
import ManageMatch from "../Matches/ManageMatches";
import ManageEvents from "../Events/ManageEvenets";
import ManageTeamAthlete from "../TeamAthletes/ManageTeamAthlete";
import ManageTransaction from "../Transactions/ManageTransaction";
import ContributionHistory from "../Merchent/ContributionHistory";
import MerchantTransactionDetails from "../Merchent/TransactionDetails";
import LeaderboardMatch from "../Matches/LeaderboardMatch";
//import ManageTransaction from "../Transactions/ManageTransaction";
import ProofImage from "../Events/ProofImage";
import ManageRole from "../Role/ManageRole";
import ManageStaff from "../Staff/ManageStaff";
import AddNewRole from "../Role/AddNewRole";
import AddStaff from "../Staff/AddStaff";
import AthleteContributionHistory from "../Athlete/ContributionHistory";
import AthleteTransactionDetails from "../Athlete/TransactionDetails";
import Settings from "../Setting/ManageSetting";
import ManageContribution from "../Contributions/ManageContribution";
import FanTransactionDetails from "../Fan/transactionDetail";
import ManagePotentialMerchant from "../PotentialMerchant/ManagePotentialMerchant";
import Posts from "../Posts/ManagePosts";

function Layout() {
  return (
    <div id="layout-wrapper">
      <Header />
      <LeftNavigation />

      <div className="main-content">
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/universities" element={<University />} />
          <Route path="/athlete" element={<ManageAthlete />} />
          <Route
            path="/athlete/nil-deals/:id"
            element={<AthleteContributionHistory />}
          />
          <Route
            path="/athlete/transaction-details/:id"
            element={<AthleteTransactionDetails />}
          />
          <Route
            path="/universities/team/athlete/:id"
            element={<ManageTeamAthlete />}
          />
          <Route path="/sports" element={<ManageSuports />} />
          <Route path="/universities/teams/:id" element={<ManageTeam />} />

          <Route path={"/fan"} element={<ManageFan />} />
          <Route
            path={`/fan/transaction-detail/:id`}
            element={<FanTransactionDetails />}
          />

          <Route path="/merchant" element={<ManageMerchent />} />
          <Route path="/potential" element={<ManagePotentialMerchant />} />
          <Route
            // path="/merchant/contribution-history/:id/:name"
            path="/merchant/contribution-history/:id"
            element={<ContributionHistory />}
          />
          <Route
            path="/merchant/transaction-details/:id/:name"
            element={<MerchantTransactionDetails />}
          />
          <Route path="/match" element={<ManageMatch />} />
          <Route path="/match/leaderboard/:id" element={<LeaderboardMatch />} />
          <Route path="/events" element={<ManageEvents />} />
          <Route path="/transaction" element={<ManageTransaction />} />
          <Route path="/contribution" element={<ManageContribution />} />
          <Route path="/role" element={<ManageRole />} />

          <Route path="/role/add-role" element={<AddNewRole />} />
          <Route path="/role/edit-role/:id" element={<AddNewRole />} />

          <Route path="/staff" element={<ManageStaff />} />
          <Route path="/staff/add-staff" element={<AddStaff />} />
          <Route path="/staff/edit-staff/:id" element={<AddStaff />} />
          <Route path="/events/:id/proof-image" element={<ProofImage />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/posts" element={<Posts />} />
        </Routes>
      </div>
    </div>
  );
}

export default Layout;
