import { useController } from "react-hook-form";
import { errorType } from "../../constants";
import { useState, useEffect } from "react";

function MultiSelectCheckboxField({
  name,
  control,
  rules,
  defaultValue,
  options,
  onChange,
  selectedAthleteIds,
  selectedAthlete
}) {
  const {
    field,
    fieldState: { error },
    
  } = useController({ name, control, rules,  rules: {
    required: selectedAthleteIds ? false : 'select atleast one athlete',
  }, defaultValue: defaultValue || [] });
  const [selectedIds, setSelectedIds] = useState(selectedAthleteIds || []);
  const [searchInput, setSearchInput] = useState('');
  console.log(options, "zcbxzc")
  const onInputChange = (_value) => {
    const newValue = selectedIds.includes(_value)
      ? selectedIds.filter((item) => item !== _value)
      : [...selectedIds, _value];
    console.log(newValue, "sdvjhbsd")
    setSelectedIds(newValue);
    onChange(newValue);
    if (onChange) onChange(newValue);
    field.onChange(newValue);
  };

  useEffect(() => {
    setSelectedIds(selectedAthleteIds || []);
  }, [selectedAthleteIds]);

  const filteredAthleteList = options?.filter((athlete) =>
    athlete?.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <>
      <div className="inline-field-wrpr">
        <div className={`form-in ${error ? "required-field" : ""}`}>
          <div className="position-relative d-flex align-items-center search_input  mt-2">
            <span className="search-icon">
              <img src="/assets/svg/search-normal.svg" alt="" />
            </span>
            <input
              className="form-control form-control-lg w-100 p-0  fw-600 h4_1"
              type="text"
              placeholder="Search athlete..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          <ul className="ps-0 pt-3 select-dropdown-style">
            {/* <div className="athleteViewBox">
            {filteredAthleteList?.map((_option, index) => (
              <li key={index} className="my-2 checkbox-li">
                <div
                  className="d-flex justify-content-evenly align-items-center"
                  key={index}
                  onClick={() => onInputChange(_option.id)} // Make the entire card clickable
                >
                  <img
                    width="50px"
                    height="50px"
                    src={_option.profileImage || "/assets/images/user-default-image.webp"}
                    alt=""
                    className="rounded-circle"
                  />
                  <span className="">{_option?.name}</span>
                  <label
                    className="form-check-label style-checkbox"
                    onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the outer click
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedIds?.includes(_option.id)}
                      onChange={() => onInputChange(_option.id)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </li>
            ))}
          </div> */}
            <div className="athleteViewBox">
              {filteredAthleteList?.map((_option, index) => (
                <li key={index} className="my-2 checkbox-li">
                  <div
                    className={`d-flex justify-content-between align-items-center ${_option?.flag ? 'disabled gray' : ''}`}
                    key={index}
                    onClick={() => _option?.flag ? null : onInputChange(_option.id)} // Disable click if flag is true
                  >
                    <img
                      width="50px"
                      height="50px"
                      src={_option.profileImage || "/assets/images/user-default-image.webp"}
                      alt=""
                      className="rounded-circle"
                    />
                    <span className="">{_option?.name}</span>
                    <label
                      className="form-check-label style-checkbox"
                      onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the outer click
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedIds?.includes(_option.id)}
                        onChange={() => _option?.flag ? null : onInputChange(_option.id)} // Disable checkbox if flag is true
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li>
              ))}
            </div>

          </ul>

        </div>

      </div>
      {errorType?.map((type) =>
        error?.type === type && error?.message !== "" ? (
          <p key={type} className={"error-msg error-multi-selector"}>
            {error?.message}
          </p>
        ) : null
      )}
    </>
  );
}

MultiSelectCheckboxField.defaultProps = {
  defaultValue: [],
  rules: {},
  errorClass: "error",
  onChange: (value) => value,
  optionLabel: "name",
  optionValue: "id",
};

export default MultiSelectCheckboxField;

