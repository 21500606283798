import axios from "axios";

export function getSystemDetails() {
  return axios({
    method: "GET",
    url: "/admin/getSystemDetails",
  });
}

export function getDashaboardChartDetails(params) {
  return axios({
    method: "GET",
    params: params,
    url: "/admin/analytics",
  });
}
export function getMonthlyEventContribution() {
  return axios({
    method: "GET",
    url: "/admin/analytics/event-contribution",
  });
}

export function getDashaboardOverviewDetails(params){
    
    return axios({
        method: 'GET',
        params:params,
        url: '/admin/analytics/overview'
    })
}
export function getDashaboardEventStatusDetails(params){
    
    return axios({
        method: 'GET',
        params:params,
        url: '/admin/analytics/event-status'
    })
}
export function getDashaboardCategoryContribution(params){
    
    return axios({
        method: 'GET',
        params:params,
        url: '/admin/analytics/categorywise-contributions'
    })
}
export function getTopAthleteByContribution(params){
    
    return axios({
        method: 'GET',
        params:params,
        url: '/admin/analytics/top-contributions'
    })
}
export function getTopAthleteByFollower(params){
    
    return axios({
        method: 'GET',
        params:params,
        url: '/admin/analytics/top-follower'
    })
}
