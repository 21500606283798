import React from "react";
import Skeleton from "react-loading-skeleton";
import { _ } from "../../locale";

const MatchShow = ({ matchList, loader }) => {
  return (
    <>
      <div className="d-flex user-info-data">
        {matchList?.teams?.map((team, index) => (
          <div key={index} className="col-md-6">
            <div className="d-flex align-items-center mb-2">
              <div>
                <img
                  src={
                    team?.attachment
                      ? team?.attachment?.filePath
                      : "/assets/images/user-img.jpeg"
                  }
                  alt=""
                  height={45}
                  width={45}
                  className="rounded-circle"
                />
              </div>
              <h5 className="margin-left mb4 mb-0 fs-4">{`Team ${String.fromCharCode(
                65 + index
              )}`}</h5>
            </div>

            <div className="row align-items-center">
              <div
                className="col-md-6 details-heading"
                style={{ paddingLeft: "21px" }}
              >
                {_("Team")}:
              </div>
              <div className="col-md-6">
                {loader ? (
                  <Skeleton width={200} height={15} />
                ) : team.name !== null ? (
                  team.name
                ) : (
                  "-"
                )}
              </div>
            </div>

            <div className="row align-items-center">
              <div
                className="col-md-6 details-heading"
                style={{ paddingLeft: "21px" }}
              >
                {_("University")}:
              </div>
              <div className=" col-md-6 mt-1 gap-1">
                {loader ? (
                  <Skeleton width={200} height={15} />
                ) : team.university !== null ? (
                  team?.university?.name
                ) : (
                  "-"
                )}
              </div>
            </div>
            <tr className="d-flex">
              <td className="details-heading">{_("ATHLETE")+ "(s)"}:</td>
            </tr>
            <tr>
              <td className="athlete-img-style gap-1 w-100 mt-1 mw-100">
                {loader ? (
                  <Skeleton width={200} height={15} />
                ) : team?.athletes !== null ? (
                  <div className="row">
                    {team.athletes.map((athlete, index) => (
                      <div className="athlete-show-style col-md-6" key={index}>
                        {console.log(
                          athlete?.athlete?.userProfile?.profileImage?.filePath,
                          "sdbchsdhj"
                        )}

                        <img
                          src={
                            athlete?.athlete?.userProfile?.profileImage
                              ?.filePath !== undefined
                              ? athlete?.athlete?.userProfile?.profileImage
                                  ?.filePath
                              : "/assets/images/user-default-image.webp"
                          }
                          alt={`Profile Image ${index + 1}`}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            marginBottom: "5px",
                          }}
                        />
                        <div>{athlete?.athlete?.userProfile?.name}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </div>
        ))}
      </div>
    </>
  );
};

export default MatchShow;
