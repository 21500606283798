import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { updateUiversityStatus } from "../../actions/University";
import { useState } from "react";
import { _ } from "../../locale";
import toast from "react-hot-toast";
import { deleteTeam } from "../../actions/teams";
import TeamAddEditModal from "../../components/Modal/TeamAddEditModal";
import moment from "moment";
import RoundUpModal from "../../components/Modal/RoundUpModal";
import { CONTRIBUTION_TYPE, EVENT_STATUS, USER_TYPE } from "../../constants";
import { getTransactionViewDetails } from "../../actions/transaction";


function TransactionSlide({ slideData, index, srno, fetchData }) {
  const [status, setStatus] = useState(slideData?.status);
  const [isChecked, setIsChecked] = useState(slideData.status == "1");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const [viewTransDetail, setViewTransDetail] = useState(false);
  console.log(fetchData, "sdcvhsdcvjsd");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteTeam(slideData?.id);
              fetchData(1);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == "1" ? 0 : 1;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateUiversityStatus(
                { status },
                slideData?.id
              );
              fetchData();
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
  };

  const viewTransactionDetails = async(id, page)=>{
      try {
        const params = {
          page,
          perPage:10, // Include filters in the request parameters
        };
        const {responseData} = await getTransactionViewDetails(id,params);
        setViewTransDetail(responseData?.distribution)
       setShowModal(true)
      } catch ({ response, request }) {
        if (response) {
          toast.error(response?.data?.message);
        } else if (request) {
          toast.error(_("NO_INTERNET"));
        }
      }
  
  }

  const handleShow = (id) => {};

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td>
        {slideData?.createdAt
          ? moment(slideData?.createdAt).format("lll")
          : null}
      </td>
      <td className="text-wrap">{slideData?.transactionId ? slideData?.transactionId  : '----'}</td>
      <td>{USER_TYPE[`${slideData?.userType}`]}</td>
      <td>
        {slideData?.fan?.userProfile
          ? slideData?.fan?.userProfile?.name
          : null}
      </td>
      <td>{CONTRIBUTION_TYPE[`${slideData?.type}`]}</td>
      <td>{slideData?.amount !== null ? parseFloat(slideData.amount).toFixed(2) : null}</td>
      <td>
                 <Link
                onClick={() => viewTransactionDetails(slideData?.id)}
                className="d-flex align-items-center gap-2"
                title="View detail"
              >
                <i className="mdi mdi-eye-outline"></i>
                <span>view</span>
              </Link>
      </td>
      {show && (
        <TeamAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          teamId={slideData?.id}
        />
      )}
      {showModal && (
        <RoundUpModal
          show={showModal}
          viewTransDetail={viewTransDetail}
          onHide={() => {
            setShowModal(false);
          }}
          contributionMode = {slideData?.type}
        />
      )}
    </tr>
  );
}

export default TransactionSlide;
