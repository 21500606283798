import axios from "axios";
import {BASE_URL} from "../constants";

export const UNIVERSITY_LISTING = 'UNIVERSITY_LISTING';
// export const PLAN_DETAIL = 'PLAN_DETAIL';
// export const DELETE_PLAN = 'DELETE_PLAN';
// export const PLAN_STATUS = 'PLAN_STATUS';

export const getUniversityListing = (props) => {
    console.log("universityProps+++++",props)
    return axios({
        method: 'GET',
        params: props,
        url: `/university`
    });
};
export const getUniversityForDropdown = (props) => {
    console.log(props,"sdvhbsdb")
    return axios({
        method: 'GET',
        params: props,
        url: `/university/all`
    });
};

export const createUniversity = (payload) => {
    console.log(payload)
    return axios({
        method: 'POST',
        data: payload,
        url: `/university`
    });
};

export const getSingalUniversityList = (id) => {
    return axios({
        method: 'GET',
        
        url: `/admin/university/${id}`
    });
};

export const updateUiversityStatus = (payload,id) => {
    console.log(payload,"dsbcdhjsbc")
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/university/${id}/status`
    });
};

export const deleteUniversity = (id) => {
    return axios({
        method: 'DELETE',
        url: `/university/${id}`
    });
};

export const updateUniversity = (id,data) => {
    return axios({
        method: 'PATCH',
        data:data,
        url: `/university/${id}`
    });
};


