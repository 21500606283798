import axios from "axios";

export function login(payload) {
  return axios({
    method: "POST",
    data: payload,
    url: "/user/login",
  });
}

export function uploadFile(props) {
  return axios({
    method: "POST",
    data: props,
    url: `/attachment/upload`,
  });
}

export function removeFile(uniqueName) {
  return axios({
    method: "DELETE",
    // params: props,
    url: `attachment/` + uniqueName,
  });
}
export function getUserData() {
  return axios({
    method: "GET",
    url: "/user/profile",
  });
}

export function changePassword(params) {
  return axios({
    method: "PATCH",
    data: params,
    url: "/user/change-password",
  });
}

export function changeNumber(id, data){
  return axios({
    method:"PATCH",
    data : data,
    url: `/user/${id}`
  })
}