import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import MerchentAddEditModal from "../../components/Modal/MerchentAddEditModal";

function PotentialMerchentSlide({
  slideData,
  index,
  srno,
  fetchRecord,
  show,
  setShow,
  modalProperty
}) {
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(null);
  console.log(id,"hjjhjh")
  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
    modalProperty(id);
  };

  const handleShow= () => {
    setShowModal(true)
  }

  return (
    <>
    <tr>
      <td className="mw-100">{parseInt(srno + parseInt(index + 1))}</td>
      <td className="mw-100">{slideData?.merchantObject?.entity_id}</td>
      <td className="mw-100">
        {slideData?.merchantObject?.name?.charAt(0).toUpperCase() +
          slideData?.merchantObject?.name?.slice(1) ?? null}
      </td>
      <td className="mw-100">
        {slideData?.transactionCount ? slideData?.transactionCount : 0}
      </td>
      <td>{moment(slideData.createdAt).format("lll")}</td>
      <td className="mw-100">
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="View detail"
            >
              <i className="mdi mdi-eye-outline eyeIcon"></i>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                handleShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Update"
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
        </ul>
      </td>
    </tr>
    {showModal && (
        <MerchentAddEditModal
          fetchData={fetchRecord}
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          data={slideData}
        //   setActivePage={setActivePage}
        />
      )}
    </>
  );
}

export default PotentialMerchentSlide;
