import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { deleteStaffById, resetPassword } from "../../actions/staff";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { _ } from "../../locale";
import { Link } from "react-router-dom";
import PasswordInputfield from "../../components/FormFields/PasswordInputFiled";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { updateAthleteStatus } from "../../actions/athlete";
import Spinner from "react-bootstrap/esm/Spinner";

function StaffSlide({
  slideData,
  index,
  srno,
  fetchData,
  activePage,
  filtersData,
}) {
  const [showModal, setShowModal] = useState(false);
  const [getPassword, setGetPassword] = useState(true);

  const showPassword = () => {
    setGetPassword(!getPassword);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    reset();
  };
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const deleteRecord = (id) => {
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteStaffById(id);
              fetchData();
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };
  const updateStatus = () => {
    let status = slideData.status == 1 ? false : true;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateAthleteStatus(slideData?.id, {
                status,
              });
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const onSubmit = async (values) => {
    let data = {
      userId: slideData?.id,
      password: values?.password,
    };
    try {
      await resetPassword(data);
      toast.success("Password changed successfully");
      reset();
      handleCloseModal();
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      <tr>
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td className="mw-100">
          {slideData?.userProfile?.name !== null
            ? slideData?.userProfile?.name.charAt(0).toUpperCase() +
              slideData?.userProfile?.name.slice(1)
            : "-" ?? null}
        </td>
        <td className="text-wrap mw-100">
          {slideData?.Roles?.map((item, index) => {
            return (
              <div key={index} className="d-inline-flex pe-1">
                {item?.name} {index !== slideData?.Roles?.length - 1 && ","}
              </div>
            );
          })}
        </td>
        <td className="mw-100">{slideData?.email}</td>
        <td>
          {slideData?.mobile === null ? "-" : slideData?.countryCode}
          {slideData?.mobile}
        </td>
        <td>
          {
            <Form.Check
              type="switch"
              id={`custom-switch-${slideData?.id}`}
              onChange={() => updateStatus(slideData?.id)}
              checked={slideData?.status === 1}
            />
          }
        </td>
        <td>
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <Link
                to={`/dashboard/staff/edit-staff/${slideData?.id}`}
                className="btn btn-sm btn-soft-info newBtnEdit"
                title="Update"
                activePage={activePage}
                filtersData={filtersData}
              >
                <i className="mdi mdi-pencil-outline"></i>
              </Link>
            </li>
            {/* <li>
              <Link
                onClick={() => {
                  deleteRecord(slideData?.id);
                }}
                className="btn btn-sm btn-soft-danger newBtnDel"
                title="Delete"
              >
                <i className="mdi mdi-delete-outline"></i>
              </Link>
            </li> */}
            <li>
              <div
                onClick={() => {
                  setShowModal(true);
                  // setId(data?.id);
                }}
                className="btn btn-sm btn-soft-info newBtnEdit"
                title="Change Password"
              >
                Change Password
              </div>
            </li>
          </ul>
        </td>
      </tr>

      {showModal && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="modal-dialog-margin"
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-12 mb-4 mt-2">
                <label className="form-label">New Password</label>
                <div className="input-group auth-pass-inputgroup">
                  <PasswordInputfield
                    control={control}
                    name={"password"}
                    label={_("PASSWORD")}
                    placeholder={_("Enter new password")}
                    normalize={(e) => e.replace(/^\s+/g, "")}
                    type={getPassword === true ? "password" : "input"}
                    inputClassName={"form-control"}
                    showPassword={showPassword}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter new password",
                      },
                      minLength: {
                        value: 4,
                        message: _("PASSWORD_VALIDATE"),
                      },
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap gap-2 mt-1 mb-3">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`btn newBtn btn-dark  waves-light ${
                    isSubmitting && "btn-loader"
                  }`}
                >
                  {isSubmitting && (
                    <Spinner animation="border spiner-border-style" />
                  )}
                  <span>{_("UPDATE")}</span>
                </button>
                <button
                  type="btn"
                  onClick={handleCloseModal}
                  className="btn btn-secondary"
                >
                  {_("CANCEL")}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default StaffSlide;
