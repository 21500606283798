import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ContributionSkeleton = ({ count }) => {
  const items = new Array(count).fill(0);
  return (
    <>
      {items.map((item, index) => (
        <tr>
          <td>
            <Skeleton width={70} height={15} />
          </td>
          <td>
            <Skeleton width={100} height={15} />
          </td>
          <td>
            <Skeleton width={45} height={15} />
          </td>
          <td>
            <Skeleton width={100} height={15} />
          </td>
          <td>
            <Skeleton width={70} height={15} />
          </td>
          <td>
            <Skeleton width={70} height={15} />
          </td>
        </tr>
      ))}
    </>
  );
};

export default ContributionSkeleton;
