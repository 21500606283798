import { useLocation } from "react-router";

function Loader() {
  const location = useLocation();
  const url = location.pathname;

  return (
    <>
      <div className="inLoader">
        <div className="">
          <span className="circle circle-1"></span>
          <span className="circle circle-2"></span>
          <span className="circle circle-3"></span>
          <span className="circle circle-4"></span>
          <span className="circle circle-5"></span>
        </div>
      </div>
    </>
  );
}

export default Loader;
