import React, { useEffect, useState } from "react";
import { _ } from "../../locale";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Inputfield from "../../components/FormFields/InputField";
import NewPhoneInputField from "../../components/FormFields/NewPhoneInputFiled";
import Spinner from "react-bootstrap/esm/Spinner";
import { changeNumber, changePassword, getUserData } from "../../actions/auth";
import { formatPhone } from "../../constants";
import PasswordInputfield from "../../components/FormFields/PasswordInputFiled";

function Profile() {
  const navigate = useNavigate();
  const {
    setValue,
    handleSubmit,
    control,
    isSubmitting,
    reset,
    formState: { errors },
  } = useForm();

  const {
    handleSubmit: handleSubmitPassword,
    control: passwordControl,
    isSubmitting: isPasswordSubmitting,
  } = useForm();

  
  const [getPassword, setGetPassword] = useState(true);
  const [newPassword, setNewPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState(true);
  const [id, setId] = useState(null);

  const showPassword = () => {
    setGetPassword(!getPassword);
  };
  const showNewPassword = () => {
    setNewPassword(!newPassword);
  };
  const showConfirmPassword = () => {
    setConfirmPassword(!confirmPassword);
  };


  const onSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        countryCode: "+1",
        mobile: formatPhone(values?.mobile),
      };
      delete payload.name;
      delete payload.email;
     const response =  await changeNumber(id, payload);
     if(response?.status === 200){
      toast.success('Profile updated successfully', {
        position: "right-top",
        // autoClose: 50000, 
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
     }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const onSubmitPassWord = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      toast.error("New Password and Confirm password do not match");
    } else {
     try{
     const response =  await changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      })
      // setValue("oldPassword", "")
      // setValue("newPassword", "")
      // setValue("confirmedPassword", "")
      toast.success("Password updated successfully!");
      // window.location.reload();
    }
      
      catch ({ request, response }) {
        if (response) {

          toast.error('Old password is incorrect', {
                    position: "top-right",
                    autoClose: 5000, // 5 seconds
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
        } else if (request) {
          toast.error(_("NO_INTERNET"));
        }
      }
   }
  };

  const fetchRecord = async () => {
    try {
      const resp = await getUserData();
      const data = resp?.data?.responseData;
      setValue("name", data?.userProfile?.name);
      setValue("email", data?.email);
      setValue("mobile", data?.mobile);
      setId(data?.id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchRecord();
  }, []);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_("PROFILE SETTINGS")}</h4>
              <div className="flex-shrink-0"></div>
            </div>
          </div>
        </div>
        <form
          style={{ width: "65%", margin: "1rem", padding: "1rem" }}
          class="bg-white"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h4 className="mb-4 font-size-18">{_("MY PROFILE")}</h4>
          <div className="row">
            <div className="col-6 mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Name
              </label>
              <Inputfield
                control={control}
                name={"name"}
                label={_("NAME")}
                placeholder={_("E.g. Robin")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type={"text"}
                disabled={true}
                inputClassName={"form-control"}
                rules={{
                  required: { value: true, message: _("PASSWORD_REQUIRED") },
                  minLength: {
                    value: 4,
                    message: _("PASSWORD_VALIDATE"),
                  },
                }}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">{_("EMAIL") + "*"}</label>
              <Inputfield
                control={control}
                name={"email"}
                placeholder={_("E.g. example@gmail.com")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="email"
                disabled={true}
                inputClassName={"form-control"}
                rules={{
                  required: {
                    value: true,
                    message: _("ENTER_EMAIL"),
                  },
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                    message: _("INVALID_EMAIL_FORMAT"),
                  },
                }}
              />
            </div>
            <div className="col-md-6 ">
              <label className="form-label">{_("Phone Number") + "*"}</label>

              <div className="d-flex">
                <div>
                  <Inputfield
                    control={control}
                    name={"countryCode"}
                    placeholder={_("+1")}
                    normalize={(e) => e.replace(/^\s+/g, "")}
                    type="text"
                    disabled={true}
                    value={"+1"}
                    inputClassName={"form-control country-code-style"}
                    rules={{
                      required: {
                        value: false,
                        message: _("Country code is required"),
                      },
                    }}
                  />
                  <div>
                    <img
                      className="country-code-icon-stle"
                      src="/assets/images/usicon.webp"
                    />
                  </div>
                </div>
                <NewPhoneInputField
                  control={control}
                  // labelText={_("mobileNumber")}
                  name="mobile"
                  inputType="text"
                  placeholder={_("E.g. (123) 234-1234")}
                  formInWidth="w-100"
                  maxLength="14"
                  rules={{
                    required: {
                      value: true,
                      message: _("Enter phone number"),
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`btn newBtn btn-dark  waves-light my-2 ${
              isSubmitting && "btn-loader"
            }`}
          >
            {isSubmitting && <Spinner animation="border spiner-border-style" />}
            {_("SAVE")}
          </button>
        </form>

        <form
          style={{ width: "65%", margin: "1rem", padding: "1rem" }}
          class="bg-white"
          onSubmit={handleSubmitPassword(onSubmitPassWord)}
        >
          <h4 className="mb-4 font-size-18">{_("RESET PASSWORD")}</h4>
          <div className="col-md-12 mb-4 mt-2">
            <label className="form-label">Old Password</label>
            <div className="input-group auth-pass-inputgroup">
              <PasswordInputfield
                control={passwordControl}
                name={"oldPassword"}
                label={_("Old Password")}
                placeholder={_("Old password")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type={getPassword === true ? "password" : "input"}
                inputClassName={"form-control"}
                showPassword={showPassword}
                rules={{
                  required: { value: true, message: _("PASSWORD_REQUIRED") },
                  minLength: {
                    value: 4,
                    message: _("PASSWORD_VALIDATE"),
                  },
                }}
              />
            </div>
          </div>
          <div className="col-md-12 mb-4 mt-2">
            <label className="form-label">New Password</label>
            <div className="input-group auth-pass-inputgroup">
              <PasswordInputfield
                control={passwordControl}
                name={"newPassword"}
                label={_("PASSWORD")}
                placeholder={_("New password")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type={newPassword === true ? "password" : "input"}
                inputClassName={"form-control"}
                showPassword={showNewPassword}
                rules={{
                  required: { value: true, message: _("PASSWORD_REQUIRED") },
                  minLength: {
                    value: 4,
                    message: _("PASSWORD_VALIDATE"),
                  },
                }}
              />
            </div>
          </div>
          <div className="col-md-12 mb-4 mt-2">
            <label className="form-label">Confirm Password</label>
            <div className="input-group auth-pass-inputgroup">
              <PasswordInputfield
                control={passwordControl}
                name={"confirmPassword"}
                label={_("PASSWORD")}
                placeholder={_("Confirm new password")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type={confirmPassword === true ? "password" : "input"}
                inputClassName={"form-control"}
                showPassword={showConfirmPassword}
                rules={{
                  required: { value: true, message: _("PASSWORD_REQUIRED") },
                  minLength: {
                    value: 4,
                    message: _("PASSWORD_VALIDATE"),
                  },
                }}
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isPasswordSubmitting}
            className={`btn newBtn btn-dark  waves-light my-2 ${
              isPasswordSubmitting && "btn-loader"
            }`}
          >
            {isPasswordSubmitting && (
              <Spinner animation="border spiner-border-style" />
            )}
            {_("Change Password")}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Profile;
