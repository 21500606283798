import axios from "axios";
// import { BASE_URL } from "../constants";

export const getPostListing = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: `/admin/posts`,
  });
};

export const getPostSpamListing = (id) => {
  return axios({
    method: "GET",
    url: `/post/${id}/spam-list`,
  });
};

export const managePostSpamStatus = (id, data) => {
  return axios({
    method: "PATCH",
    data: data,
    url: `/post/${id}/status`,
  });
};
