

import { UNIVERSITY_LISTING } from "../actions/University";

const initialState = {
  universityListing: [],
};

const university = (state = initialState, action) => {
  switch (action.type) {
    case UNIVERSITY_LISTING :
      return {
        ...state,
        universityListing: action.payload,
      };
    break;
    default: return state
  }
}
export default university;