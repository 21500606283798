import axios from "axios";
export const USER_LISTING = 'USER_LISTING';

export const getContributionDetails = (params) => {
    return axios({
        method: 'GET',
        params:params,
        url: `/admin/contribution/events`
    });
};
export const getContributionExportData = async(params) => {
    try{
        const {data} = await axios({
          method: 'GET',
          params:params,
          url: `admin/contribution/events/exports`
      });
      return data   
    }catch(error){
      console.log(error)
    }
  };
  