import React from "react";
import { useState } from "react";
import { getEvenetDetailsById } from "../../actions/events";
import toast from "react-hot-toast";
import { _ } from "../../locale";
import PageHeading from "../../components/common/PageHeading";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from "react";
const ProofImage = () => {
  const [loader, setLoader] = useState(false);
  const [eventList, setEventList] = useState({});
  const [eventProofImage, setEventProofImage] = useState({});
   const location = useLocation()
   const { id } = useParams();
 const queryParams = queryString.parse(location.search)
 const proofId = queryParams.proofId;

  const getEventDetailListing = async (id) => {
    setLoader(true);
    try {
      const resp = await getEvenetDetailsById(id);
      let proofSubmitt =
        resp?.data?.responseData?.eventActions.length > 0 &&
        resp?.data?.responseData?.eventActions.filter( (ev_data) => ev_data.id == proofId );
      if (proofSubmitt) {
        console.log("proofSubmitt+++", proofSubmitt)
        setEventProofImage(proofSubmitt[0]);
      }
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };
  useEffect(()=>{
    getEventDetailListing(id)
  },[id])
  console.log("asdfgh", eventProofImage);

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading
                heading={"PROOFIMAGE"}
                //   count={list?.totalRecords || 0
              />
            </div>
          </div>
        </div>
        {eventProofImage?.proof?.length > 0 && eventProofImage?.proof?.map((proofDetails)=>{
        return <div className="proof-image-card">
          <img style={{width:'100%', height:'450px', objectFit:'fill'}} src={proofDetails?.filePath} alt={proofDetails?.fileName}/>
          <p>{proofDetails?.createdAt}</p>
          </div>
        })}
         <div>
          <div>
            <button>Back to previous page</button>
          </div>
         </div>
      </div>
    </div>
  );
};

export default ProofImage;
