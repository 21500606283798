import React, { useEffect, useState } from "react";
import PageHeading from "../../components/common/PageHeading";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import { _ } from "../../locale";
import { addRole, getById, getPermissionList } from "../../actions/roles";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

const AddNewRole = () => {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const [permissionList, setPermissionList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const params = useParams();
  const roleId = params?.id;

  const fetchData = async () => {
    try {
      const response = await getPermissionList();
      const data = response?.data?.permissions;
      setPermissionList(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSingalRecords = async (roleId) => {
    try {
      const res = await getById(Number(roleId));
      const response = res?.data?.responseData;
      const permissions = response?.Permissions.map(
        (permission) => permission.id
      );
      setSelectedIds(permissions);
      setValue("name", response?.name);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    values = { ...values, permissions: selectedIds };
    if (roleId) {
      values = Object.assign(values, { roleId: roleId });
    }
    try {
      await addRole(values);
      navigate("/dashboard/role");
    } catch (error) {
      if (error?.response?.data?.errors) {
        toast.error(error?.response?.data?.errors?.permissions);
      }
      console.log(error?.response?.data?.errors?.permissions, "error");
    }
  };
  useEffect(() => {
    fetchData();
    if (roleId) {
      fetchSingalRecords(roleId);
    }
  }, [roleId]);

  const handleCheckboxChange = (permissionId) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(permissionId)) {
        return prevSelectedIds.filter((id) => id !== permissionId);
      } else {
        return [...prevSelectedIds, permissionId];
      }
    });
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center">
              <h3>
                {roleId !== null && roleId !== undefined
                  ? _("EDIT_ROLE")
                  : _("ADD_NEW_ROLE")}
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row card">
                <div className="card-body">
                  <div className="mb-3 col-6">
                    <label className="form-label">{_("NAME") + "*"}</label>
                    <Inputfield
                      control={control}
                      name={"name"}
                      placeholder={_("E.g. Event manager")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("Enter role name"),
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    {permissionList?.map((data, index) => {
                      return (
                        <div key={index} className="d-flex">
                          <div className="">
                            <input
                              type="checkbox"
                              name="permissions"
                              className="form-check-input"
                              checked={selectedIds.includes(data.id)}
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </div>
                          <p className="ps-2">{data?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-2 mt-3 mb-5">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`btn newBtn btn-dark  waves-light ${
                      isSubmitting && "btn-loader"
                    }`}
                  >
                    {isSubmitting && (
                      <Spinner animation="border spiner-border-style" />
                    )}
                    {roleId ? (
                      <span>{_("UPDATE")}</span>
                    ) : (
                      <span>{_("ADD_ROLE")}</span>
                    )}
                  </button>
                  <Link to={"/dashboard/role"} className="btn btn-secondary">
                    {_("CANCEL")}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewRole;
