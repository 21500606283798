import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _ } from "../../locale";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { managePostSpamStatus } from "../../actions/posts";

function InactivePost({ reload, show, onHide, id, slideData }) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({});
  const [defaultValue, setDefaultValue] = useState();

  const onSubmit = async (formData) => {
    const payload = {
      ...formData,
      status: 0,
    };

    try {
      let resp = await managePostSpamStatus(id, payload);
      if (resp?.status === 200) {
        // Fetch data and hide the form
        reload();
        onHide();
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {_("DEACTIVE_POST")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="">
                    <div className="mb-3">
                      <label className="form-label">{_("REASON") + "*"}</label>
                      <InputTextAreaField
                        control={control}
                        name="reason"
                        label={_("example 1234")}
                        fieldClass={"form-control"}
                        maxLength={500}
                        rules={{
                          required: {
                            value: true,
                            message: _("REASON_REQUIRED"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting && (
                        <Spinner animation="border spiner-border-style" />
                      )}
                      <span>{_("UPDATE")}</span>
                    </button>
                    <Link onClick={onHide} className="btn btn-secondary">
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default InactivePost;
