import axios from "axios";
export const USER_LISTING = 'USER_LISTING';

export const createMatch = (data) => {
    return axios({
        method: 'POST',
        data: data,
        url: `/match`
    });
};

export const getMatchById = (id) => {
    return axios({
        method: 'GET',
        // data: data,
        url: `/match/${id}`
    });
};

export const getMatchData = (params) => {
    return axios({
        method: 'GET',
        params: params,
        url: `/matches`
    });
};

export const deleteMatch = (id) => {
    return axios({
        method: 'DELETE',
        url: `/match/${id}`
    });
};

export const updateMatch = (id,data) => {
    return axios({
        method: 'PATCH',
        data:data,
        url: `/match/${id}`
    });
};






