import { useEffect } from "react";
import { getDashaboardChartDetails } from "../../actions/dashboard";
import ReactTable from "../../components/common/ReactTable";

export const matchesTabs = [
  { eventKey: "tab1", title: "Today", content: "bsvddvwe" },
  { eventKey: "tab2", title: "This week,", content: "hjcbhsdjbchdjbhbjeh" },
  {
    eventKey: "tab3",
    title: "his month,",
    content: "hjfhfehrfgergfegerfheheehbvf",
  },
];

export const AmountContributionChart = (contributionAmounts, types = "") => {
  return {
    chart: {
      type: types === "linechart" ? "column" : "pie",
    },
    title: {
      text: types === "linechart" ? "Contributions By Fans/Merchants" : "",
    },
    credits: {
      enabled: false,
    },
    // subtitle: {
    //   text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>',
    // },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Amount($)",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          //format: types === 'piechart' ? '{point.y:.1f}%' : "{point.y:.1f} $",
          formatter: function () {
            return types === "piechart"
              ? this.y.toFixed(2) + "%"
              : this.y.toFixed(2); // Append % after the digit
          },
        },
      },
    },
    tooltip: {
      enabled: false, // Disable tooltip on hover
    },
    // tooltip: {
    //   headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //   pointFormat:
    //     '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    // },

    series: [
      {
        name: "Contributions",
        colorByPoint: true,
        data: [
          {
            name: "Direct",
            y:
              types === "piechart"
                ? Number(
                    `${
                      (contributionAmounts?.direct /
                        (contributionAmounts?.direct +
                          contributionAmounts?.roundups +
                          contributionAmounts?.matches)) *
                      100
                    }`
                  )
                : Number(`${contributionAmounts?.direct}`),
          },
          {
            name: "Roundups",
            y:
              types === "piechart"
                ? Number(
                    `${
                      (contributionAmounts?.roundups /
                        (contributionAmounts?.direct +
                          contributionAmounts?.roundups +
                          contributionAmounts?.matches)) *
                      100
                    }`
                  )
                : Number(`${contributionAmounts?.roundups}`),
          },
          {
            name: "Games",
            y:
              types === "piechart"
                ? Number(
                    `${
                      (contributionAmounts?.matches /
                        (contributionAmounts?.direct +
                          contributionAmounts?.roundups +
                          contributionAmounts?.matches)) *
                      100
                    }`
                  )
                : Number(`${contributionAmounts?.matches}`),
          },
        ],
      },
    ],
  };
};

export const PieChartContributionOptions = (contributionAmounts) => {
  return {
    chart: {
      type: "pie",
    },
    title: {
      text: "Contributions By Fans/Merchants",
    },
    subtitle: {
      text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>',
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Total percent market share",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPadding: 0.4,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}%",
        },
      },
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    },

    series: [
      {
        name: "Browsers",
        colorByPoint: true,
        data: [
          {
            name: "Direct",
            y: 20000,
          },
          {
            name: "Roundups",
            y: 15000,
          },
          {
            name: "Matches",
            y: 13000,
          },
        ],
      },
    ],
  };
};
export const EventBasedAthleteContributionOptions = (eventData) => {
  let eventMonthlyContributionArray = [];
  eventData?.map((item) =>
    eventMonthlyContributionArray.push({
      monthData: `${item.month} ${item.year}`,
      value: item.roundUp,
    })
  );

  return {
    chart: {
      type: "spline",
    },
    title: {
      text: "Monthly Settled Amount To Athletes",
    },
    // subtitle: {
    //     text: 'Source: ' +
    //         '<a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" ' +
    //         'target="_blank">Wikipedia.com</a>'
    // },
    xAxis: {
      // categories: [
      //   "Jan",
      //   "Feb",
      //   "Mar",
      //   "Apr",
      //   "May",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      // ],
      categories: eventMonthlyContributionArray.map((item) => item.monthData),
      accessibility: {
        description: "Months of the year",
      },
    },
    yAxis: {
      title: {
        text: "Amount",
      },
      labels: {
        format: "{value}",
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
    },
    plotOptions: {
      spline: {
        marker: {
          radius: 4,
          lineColor: "#666666",
          lineWidth: 1,
        },
      },
    },
    series: [
      {
        name: "contribution",
        marker: {
          symbol: "circle",
        },
        // data: [5.2, 5.7, 8.7, 13.9, 18.2, 21.4, 25.0, {
        //     y: 26.4,
        //     marker: {
        //         symbol: 'url(https://www.highcharts.com/samples/graphics/sun.png)'
        //     },
        //     accessibility: {
        //         description: 'Sunny symbol, this is the warmest point in the chart.'
        //     }
        // }, 22.8, 17.5, 12.1, 7.6]
        data: eventMonthlyContributionArray.map((item) => item.value),
      },
      // {
      //     name: 'Bergen',
      //     marker: {
      //         symbol: 'diamond'
      //     },
      //     data: [{
      //         y: 1.5,
      //         marker: {
      //             symbol: 'url(https://www.highcharts.com/samples/graphics/snow.png)'
      //         },
      //         accessibility: {
      //             description: 'Snowy symbol, this is the coldest point in the chart.'
      //         }
      //     }, 1.6, 3.3, 5.9, 10.5, 13.5, 14.5, 14.4, 11.5, 8.7, 4.7, 2.6]
      // }
    ],
  };
};

export const EventsBasedEventStatusOptions = (status) => {
  return {
    chart: {
      type: "column",
    },
    title: {
      // text: "Contributions By Fans/Merchants",
      text: "Event Status",
    },
    // subtitle: {
    //   text: 'Click the columns to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>',
    // },
    xAxis: {
      type: "category",
    },
    yAxis: {
      title: {
        text: "Events Status Count",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        pointPadding: 0.2,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          // format: "{point.y:.1f}",
          formatter: function () {
            return parseInt(this.y);
          },
        },
      },
    },

    // tooltip: {
    //   headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //   pointFormat:
    //     '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
    // },
    tooltip: {
      enabled: false,
    },

    series: [
      {
        name: "Event Status",
        colorByPoint: true,
        data: [
          {
            name: "Not Published",
            y: Number(`${status?.notPublished}`),
          },
          {
            name: "Published",
            y: Number(`${status?.published}`),
          },
          {
            name: "Accepted",
            y: Number(`${status?.accepted}`),
          },
          {
            name: "Rejected",
            y: Number(`${status?.rejected}`),
          },
          {
            name: "Proof Submitted",
            y: Number(`${status?.proofSubmitted}`),
          },
          {
            name: "Proof Accepted",
            y: Number(`${status?.proofAccepted}`),
          },
          {
            name: "Proof Rejected",
            y: Number(`${status?.proofRejected}`),
          },
          {
            name: "Not Responded",
            y: Number(`${status?.notResponded}`),
          },
          {
            name: "Cancelled",
            y: Number(`${status?.cancelled}`),
          },
        ],
      },
    ],
  };
};
