import { Link, useNavigate, useParams } from "react-router-dom";
import UserInfo from "./UserInfo";
import React from "react";
import Bradcrum from "./Bradcrum";

function Header() {
  const params = useParams();
  const navigate = useNavigate();
  // Extract the value from the object
  const stringValue = Object.values(params)[0].split("/");
  console.log("stringValue+++++",stringValue);
  const id = stringValue[stringValue.length - 1];
  //console.log(stringValue, "sdchjvsdvb");

  const navUrl = (data) => {
    console.log(data, "sdchgvsdjbvs");
    if (data === "universities") {
      navigate(`/dashboard/universities`);
    } else if (data === "team") {
      navigate(`/dashboard/universities/teams/${Number(id)}`);
    };
  }

  const navUrlForAthlete = (data) => {
    console.log(data, "sdchgvsdjbvs");
    if (data === "athlete") {
      navigate(`/dashboard/athlete`);
    }
  };

  const navUrlForFan = (data) => {
    if (data === "fan") {
      navigate("/dashboard/fan");
    }
  };
  const navUrlForMerchant = (data) => {
    if (data === "merchant") {
      navigate(`/dashboard/merchant`);
    }
  };
  const navUrlForMatch = (data) => {
    if (data === "match") {
      navigate(`/dashboard/match`);
    }
  };
  
  const navUrlForRole = (data) => {
    if (data === "role") {
      navigate(`/dashboard/role`);
    }
  };

 const navUrlForStaff = (data) => {
    if (data === "staff") {
      navigate(`/dashboard/staff`);
    }
  };

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box text-start">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/assets/svg/Logo.svg" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/assets/svg/Logo.svg" alt="" height="17" />
              </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src="/assets/svg/Logo.svg" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/assets/svg/Logo.svg" alt="Wallt" />
              </span>
            </Link>
          </div>
          {stringValue?.[0] === "universities" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrl}
              matchedValue={"universities"}
            />
          )}

          {stringValue?.[0] === "athlete" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrlForAthlete}
              matchedValue={"athlete"}
            />
          )}

          {stringValue?.[0] === "fan" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrlForFan}
              matchedValue={"fan"}
            />
          )}

          {stringValue?.[0] === "merchant" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrlForMerchant}
              matchedValue={"merchant"}
            />
          )}
           {stringValue?.[0] === "match" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrlForMatch}
              matchedValue={"match"}
            />
          )}
           {stringValue?.[0] === "role" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrlForRole}
              matchedValue={"role"}
            />
          )}
          {stringValue?.[0] === "staff" && (
            <Bradcrum
              stringValue={stringValue}
              navUrl={navUrlForStaff}
              matchedValue={"staff"}
            />
          )}
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon "
              id="page-header-notifications-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <i className="bx bx-bell "></i> */}
              {/*<span className="badge bg-danger rounded-pill">3</span>*/}
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications-dropdown"
            >
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0" key="t-notifications">
                      {" "}
                      Notifications{" "}
                    </h6>
                  </div>
                  <div className="col-auto">
                    <a href="#!" className="small" key="t-view-all">
                      {" "}
                      View All
                    </a>
                  </div>
                </div>
              </div>
              <div data-simplebar style={{ maxHeight: "230px" }}>
                <a
                  href="javascript: void(0);"
                  className="text-reset notification-item"
                >
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1" key="t-your-order">
                        Your order is placed
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1" key="t-grammer">
                          If several languages coalesce the grammar
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i>{" "}
                          <span key="t-min-ago">3 min ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="p-2 border-top d-grid">
                <a
                  className="btn btn-sm btn-link font-size-14 text-center"
                  href="javascript:void(0)"
                >
                  <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                  <span key="t-view-more">View More..</span>
                </a>
              </div>
            </div>
          </div>

          <UserInfo />

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon right-bar-toggle "
            >
              {/* <i className="bx bx-cog"></i> */}
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
