import axios from "axios";
export const USER_LISTING = "USER_LISTING";

export const getRolesList = (params) => {
  return axios({
    method: "GET",
    params: params,
    url: "/roles/list",
  });
};

export const addRole = (params) => {
  let data = { ...params };
  let url = "/role";
  if (data?.roleId) {
    url = "/role/" + data?.roleId;
  }
  delete params.roleId;
  return axios({
    method: data?.roleId ? "PATCH" : "POST",
    data: params,
    url: url,
  });
};


export const getById = (id) => {
  // console.log(id, "ssjjjs");
  return axios({
    method: "GET",
    url: `/role/${id}`,
  });
};

export const deleteRole = (id) => {
  return axios({
    method: "DELETE",
    url: `/role/${id}`,
  });
};

export const getPermissionList = (params) => {
  return axios({
    method: "GET",
    data: params,
    url: "/permissions",
  });
};
