import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { limit, roundUpStatus } from "../../constants";

import { getSuportsListing } from "../../actions/Suports";
import SuportsAddEditModal from "../../components/Modal/SuportsAddEditModal";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import PageHeading from "../../components/common/PageHeading";
import MerchentAddEditModal from "../../components/Modal/MerchentAddEditModal";
import { getAthleteListing } from "../../actions/athlete";
import MerchentSlide from "./MerchentSlide";
import MerchentSkelton from "./MerchentSkeleton";
import MerchantViewModal from "../../components/Modal/MerchentViewModal";
import ReactSelectField from "../../components/FormFields/ReactSelectField";

function ManageMerchent() {
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const { control, setValue, handleSubmit } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  let search = searchParams.get("searchText");
  const [showMerchant, setShowMerchant] = useState(false);
  const navigate = useNavigate();

  const [id, setId] = useState();

  const initalFetchData = useRef(true);
  // console.log(activePage, "dsvhbsd");

  const modalProperty = (id) => {
    setId(id);
    setShowMerchant(true);
  };

  const fetchData = async (page, filters) => {
    try {
      const params = {
        page,
        perPage: 20,
        role: "merchant",
        ...filters,
      };
      const resp = await getAthleteListing(params);
      setList(resp?.data?.responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };

  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    if (data.roundupStatus === false || data.roundupStatus === true) {
      filters.roundupStatus = data.roundupStatus;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
      if (filtersData.roundupStatus) {
        queryParams.push(`roundupStatus=${filtersData?.roundupStatus}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
    setValue("roundupStatus", "");
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const modalCloseHandler = () => {
    setShowMerchant(false);
    navigate("/dashboard/merchant");
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading
                heading={"MERCHANTS"}
                count={list?.totalRecords || 0}
              />
              <div className="flex-shrink-0">
                <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_MERCHANT")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by Store name, email, manager and phone number..")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>
            <div className="game-select-container ms-3">
              <ReactSelectField
                control={control}
                name="roundupStatus"
                label={_("ROLE")}
                placeholder={_("Select Status")}
                options={roundUpStatus}
                optionValue="value"
                optionLabel="label"
                onChange={(selectedValue) =>
                  // setContribution((prevState) => ({
                  //   prevState,
                  //   contributionTo: selectedValue,
                  // }))
                  console.log("selectedValue", selectedValue)
                }
              />
            </div>
            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover table-responsive">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("STORE_NAME")}</th>
                        <th scope="col">{_("EMAIL")}</th>
                        <th scope="col">{_("MANAGER_NAME")}</th>
                        <th scope="col">{_("PHONE_NO")}</th>
                        <th scope="col">{_("ROUND_STATUS")}</th>
                        <th scope="col">{_("ROUND_UP_BALANCE")}</th>
                        <th scope="col">{_("REGISTRATION_DATE")}</th>
                        <th scope="col">{_("STATUS")}</th>
                        <th scope="col">{_("ACTIONS")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ?(
                        <MerchentSkelton count={8} />
                      ) : list && list?.data && list?.data?.length > 0 ? (
                        list &&
                        list?.data &&
                        list?.data?.length > 0 &&
                        list?.data?.map((obj, index) => (
                          <MerchentSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            modalProperty={modalProperty}
                            activePage = {activePage}
                            filtersData = {filtersData}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {list && list?.totalRecords > 20 && (
                <ResponsivePagination
                  current={activePage}
                  total={list?.totalPages ?? 1}
                  onPageChange={handlePageChange}
                  maxWidth={20}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <MerchentAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          setActivePage={setActivePage}
        />
      )}
      {showMerchant && (
        <MerchantViewModal
          show={showMerchant}
          onHide={modalCloseHandler}
          id={id}
        />
      )}
     
    </div>
  );
}
export default ManageMerchent;
