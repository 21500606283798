import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SuccessModal = ({ importModel, setImportModel, fetchData }) => {
    const handleCloseSuccessModel = ()=>{
        setImportModel(prevState=>({...prevState, showSuccessModel:false}))
        fetchData(1)
    }
  return (
    <Modal 
    show={importModel?.showSuccessModel} 
    onHide={handleCloseSuccessModel} 
    aria-labelledby="contained-modal-title-vcenter"
    className="max-with-580 modal-dialog-margin"
    centered
    >
      <Modal.Body className="text-center">
        {/* <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', fontSize: '50px' }} /> */}
        <i class="fa fa-check" aria-hidden="true" style={{ color: 'green', fontSize: '50px' }}></i>
        <p className="mt-2 h5">File Uploaded Successfully</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseSuccessModel}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuccessModal;
