import axios from "axios";
export const USER_LISTING = "USER_LISTING";

export const getStaffList = (params) => {
  return axios({
    method: "GET",
    params: params,
    url: "/admin/staff",
  });
};

export const addNewStaff = (params) => {
  let data = { ...params };
  let url = "/admin/staff";
  console.log(data?.id, "sss");
  if (data?.id) {
    url = "/admin/staff/" + data?.id;
  }
  delete params?.id;
  return axios({
    method: data?.id ? "PATCH" : "POST",
    data: params,
    url: url,
  });
};

export const getStaffById = (id) => {
  return axios({
    method: "GET",
    url: `/user/${id}`,
  });
};

export const deleteStaffById = (id) => {
  return axios({
    method: "DELETE",
    url: `/admin/staff/${id}`,
  });
};

export const resetPassword = (data) =>{
  return axios({
    method: "POST",
    data : data,
    url:"/admin/password-reset",
  })
} 