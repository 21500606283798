import React from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { CONTRIBUTION_TYPE } from "../../constants";
import { _ } from "../../locale";

const RoundUpModal = ({ show, onHide, id, onClick, imgSrc, viewTransDetail, contributionMode }) => {

  return (
    <>
    { CONTRIBUTION_TYPE[`${contributionMode}`] === (_('ROUND_UP')) ?(
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="max-with-580 modal-dialog-margin"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <h3>Contribution made to</h3>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <h5 className="mb-3">Athletes</h5>
          <div className="d-flex gap-3 mb-4">
            {viewTransDetail?.length && viewTransDetail.map((item, index) => {
             return  item?.athlete ? (
                <div
                  key={index}
                  className="card_athlete d-flex flex-wrap pt-2 justify-content-center"
                  typeof="btn"
                >
                  <div onClick={onClick}>
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <img
                        src={item?.athlete?.userProfile?.profileImage ? item?.athlete?.userProfile?.profileImage?.filePath : "/assets/images/user-img.jpeg"}
                        alt={item?.athlete?.userProfile?.profileImage ? item?.athlete?.userProfile?.profileImage?.fileName :""}
                        width={"48px"}
                        height={"48px"}
                        className="rounded-circle"
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-center select-card-text">{item?.athlete?.userProfile?.name}</h5>
                    </div>
                  </div>
                </div>
              ):null})
            }
          </div>
          {viewTransDetail?.length && viewTransDetail.map((item, index) => {
            return item?.team ? (
          <Accordion>
            <Accordion.Item eventKey="0" className="border-0">
              <Accordion.Header className="border-bottom">{item?.team?.name}</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-wrap gap-3 mb-4">
                  {item?.childContributions?.length && item?.childContributions?.map((childContri_data, index) => {
                    console.log("childContri_data", childContri_data)
                    return (
                      <div
                        key={index}
                        className="card_athlete d-flex flex-wrap pt-2 justify-content-center"
                        typeof="btn"
                      >
                        <div onClick={onClick}>
                          <div className="d-flex align-items-center justify-content-center mb-2">
                            <img
                              src={
                                childContri_data?.athlete?.userProfile?.profileImage ?  childContri_data?.athlete?.userProfile?.profileImage?.filePath : "/assets/images/user-img.jpeg"
                              }
                              alt={childContri_data?.athlete?.userProfile?.profileImage ?  childContri_data?.athlete?.userProfile?.profileImage?.fileName : ""}
                              width={"48px"}
                              height={"48px"}
                              className="rounded-circle"
                            />
                          </div>
                          <div>
                            <h5 className="mb-0 text-center select-card-text">{childContri_data?.athlete?.userProfile?.name}</h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1" className="border-bottom border-0">
              <Accordion.Header>Team B</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex gap-3">
                  {[1, 2, 3, 4].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="card_athlete d-flex flex-wrap align-items-center justify-content-center"
                        typeof="btn"
                      >
                        <div onClick={onClick}>
                          <div className="d-flex align-items-center justify-content-center">
                            <img
                              src={
                                imgSrc ? imgSrc : "/assets/images/user-img.jpeg"
                              }
                              alt=""
                              width={"48px"}
                              height={"48px"}
                              className="rounded-circle"
                            />
                          </div>
                          <div>
                            <h5 className="mb-0 text-center">Robin</h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
            ):null})}
        </Modal.Body>
      </Modal>
    ):(
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="max-with-580 modal-dialog-margin"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <h3>Contribution made to</h3>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          {/* <h5 className="mb-3">Athletes</h5> */}
          <div className="d-flex gap-3 mb-4">
            {viewTransDetail?.length && viewTransDetail.map((item, index) => {
             return(
              <>
                 {/* showing all athletes */}
                {item?.athlete ? (
                  <div className="d-flex flex-column" >
                  <h5 className="mb-3">Athletes</h5>
                <div
                  key={index}
                  className="card_athlete d-flex flex-wrap pt-2 justify-content-center"
                  typeof="btn"
                >
                  <div onClick={onClick}>
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <img
                        src={item?.athlete?.userProfile?.profileImage ? item?.athlete?.userProfile?.profileImage?.filePath : "/assets/images/user-img.jpeg"}
                        alt={item?.athlete?.userProfile?.profileImage ? item?.athlete?.userProfile?.profileImage?.fileName :""}
                        width={"48px"}
                        height={"48px"}
                        className="rounded-circle"
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-center select-card-text">{item?.athlete?.userProfile?.name}</h5>
                    </div>
                  </div>
                </div>
                </div>
              ):null}
                  
                {/* showing all athletes within a team*/}

                {item?.team ? (
                  <div className="d-flex flex-column" >
                  <h5>{`Team - ${item?.team?.name}`}</h5>
                  <div className="d-flex flex-wrap gap-3" >
                {item?.childContributions?.length && item?.childContributions?.map((childContri_data, index) => {
               return  <div
                  key={index}
                  className="card_athlete pt-2 justify-content-center"
                  typeof="btn"
                >
                  <div onClick={onClick}>
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <img
                        src={childContri_data?.athlete?.userProfile?.profileImage ? childContri_data?.athlete?.userProfile?.profileImage?.filePath : "/assets/images/user-img.jpeg"}
                        alt={childContri_data?.athlete?.userProfile?.profileImage ? childContri_data?.athlete?.userProfile?.profileImage?.fileName :""}
                        width={"48px"}
                        height={"48px"}
                        className="rounded-circle"
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-center select-card-text">{childContri_data?.athlete?.userProfile?.name}</h5>
                    </div>
                  </div>
                </div>
                })}
                </div>
                </div>
                ):null}

                  {/* showing all athletes within a team in a match */}

                {item?.match ? (
                  <div className="d-flex flex-column gap-2">
                  <h2>{`Match name - ${item?.match?.title}`}</h2>
                  <h5>{`Team name - ${item?.matchTeam?.name}`}</h5>
                  <div className="d-flex flex-wrap gap-3">
                 {item?.childContributions?.length && item?.childContributions?.map((childContri_data, index) => {
               return  <div
                  key={index}
                  className="card_athlete d-flex flex-wrap pt-2 justify-content-center"
                  typeof="btn"
                >
                  <div onClick={onClick}>
                    <div className="d-flex align-items-center justify-content-center mb-2">
                      <img
                        src={childContri_data?.athlete?.userProfile?.profileImage ? childContri_data?.athlete?.userProfile?.profileImage?.filePath : "/assets/images/user-img.jpeg"}
                        alt={childContri_data?.athlete?.userProfile?.profileImage ? childContri_data?.athlete?.userProfile?.profileImage?.fileName :""}
                        width={"48px"}
                        height={"48px"}
                        className="rounded-circle"
                      />
                    </div>
                    <div>
                      <h5 className="mb-0 text-center select-card-text">{childContri_data?.athlete?.userProfile?.name}</h5>
                    </div>
                  </div>
                </div>
                })}
                </div>
                </div>
            ):null}
              
              </>)
            })}
          </div>
        </Modal.Body>
      </Modal>
    )}
    </>
  );
};

export default RoundUpModal;
