import { useState } from "react";
import { Controller } from "react-hook-form";
import { formatPhoneNumber } from "../../constants";
function NewPhoneInputField({control, defaultValue, value, rules, disabled = false, labelText, inputType, name, placeholder, formInWidth, iconPos, icon,maxLength,minLength}){
    console.log(value,defaultValue,"hi")
    return(
        <>
            <Controller
                control={control}
                rules={rules} 
                defaultValue={defaultValue !== undefined ? defaultValue : ""}
                name={name}
                render={({ field, fieldState: { error } }) => {
                    const formattedPhoneNumber = formatPhoneNumber(field.value);
                    return (
                        <div className={`form-in ${formInWidth ? formInWidth : ''} ${error ? "f-error" : ""}` }>
                            {
                            labelText &&
                                <label className="form-label">{labelText}</label>
                            }
                            <div className={`f-in w-100  ${iconPos ? `${iconPos}-icon` : ""}`}>
                                <input 
                                    {...field}
                                    type={inputType} 
                                    placeholder={placeholder} 
                                    className="form-control" 
                                    name={name}
                                    value={formattedPhoneNumber || value}
                                    maxLength={maxLength}
                                    disabled={disabled}
                                />
                            
                                { icon ? <span className={`icon ${icon}-icon`}></span>
                                : null }
                                

                            </div>
                            {error ? (
                            <p className="error-msg d-flex align-items-center">
                            <span className="icons info-icon"></span> {error?.message}
                            </p>
                            ) : null}
                        </div>
                    );
                }}
            />
        </>
    )
}
export default NewPhoneInputField;
