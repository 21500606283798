import {locale} from './translations.js';
import { CONFIG } from './constants';
import { useSelector } from "react-redux";

/**
 * Translation
 *
 * @param      {<type>}  text    The text
 * @return     {<type>}  { description_of_the_return_value }
 */
export function _(text, params) {
	if(typeof localStorage == 'undefined')
		return text;
	let lang = localStorage.getItem('language');

	const language = lang ? lang : 'en';
	params = typeof params !== 'undefined' && params !== null ? params : [];

	// console.log(lang, '%%%%%%%%%', text);

	if(typeof locale[language] !== 'undefined' && typeof locale[language][text] !== 'undefined'){
		text = locale[language][text];
	}
  
 	if(text.match(/\%s/)){
		params.map(p => {
			text = text.replace(/\%s/, p);
		});
	}

	// console.log(lang, '^^^^^^^^', text);
	
	return text;
}