import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import ImageUploaderField from "../FormFields/ImageUploderFiled";
import AutoCompleteField from "../../actions/AutoComplete";
import { getUniversityForDropdown } from "../../actions/University";
import ReactDatePicker from "../FormFields/ReactDatePicker";
import ReactSelectField from "../FormFields/ReactSelectField";
import { getSuportsListing } from "../../actions/Suports";
import { getAthleteListing } from "../../actions/athlete";
import InputTextAreaField from "../FormFields/InputTextAreaField";
import moment from "moment";
import { createMatch, getMatchById, updateMatch } from "../../actions/match";
import setMinutes from "date-fns/setMinutes";
import MultiSelectCheckboxField from "../FormFields/MultiSelectCheckboxField";
import EventMultiSelectCheckbox from "../FormFields/EventMultiSelectCheckbox";
function AddEditMatches({
  fetchData,
  show,
  onHide,
  id,
  setActivePage,
  activePage,
  filtersData,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { isSubmitting },
  } = useForm({});
  const [defaultValue, setDefaultValue] = useState();
  const [defaultLatLng, setDefaultLatLng] = useState();
  const [sportsListing, setSportsListing] = useState();
  const kGoogleKey = "AIzaSyBj2sDQqTe7MWv6F71KfmfUsh3LyaXhxdg";
  const [universityListing, setUniversityListing] = useState([]);
  const [athleteListTeemA, setAthleteListTeemA] = useState(null);
  const [athleteListTeemB, setAthleteListTeemB] = useState(null);
  const [team1Id, setTeam1Id] = useState(null);
  const [team2Id, setTeam2Id] = useState(null);
  const [athlete1, setAthlete1] = useState();
  const [athlete2, setAthlete2] = useState();
  const [athleteId1, setAthleteId1] = useState();
  const [athleteId2, setAthleteId2] = useState();
  const [university1Selectore, setUniversity1Selector] = useState(null);
  const [university2Selectore, setUniversity2Selector] = useState(null);
  const [sportsSelector, setSportsSelector] = useState(null);
  const [dateSelect, setDateSelect] = useState();
  const [sportId, setSportId] = useState(null);
  const [university2Id, setUniversity2Id] = useState(null);
  const [university1Id, setUniversity1Id] = useState(null);
  const [selectedAthleteA, setSelectedAthleteA] = useState([]);
  const [selectedAthleteB, setSelectedAthleteB] = useState([]);
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const [startTime, setStartTime] = useState();
  const [selectedIds, setSelectedIds] = useState({
    teamASelectedIds: 0,
    teamBSelectedIds: 0,
  });
  const [errorMsg, setErrorMsg] = useState("");

  const fetchSportsData = async (page, filters) => {
    // setShowLoader(true);
    try {
      const params = {
        page,
        perPage: 200,
        ...filters,
      };
      const resp = await getSuportsListing(params);
      const sportsList = resp?.data?.responseData?.data?.map((sport) => ({
        label: sport.name,
        value: sport.id,
      }));
      setSportsListing(sportsList);
      //   setShowLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      //   setShowLoader(false);
    }
  };

  const onSubmit = async (formData) => {
    console.log("formdata+++++++", formData);
    const payload = {
      ...formData,
      attachmentId: formData?.matchImageId?.id,
      mapAddress: formData?.location?.label,
      latitude: defaultLatLng?.lat
        ? defaultLatLng?.lat?.toString()
        : formData?.location?.latitude?.toString(),
      longitude: defaultLatLng?.lng
        ? defaultLatLng?.lng?.toString()
        : formData?.location?.longitude?.toString(),
      startDate: moment(formData?.startDate).toISOString(),
      endDate: moment(formData?.endDate).toISOString(),
      teams: [
        {
          name: formData?.name1,
          universityId: formData?.universityId1,
          attachmentId: formData?.teamImageId1?.id,
          athletes: formData?.teamA?.length > 0 ? formData?.teamA : athlete1,
        },
        {
          name: formData?.name2,
          universityId: formData?.universityId2,
          attachmentId: formData?.teamImageId2?.id,
          athletes: formData?.teamB?.length > 0 ? formData?.teamB : athlete2,
        },
      ],
    };
    console.log(payload, "dcjbhds");
    if (team1Id) {
      payload.teams[0]["id"] = team1Id;
    }
    if (team2Id) {
      payload.teams[1]["id"] = team2Id;
    }

    delete payload?.location;
    delete payload?.imageId;
    delete payload?.countryCode;
    delete payload?.mobile;
    delete payload?.matchImageId;
    delete payload?.athletes1;
    delete payload?.athletes2;
    delete payload?.name1;
    delete payload?.name2;
    delete payload?.teamImageId1;
    delete payload?.teamImageId2;
    delete payload?.universityId1;
    delete payload?.universityId2;
    delete payload?.teamA;
    delete payload?.teamB;
    delete payload?.athletes;

    try {
      let resp;
      if (id) {
        resp = await updateMatch(id, payload);
      } else {
        resp = await createMatch(payload);
      }

      if (resp?.status === 200) {
        // Fetch data and hide the form
        onHide();
        fetchData(activePage, filtersData);
        // setActivePage(1)
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
        // setErrorMsg(request)
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const fetchUniversityData = async (page, type) => {
    try {
      // const resp = await getUniversityForDropdown({ required: true, sportId: sportsSelector?.value });
      const resp = await getUniversityForDropdown({
        required: true,
        sportId: sportsSelector?.value ? sportsSelector?.value : sportId,
      });
      const newArray = resp?.data?.responseData?.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setUniversityListing(newArray);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      // setShowLoader(false);
    }
  };

  const fetchAthleteData = async (page, uniId, sportId, id1, id2) => {
    try {
      const params = {
        page,
        perPage: 20,
        isRegisteredUser: 1,
        sportId: id2 ? id2 : sportId?.value,
        universityId: id1 ? id1 : uniId?.value,
        role: "athlete",
      };
      const resp = await getAthleteListing(params);
      setSelectedAthleteA([]);
      const newAthleteList = resp?.data?.responseData?.data?.map((elm) => {
        const flag = selectedAthleteB
          ? selectedAthleteB.includes(elm?.id)
          : false;

        return {
          profileImage: elm?.userProfile?.profileImage?.filePath,
          name: elm?.userProfile?.name,
          id: elm?.id,
          flag: flag,
        };
      });

      setAthleteListTeemA([...newAthleteList]);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const fetchAthleteData2 = async (page, uniId, sportId, id1, id2) => {
    try {
      const params = {
        page,
        perPage: 20,
        isRegisteredUser: 1,
        sportId: id2 ? id2 : sportId?.value,
        universityId: id1 ? id1 : uniId?.value,
        role: "athlete",
      };
      const resp = await getAthleteListing(params);
      setSelectedAthleteB([]);
      const newAthleteList = resp?.data?.responseData?.data?.map((elm) => {
        const flag = selectedAthleteA
          ? selectedAthleteA.includes(elm?.id)
          : false;

        return {
          profileImage: elm?.userProfile?.profileImage?.filePath,
          name: elm?.userProfile?.name,
          id: elm?.id,
          flag: flag,
        };
      });

      setAthleteListTeemB([...newAthleteList]);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const fetchSingalData = async (id) => {
    try {
      const resp = await getMatchById(id);
      const data = resp?.data?.responseData;
      setValue("name", data?.name);
      const locationDefaultValue = {
        lat: data?.latitude,
        lng: data?.longitude,
      };
      let roleList1 = [];
      let roleList2 = [];
      if (data?.teams?.[0]?.athletes?.length) {
        data?.teams?.[0]?.athletes &&
          data?.teams?.[0]?.athletes?.map((ork, index) => {
            roleList1.push(ork.userId);
          });
      }
      if (data?.teams?.[1]?.athletes?.length) {
        data?.teams?.[1]?.athletes &&
          data?.teams?.[1]?.athletes?.map((ork, index) => {
            roleList2.push(ork.userId);
          });
      }

      setAthlete1(roleList1);
      setAthlete2(roleList2);
      setAthleteId1(roleList1);
      setAthleteId2(roleList2);
      setTeam1Id(data?.teams?.[0]?.id);
      setTeam2Id(data?.teams?.[1]?.id);
      setDefaultLatLng(locationDefaultValue);
      setDefaultValue(data?.mapAddress);
      setUniversity1Id(data?.teams[0]?.universityId);
      setUniversity2Id(data?.teams[1]?.universityId);
      setSportId(data?.sportsId);
      reset({
        title: data?.title,
        startDate: data?.startDate ? new Date(data?.startDate) : "-",
        endDate: data?.endDate ? new Date(data?.endDate) : "-",
        sportsId: data?.sportsId,
        description: data?.description,
        name1: data?.teams?.[0]?.name,
        teamImageId1: data?.teams?.[0]?.attachment,
        universityId1: data?.teams[0]?.universityId,
        name2: data?.teams?.[1]?.name,
        teamImageId2: data?.teams?.[1]?.attachment,
        universityId2: data?.teams[1]?.universityId,
      });
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  let isToday = moment(startTime).isSame(moment(), "day");

  const calculateMinTime = (date, isStartTime) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      return isStartTime
        ? moment().toDate()
        : setMinutes(moment().toDate(), 10);
    }
    return moment().startOf("day").toDate();
  };

  const handleChange = (value) => {
    setStartTime(value);
    const minTime = isToday
      ? calculateMinTime(value, true)
      : moment().startOf("day").toDate();
  };

  useEffect(() => {
    if (id) {
      fetchSingalData(id);
    }
  }, [id, defaultValue]);

  useEffect(() => {
    fetchSportsData();
  }, []);

  useEffect(() => {
    if (sportsSelector || sportId) {
      fetchUniversityData();

      setValue("universityId1", "");
      setValue("universityId2", "");
      setValue("teamA", "");
      setValue("teamB", "");
      setAthleteListTeemA(null);
      setAthleteListTeemB(null);
      setAthleteId1(null);
      setUniversity1Id(null);
    }
  }, [sportsSelector, sportId]);

  useEffect(() => {
    if (
      (university1Selectore && sportsSelector) ||
      (sportId && university1Id)
    ) {
      fetchAthleteData(
        1,
        university1Selectore,
        sportsSelector,
        university1Id,
        sportId
      );
    } else {
      if (!university1Selectore || !university1Id) {
        setAthleteListTeemA(null);
      }
    }
  }, [university1Selectore, university1Id]);

  useEffect(() => {
    if (
      (university2Selectore && sportsSelector) ||
      (sportId && university2Id)
    ) {
      fetchAthleteData2(
        1,
        university2Selectore,
        sportsSelector,
        university2Id,
        sportId
      );
    }
  }, [university2Selectore, university2Id]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-750 addLeadModal  
                modal-match"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {id !== null && id !== undefined ? _("EDIT_MATCH") : _("ADD_MATCH")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">{_("TITLE") + "*"}</label>
                      <Inputfield
                        control={control}
                        name={"title"}
                        placeholder={_("E.g. T20..")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("Enter title"),
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="new-class">
                    <div className="mb-3">
                      <label className="form-label">
                        {_("GAME_LOCATION") + "*"}
                      </label>
                      {defaultValue && id && (
                        <AutoCompleteField
                          control={control}
                          name="location"
                          placeholder="Enter location"
                          apiKey={kGoogleKey}
                          defaultValue={defaultValue}
                        />
                      )}

                      {defaultValue === undefined && id === undefined && (
                        <AutoCompleteField
                          control={control}
                          name="location"
                          placeholder="Enter location"
                          apiKey={kGoogleKey}
                        />
                      )}
                    </div>
                  </div>

                  {/* <div className="d-flex w-100 justify-content-between gap-3">
                                        <div className="mb-3 w-50">
                                            <ReactDatePicker
                                                control={control}
                                                name="startDate"
                                                label="Start Date & Time"
                                                inputClassName={"form-control"}
                                                errorClass="Your Error Class"
                                                dateFormat="MM/DD/YYYY"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Enter start date & time",
                                                    },
                                                }}
                                                minDate={Date.now(dateSelect)}
                                                onChangeDate={(selectedDate) => {
                                                    setSelectedDate(Date.now(selectedDate))

                                                }}
                                            />
                                        </div>
                                        <div className="mb-3 w-50">
                                            <ReactDatePicker
                                                control={control}
                                                name="endDate"
                                                label="End Date & Time"
                                                inputClassName={"form-control"}
                                                errorClass="Your Error Class"
                                                dateFormat="MM/DD/YYYY"
                                                minDate={Date.now(selectedDate)}
                                                excludeDates={[selectedDate]}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Enter end date & time",
                                                    },
                                                }}
                                                onChangeDate={(selectedDate) => {
                                                    setDateSelect(Date.now(selectedDate))

                                                }}
                                            />
                                        </div>
                                    </div> */}

                  <div className="d-flex w-100 justify-content-between gap-3">
                    <div className="mb-3 w-50">
                      <ReactDatePicker
                        control={control}
                        name="startDate"
                        label="Start Date & Time*"
                        onChangeDate={(selectedDate) => {
                          const selectedMoment = moment(selectedDate);

                          if (selectedMoment.isSameOrBefore(moment())) {
                            setError("startDate", {
                              type: "manual",
                              message:
                                "Start time should be after current time",
                            });
                          } else {
                            setError("startDate", null);
                            setStartDates(selectedMoment);
                            handleChange(selectedDate);
                          }
                        }}
                        rules={{
                          required: {
                            value: true,
                            message: "Enter start date & time",
                          },
                          validate: (value) => {
                            const selectedMoment = moment(value);
                            if (selectedMoment.isSameOrBefore(moment())) {
                              return "Start time should be after current time";
                            }
                            return true;
                          },
                        }}
                        minDate={new Date()}
                      />
                    </div>
                    <div className="mb-3 w-50">
                      <ReactDatePicker
                        control={control}
                        name="endDate"
                        label="End Date & Time*"
                        onChangeDate={(val) => {
                          setEndDates(moment(val));
                          handleChange(val);
                        }}
                        selectedEndDate={endDates}
                        rules={{
                          required: {
                            value: true,
                            message: "Enter end date & time",
                          },
                          validate: (value) => {
                            // Custom validation rule to check if rules are met
                            const selectedMoment = moment(value);
                            if (
                              selectedMoment.isSameOrBefore(startDates) ||
                              (selectedMoment.isSame(startDates, "day") &&
                                selectedMoment.format("HH:mm") ===
                                  startDates.format("HH:mm"))
                            ) {
                              return "End date & time should be after start date & time";
                            }
                            return true;
                          },
                        }}
                        minDate={startDates ? startDates.toDate() : new Date()}
                        // customError={
                        //     startDates &&
                        //     endDates &&
                        //     (endDates.isSameOrBefore(startDates) ||
                        //         (endDates.isSame(startDates, "day") &&
                        //             endDates.format("HH:mm") === startDates.format("HH:mm"))) &&
                        //     "End date & time should be after start date & time"
                        // }
                      />
                    </div>
                  </div>

                  <div className="">
                    <label className="form-label">{_("SPORT") + "*"}</label>
                    <div className="mb-3">
                      <ReactSelectField
                        control={control}
                        name="sportsId"
                        label={_("ROLE")}
                        placeholder={_("Select sport")}
                        options={sportsListing}
                        optionValue="value"
                        optionLabel="label"
                        onChange={(selectedValue) =>
                          setSportsSelector(selectedValue)
                        }
                        rules={{
                          required: {
                            value: true,
                            message: _("Select sport"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <label className="form-label">
                      {_("DESCRIPTION") + "*"}
                    </label>
                    <div className="mb-3">
                      <InputTextAreaField
                        control={control}
                        name="description"
                        label={_("example 1234")}
                        fieldClass={"form-control"}
                        maxLength={500}
                        rules={{
                          required: {
                            value: true,
                            message: _("Enter description"),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="">
                    <label className="form-label">{_("TEAMS")}</label>
                  </div>

                  <div className="w-100 d-flex justify-content-between gap-3">
                    <div className="w-50">
                      <label className="form-label">{_("TEAM")} 1</label>
                      <div className="university-selector-style">
                        <label className="form-label">
                          {_("UNIVERSITY") + "*"}
                        </label>
                        <div className="mb-3">
                          <ReactSelectField
                            control={control}
                            name="universityId1"
                            label={_("ROLE")}
                            placeholder={_("E.g. Stanford University..")}
                            options={universityListing}
                            optionValue="value" // Make sure this matches the key in your options array
                            optionLabel="label"
                            onChange={(selectedValue) => {
                              setUniversity1Selector(selectedValue);
                              setUniversity1Id(selectedValue?.value);
                              setAthlete1(null);
                              // reset({'teamA': ""})
                            }}
                            rules={{
                              required: {
                                value: true,
                                message: _("ENTER_UNIVERSITY_1"),
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="university-selector-style">
                        <div className="mb-3">
                          <label className="form-label">
                            {_("NAME") + "*"}
                          </label>
                          <Inputfield
                            control={control}
                            name={"name1"}
                            placeholder={_("E.g. Team A..")}
                            normalize={(e) => e.replace(/^\s+/g, "")}
                            type="text"
                            inputClassName={"form-control"}
                            rules={{
                              required: {
                                value: true,
                                message: _("NAME_REQUIRED_1"),
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="mb-3">
                          <ImageUploaderField
                            control={control}
                            label="Image"
                            name="teamImageId1"
                            uploadType="image"
                            setValue={setValue}
                            changeLabel={"image"}
                            uploadText="Upload image"
                            extensionTypes={["jpeg", "jpg", "png"]}
                            rules={{
                              required: {
                                value: true,
                                message: _("UPLOD_IMAGE_1"),
                              },
                            }}
                          />
                        </div>
                      </div>

                      {
                        // (athleteListTeemA?.length > 0 || id)
                        athleteListTeemA?.length > 0 ? (
                      <div className="">
                        <label className="form-label">
                          {_("SELECT_ATHLETE") + "(s)*"}{" "}
                        </label>
                        <label className="ml-3-style">
                          Total ({selectedAthleteA?.length})
                        </label>
                        <div className="mb-3 athlete-select-style">
                          {/* {athleteListTeemA?.length > 0 ? ( */}
                            <EventMultiSelectCheckbox
                              control={control}
                              name={`teamA`}
                              label={"Message type"}
                              placeholder="Message Type"
                              options={athleteListTeemA}
                              optionValue="value"
                              optionLabel="label"
                              selectedAthleteIds={athlete1}
                              onChange={(val) => {
                                setSelectedAthleteA(val);
                              }}
                              selectedAthlete={selectedAthleteB}
                              // selectedAthleteIds={athleteId1}

                              rules={{
                                required: {
                                  value: true,
                                  message: _(
                                    "Please Select atleast 1 athleate"
                                  ),
                                },
                              }}
                              setSelectedAthletes={setSelectedAthleteA}
                              athleteList={athleteListTeemA}
                              setAthleteList={setAthleteListTeemA}
                              universitySelectore={university1Selectore}
                              sportsSelector={sportsSelector}
                              universityId={university1Id}
                              sportId={sportId}
                              section={"addGames"}
                            />
                          {/* ) : (
                            <div
                              className="d-flex  justify-content-center align-items-center"
                              style={{ minHeight: "269px" }}
                            >
                              <h5 className="noUser-found text-truncate">
                                No active athletes in this university now{" "}
                              </h5>
                            </div>
                          )} */}
                        </div>
                      </div>
                        ):(
                          <>
                          {university1Id ? (
                           <div
                           className="d-flex  justify-content-center align-items-center"
                           style={{ minHeight: "269px" }}
                         >
                           <h5 className="noUser-found text-truncate">
                             No active athletes in this university now{" "}
                           </h5>
                         </div>
                          ):(null)}
                          </>
                        )
                        }
                    </div>

                    <div className="w-50">
                      <div className="">
                        <label className="form-label">{_("TEAM")} 2</label>
                        <div className="university-selector-style">
                          <label className="form-label">
                            {_("UNIVERSITY") + "*"}
                          </label>
                          <div className="mb-3">
                            <ReactSelectField
                              control={control}
                              name="universityId2"
                              label={_("ROLE")}
                              placeholder={_("E.g. Stanford university..")}
                              options={universityListing}
                              optionValue="value"
                              optionLabel="label"
                              onChange={(selectedValue) => {
                                setUniversity2Selector(selectedValue);
                                setUniversity2Id(selectedValue?.value);
                                setAthlete2(null);
                              }}
                              rules={{
                                required: {
                                  value: true,
                                  message: _("ENTER_UNIVERSITY_2"),
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="university-selector-style">
                          <label className="form-label">
                            {_("NAME") + "*"}
                          </label>
                          <Inputfield
                            control={control}
                            name={"name2"}
                            placeholder={_("E.g. Team B..")}
                            normalize={(e) => e.replace(/^\s+/g, "")}
                            type="text"
                            inputClassName={"form-control"}
                            rules={{
                              required: {
                                value: true,
                                message: _("NAME_REQUIRED_2"),
                              },
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="mb-3">
                          <ImageUploaderField
                            control={control}
                            label="Image"
                            name="teamImageId2"
                            uploadType="image"
                            setValue={setValue}
                            changeLabel={"image"}
                            uploadText="Upload image"
                            extensionTypes={["jpeg", "jpg", "png"]}
                            rules={{
                              required: {
                                value: true,
                                message: _("UPLOD_IMAGE_2"),
                              },
                            }}
                          />
                        </div>
                      </div>

                      {
                        // (athleteListTeemB?.length > 0 || id)
                        athleteListTeemB?.length > 0 ? (
                      <div className="">
                        <label className="form-label">
                          {_("SELECT_ATHLETE") + "(s)*"}
                        </label>
                        <label className="ml-3-style">
                          Total ({selectedAthleteB?.length || 0})
                        </label>
                        <div className="mb-3 athlete-select-style">
                          {/* {athleteListTeemB?.length > 0 ? ( */}
                            <EventMultiSelectCheckbox
                              control={control}
                              name={`teamB`}
                              label={"Message type"}
                              placeholder="Message Type"
                              options={athleteListTeemB}
                              optionValue="value"
                              optionLabel="label"
                              selectedAthleteIds={athlete2}
                              onChange={(val) => {
                                setSelectedAthleteB(val);
                              }}
                              selectedAthlete={selectedAthleteA}
                              rules={{
                                required: {
                                  value: true,
                                  message: _(
                                    "Please select atleast 1 athleate"
                                  ),
                                },
                              }}
                              setSelectedAthletes={setSelectedAthleteB}
                              athleteList={athleteListTeemB}
                              setAthleteList={setAthleteListTeemB}
                              universitySelectore={university2Selectore}
                              sportsSelector={sportsSelector}
                              universityId={university2Id}
                              sportId={sportId}
                              section={"addGames"}
                            />
                          {/* ) : (
                            <div
                              className="d-flex  justify-content-center align-items-center"
                              style={{ minHeight: "269px" }}
                            >
                              <h5 className="noUser-found text-truncate">
                                No active athletes in this university now{" "}
                              </h5>
                            </div>
                          )} */}
                        </div>
                      </div>
                       ):(
                        <>
                        {university2Id ? (
                         <div
                         className="d-flex  justify-content-center align-items-center"
                         style={{ minHeight: "269px" }}
                       >
                         <h5 className="noUser-found text-truncate">
                           No active athletes in this university now{" "}
                         </h5>
                       </div>
                        ):(null)}
                        </>
                      )
                        }
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting && (
                        <Spinner animation="border spiner-border-style" />
                      )}
                      {id ? (
                        <span>{_("UPDATE")}</span>
                      ) : (
                        <span>{_("ADD_MATCH")}</span>
                      )}
                    </button>
                    <Link
                      onClick={onHide}
                      className="btn btn-secondary waves-effect"
                    >
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddEditMatches;
