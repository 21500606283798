import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Login from "./pages/auth/Login";
import Layout from "./pages/layout/Layout";
import { ProtectedLoginRoute } from "./components/PrivateRoute/PrivateRoute"; 
// import PageNotFound from "./pages/PageNotFound/PageNotFound";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ProtectedLoginRoute element={<Login />} />} />
        <Route path="/login" element={<ProtectedLoginRoute element={<Login />} />} />
        <Route path="/dashboard/*" element={<Layout />} />
        
        
        
      </Routes>
      <Toaster position="top-right" />
    </>
  );
}

export default App;