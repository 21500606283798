import React from 'react';
import { _ } from '../../locale';

function PageHeading({heading,count}) {
    return (
    //     <div className="page-content">
    //   <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              {count ? (
                <h4 className="mb-sm-0 font-size-18">{_(heading)} ({count})</h4>
              ):(
                <h4 className="mb-sm-0 font-size-18">{_(heading)}</h4>
              )}
              
              <div className="flex-shrink-0">
            </div>

            </div>
          </div>
        </div> 

        
    //   </div> 
    // </div>
    );
}

export default PageHeading;