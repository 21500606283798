import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { _ } from "../../locale";
import SvgImage from "./SvgImage";

function LeftNavigation() {
  const [managePanel, setManagePanel] = useState(false);
  const [managePostPanel, setManagePostPanel] = useState(false);
  const [managePagePanel, setManagePagePanel] = useState(false);
  const [manageAccessPanel, setManageAccessPanel] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  console.log(permissions, "ppp");
  const location = useLocation();
  console.log(permissions, "sdjhfsdj");
  const hasFanPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-fans");
  const hasUniversityPermsisson =
    permissions?.includes("admin") ||
    permissions?.includes("manage-university");
  const hasAthletePermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-athlete");
  const hasSportPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-sport");
  const hasEventPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-events");
  const hasTransactionPermsisson =
    permissions?.includes("admin") ||
    permissions?.includes("manage-transaction");
  const hasMerchantPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-merchants");
  const hasGamesPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-games");
  const hasRolePermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-role");
  const hasStaffPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-staff");
  const hasSettingPermsisson =
    permissions?.includes("admin") || permissions?.includes("manage-setting");
  const hasContributionPermsisson =
    permissions?.includes("admin") ||
    permissions?.includes("manage-contribution");
  // handle management tab
  const handleManagement = () => {
    setManagePanel((prev) => !prev);
  };
  const handlePostManagement = () => {
    setManagePostPanel((prev) => !prev);
  };
  const handlePageManagement = () => {
    setManagePagePanel((prev) => !prev);
  };
  const handleAccessManagement = () => {
    setManageAccessPanel((prev) => !prev);
  };

  // handling route activeness
  const isActive = (match, loc, followPath) => {
    if (match) {
      return true;
    } else {
      let path = loc?.pathname?.split("/");
      if (followPath === path?.[2]) {
        return true;
      }
    }
  };

  return (
    <div className="vertical-menu overflow-auto">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu" className="pt-0">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title" key="t-menu">{_('MENU')}</li> */}
            <li className="mt-5">
              <Link
                to="/dashboard"
                className={
                  location?.pathname === "/dashboard"
                    ? " active d-flex align-items-center"
                    : "d-flex align-items-center"
                }
              >
                <SvgImage type={"dashboard"} />
                <span key="t-dashboards" className="navigation-text">
                  {_("Dashboard")}{" "}
                </span>
              </Link>
            </li>
            {hasUniversityPermsisson && (
              <li>
                <Link
                  to="/dashboard/universities"
                  className={
                    location.pathname?.includes("universities")
                      ? "active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                >
                  <SvgImage type={"university"} />
                  <span key="t-dashboards" className="navigation-text">
                    {_("UNIVERSITIES")}{" "}
                  </span>
                </Link>
              </li>
            )}

            {hasAthletePermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname?.includes("athlete")
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/athlete"
                >
                  <SvgImage type={"athlete"} />
                  <span key="t-dashboards" className="navigation-text">
                    {_("ATHLETES")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasSportPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname === "/dashboard/sports"
                      ? "sport active d-flex align-items-center"
                      : "sport d-flex align-items-center"
                  }
                  to="/dashboard/sports"
                >
                  <SvgImage type={"sport"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("SPORTS")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasFanPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname?.includes("fan")
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/fan"
                >
                  <SvgImage type={"fan"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("FANS")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasMerchantPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname?.includes("merchant")
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/merchant"
                >
                  <SvgImage type={"merchant"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("MERCHANT")}{" "}
                  </span>
                </Link>
              </li>
            )}
            <li>
              <Link
                className={
                  location?.pathname?.includes("potential")
                    ? " active d-flex align-items-center"
                    : "d-flex align-items-center"
                }
                to="/dashboard/potential"
              >
                <SvgImage type={"merchant"} />

                <span key="t-dashboards" className="navigation-text">
                  {_("POTENTIAL_MERCHANT")}{" "}
                </span>
              </Link>
            </li>
            {hasGamesPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname?.includes("match")
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/match"
                >
                  <SvgImage type={"games"} />
                  <span key="t-dashboards" className="navigation-text">
                    {_("MATCHES")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasEventPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname?.includes("events")
                      ? "event active d-flex align-items-center"
                      : "d-flex align-items-center event"
                  }
                  to="/dashboard/events"
                >
                  <SvgImage type={"event"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("EVENTS")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasTransactionPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname === "/dashboard/transaction"
                      ? "transaction active d-flex align-items-center"
                      : "d-flex align-items-center transaction"
                  }
                  to="/dashboard/transaction"
                >
                  <SvgImage type={"transaction"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("TRANSACTION")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasContributionPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname === "/dashboard/contribution"
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/contribution"
                >
                  <SvgImage type={"contribution"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("NILDEALS")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasRolePermsisson && (
              <li>
                {/* {console.log(isActive, "ssss")} */}
                <Link
                  className={
                    location?.pathname?.includes("role")
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/role"
                >
                  <SvgImage type={"role"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("ROLES")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasStaffPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname?.includes("staff")
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/staff"
                >
                  <SvgImage type={"staff"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("STAFF")}{" "}
                  </span>
                </Link>
              </li>
            )}
            {hasSettingPermsisson && (
              <li>
                <Link
                  className={
                    location?.pathname === "/dashboard/setting"
                      ? " active d-flex align-items-center"
                      : "d-flex align-items-center"
                  }
                  to="/dashboard/setting"
                >
                  <SvgImage type={"setting"} />

                  <span key="t-dashboards" className="navigation-text">
                    {_("SETTING")}{" "}
                  </span>
                </Link>
              </li>
            )}

            <li>
              <Link
                className={
                  location?.pathname === "/dashboard/posts"
                    ? " active d-flex align-items-center"
                    : "d-flex align-items-center"
                }
                to="/dashboard/posts"
              >
                <SvgImage type={"transaction"} />

                <span key="t-dashboards" className="navigation-text">
                  {_("POSTS")}{" "}
                </span>
              </Link>
            </li>

            {/* <li>
							<NavLink className={({ isActive }) => (isActive ? 'waves-effect active' : 'waves-effect')} to='/dashboard/buildingDetails'>
								<i className="bx bx-home-circle"></i>
								<span key="t-dashboards">{_('BUILDING_DETAILS')} </span>
							</NavLink>
						</li> */}

            {/* <li>
							<NavLink className={({ isActive }) => (isActive ? 'has-arrow waves-effect active' : 'has-arrow waves-effect')} to='/dashboard/projects' onClick={handleManagement}>
								<i className="bx bx-store"></i>
								<span key="t-ecommerce">{_('Projects')}</span>
							</NavLink>

							{
								managePanel === true
									?
									<ul className={managePanel === true ? "sub-menu mm-collapse mm-show" : "sub-menu mm-collapse"} >
										<li>
											<NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/dashboard/milestones'> {_('Milestone')}
											</NavLink>
										</li>
										<li>
											<NavLink className={({ isActive }) => (isActive ? 'active' : '')} to='/dashboard/milesstoneiteams'>{_('Milestone items')}
											</NavLink>
										</li>
									</ul>
									:
									null
							}
						</li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LeftNavigation;
