import React, { useState, useRef } from 'react';
import { uploadFile } from '../../actions/fileupload';

function FileUploadPopup({ showImportCSV, onClose, onUpload }) {

const [file, setFile] = useState(null);
const fileInputRef = useRef(null);
const [fileId, setFileId] = useState(null)

const handleFileChange = async(event) => {
   setFile(event.target.files[0])
   const file = event.target.files[0]
   const formData = new FormData()
   formData.append('file', file)
  const {responseData} = await uploadFile(formData)
  console.log("fileData", responseData)
  setFileId(responseData?.id)
};

const handleUpload = () => {
  console.log("fileId", fileId)
   if (fileId) {
    onUpload(fileId)
     setFile(null);
     // Optionally, reset the file input value to allow re-uploading the same file
    // fileInputRef.current.value = null;
   }
};

if (!showImportCSV) return null;

return (
   <div className="model-overlay">
     <div className="file-upload-popup">
       <h2>Upload File</h2>
       <input
         type="file"
         ref={fileInputRef}
         onChange={handleFileChange}
         accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
       />
        <div className="file-upload-button-container">
        <button class= {file ? 'btn btn-primary' : 'btn btn-primary disabled'} type="button" onClick={handleUpload}>Upload</button>
       <button class="btn btn-primary" type="button" onClick={onClose}>Cancel</button>
        </div>  
        </div>
   </div>
);

}

export default FileUploadPopup