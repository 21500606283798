import axios from "axios";
import {BASE_URL} from "../constants";

export const USER_LISTING = 'USER_LISTING';
// export const PLAN_DETAIL = 'PLAN_DETAIL';
// export const DELETE_PLAN = 'DELETE_PLAN';
// export const PLAN_STATUS = 'PLAN_STATUS';

export const getTeamListing = (props) => {
    console.log(props,"sdcsdc")
    return axios({
        method: 'GET',
        params: props,
        url: `/teams`
    });
};

export const updateTeamStatus = (payload,id) => {
    console.log(payload,"dsbcdhjsbc")
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/team/${id}/status`
    });
};

export const createTeam = (data) => {
    return axios({
        method: 'POST',
        data: data,
        url: `/team`
    });
};

export const deleteTeam = (id,data) => {
    return axios({
        method: 'DELETE',
        // data: data,
        url: `/team/${id}`
    });
};


export const getSingalTeam = (id) => {
    return axios({
        method: 'GET',
        // data: data,
        url: `/admin/team/${id}`
    });
};

export const updateTeam = (id,data) => {
    return axios({
        method: 'PATCH',
        data: data,
        url: `/team/${id}`
    });
};


