import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { limit } from "../../constants";

import { getSuportsListing } from "../../actions/Suports";
import SuportsAddEditModal from "../../components/Modal/SuportsAddEditModal";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import TeamSkelton from "./TeamSkeleton";
import TeamSlide from "./TeamSlide";
import { getTeamListing } from "../../actions/teams";
import TeamAddEditModal from "../../components/Modal/TeamAddEditModal";
import PageHeading from "../../components/common/PageHeading";
import Pagination from "react-js-pagination";
import Skeleton from "react-loading-skeleton";
import { uploadFileId, uploadFileIdForTeam } from "../../actions/fileupload";
import { DownLoadCSVFile } from "../../actions/common";
import { getSampleImportFile } from "../../actions/athlete";
import FileUploadPopup from "../../components/Modal/FileUploadPopup";
import ImportErrorModel from "../../components/Modal/ImportErrorModel";
import SuccessModal from "../../components/Modal/SuccessModel";


function ManageTeam() {
  // local variables
  const [list, setlist] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [show, setShow] = useState(false);
  const { control, handleSubmit, setValue } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const [showImportCSV, setShowImportCSV] = useState(false);
  const [importModel, setImportModel] = useState({
    showImportErrorModel: false,
    showSuccessModel: false,
    importResponse: [],
  });
  const initalFetchData=useRef(true)

  let search = searchParams.get("searchText");

  const fetchData = async (page, filters) => {
    try {
      const params = {
        page,
        perPage: 20,
        universityId: id,
        ...filters, // Include filters in the request parameters
      };
      const resp = await getTeamListing(params);
      setlist(resp?.data?.responseData);
      initalFetchData.current=false
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current=false
    }
  };

  // const handlePageChange = (page) => {
  //   setActivePage(page);
  // };

  // const handleSetShowUser=()=>{
  //   setShowUserModal(true)
  // }

  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);


  const handleClosePopup = () => {
    setShowImportCSV(false);
  };
  // file upload on server
  const handleUploadFile = async (fileId) => {
    console.log("Uploading file:", fileId);
    const { data } = await uploadFileIdForTeam(fileId);
    setShowImportCSV(false);
    const isSuccess =
      data?.responseData?.length > 0 &&
      data?.responseData?.every((res) => res.status === 200);
    if (isSuccess) {
      setImportModel((prevState) => ({
        ...prevState,
        importResponse: [],
        showImportErrorModel: false,
        showSuccessModel: true,
      }));
    } else {
      // setImportModel(prevState=>({...prevState, importResponse: [], showImportErrorModel: false, showSuccessModel: true}))
      setImportModel((prevState) => ({
        ...prevState,
        importResponse: [...data?.responseData],
        showImportErrorModel: true,
        showSuccessModel: false,
      }));
    }
  };

  const handlePopup = (e) => {
    setShowImportCSV((prev) => !prev);
  };
  // download CSVfile functionality
  const handleDownLoadCSV = async () => {

    const Params = {
      type:'team'
    }
    const fileUrl = await getSampleImportFile(Params);
    const res = DownLoadCSVFile(fileUrl, 'Team')
    // const fileUrl = await getSampleImportFile();

  };


  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <div>
                <PageHeading
                  heading={_(`TEAMS`)}
                  count={list?.totalRecords || 0}
                />
                <h5 className="d-flex align-items-center">
                  University name :{" "}
                  {list?.metaData?.universityInfo ? (
                    list?.metaData?.universityInfo?.name
                  ) : (
                    <Skeleton width={100} height={16} className="mt-1 ms-2"/>
                  )}{" "}
                </h5>
              </div>
              <div className="flex-shrink-0">
                <>
                <>
                    <button
                      onClick={handlePopup}
                      className="btn newBtn btn-dark mr-20 "
                    >
                      {_("IMPORT_FILE")}
                    </button>
                    <button
                      onClick={handleDownLoadCSV}
                      className="btn newBtn btn-dark mr-20 "
                    >
                      {_("DOWNLOAD_FILE")}
                    </button>
                    <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_TEAM")}
                </Link>
                  </>
                </>
              </div>
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by team name, sports name...")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("TEAM_NAME")}</th>
                        <th scope="col">{_("SUPORTS_NAME")}</th>
                        {/* <th scope="col">{_("UNIVERSITY_NAME")}</th> */}
                        <th scope="col">{_("STATUS")}</th>
                        <th scope="col">{_("ACTION")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <TeamSkelton count={8} />
                      ) : list && list?.data && list?.data?.length > 0 ? (
                        list &&
                        list?.data &&
                        list?.data?.length > 0 &&
                        list?.data?.map((obj, index) => (
                          <TeamSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            activePage = {activePage}
                            filtersData={filtersData}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {list && list?.totalRecords > 20 && (
                //   <Pagination
                //   activePage={activePage}
                //   itemsCountPerPage={20}
                //   totalItemsCount={list?.totalPages ?? 1}
                //   pageRangeDisplayed={6}
                //   onChange={handlePageChange}
                // />
                <ResponsivePagination
                  current={activePage}
                  total={list?.totalPages ?? 1}
                  onPageChange={handlePageChange}
                  maxWidth={20}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <TeamAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          setActivePage={setActivePage}
        />
      )}
      
      {showImportCSV ? (
        <FileUploadPopup
          showImportCSV={showImportCSV}
          onClose={handleClosePopup}
          onUpload={handleUploadFile}
        />
      ) : (
        <></>
      )}
      {importModel?.showImportErrorModel ? (
        <ImportErrorModel
          importModel={importModel}
          setImportModel={setImportModel}
          fetchData={fetchData}
          section='team'
        />
      ) : (
        <></>
      )}
      {importModel?.showSuccessModel ? (
        <SuccessModal
          importModel={importModel}
          setImportModel={setImportModel}
          fetchData={fetchData}
        />
      ) : (
        <></>
      )}

    </div>
  );
}
export default ManageTeam;
