import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import ReactSelectField from "../FormFields/ReactSelectField";

import { getUniversityListing } from "../../actions/University";
import { getSuportsListing } from "../../actions/Suports";
import { getTeamListing } from "../../actions/teams";
import {
  createAthlete,
  getAthleteById,
  updateAthlete,
} from "../../actions/athlete";
import { confirmAlert } from "react-confirm-alert";
import ImageUploaderField from "../FormFields/ImageUploderFiled";
import AutoCompleteField from "../../actions/AutoComplete";

function MerchentAddEditModal({
  fetchData,
  show,
  onHide,
  data,
  id,
  setActivePage,
  activePage,
  filtersData,
}) {
  const navigate = useNavigate();

  // console.log(data, "dsjhbcsdhjcs");
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({});
  const [defaultValue, setDefaultValue] = useState();
  const [defaultLatLng, setDefaultLatLng] = useState();
  const kGoogleKey = "AIzaSyBj2sDQqTe7MWv6F71KfmfUsh3LyaXhxdg";

  const onSubmit = async (formData) => {
    const payload = {
      ...formData,
      mapAddress: formData?.location?.label,
      latitude: defaultLatLng?.lat
        ? defaultLatLng?.lat?.toString()
        : formData?.location?.latitude?.toString(),
      longitude: defaultLatLng?.lng
        ? defaultLatLng?.lng?.toString()
        : formData?.location?.longitude?.toString(),
    };
    delete payload?.location;
    if (id) {
      payload.imageId = formData?.imageId?.id;
    } else {
      payload.attachmentId = formData?.imageId?.id;
      payload.roleCode = "merchant";
      delete payload?.imageId;
    }

    // Handle optional merchantId field
    if (!formData.merchantId) {
      delete payload.merchantId;
    }

    try {
      let resp;
      if (id) {
        resp = await updateAthlete(id, payload);
      } else {
        resp = await createAthlete(payload);
        navigate("/dashboard/merchant");
      }

      if (resp?.status === 200) {
        // Fetch data and hide the form
        onHide();
        fetchData(activePage, filtersData);
        // setActivePage(1)
      }
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const fetchSingalData = async (id) => {
    // setShowLoader(true);
    try {
      const resp = await getAthleteById(id);
      const data = resp?.data?.responseData;
      let roleList = [];
      if (data?.userTeams?.length) {
        data?.userTeams &&
          data?.userTeams?.map((ork, index) => {
            roleList.push(ork.id);
          });
      }
      console.log(resp, "sdvhbsjdv");
      setValue("name", data?.userProfile?.name);
      setValue("email", data?.email);
      setValue("imageId", data?.userProfile?.profileImage);
      setDefaultValue(data?.address[0]?.mapAddress);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  useEffect(() => {
    if (data) {
      setValue("name", data?.merchantName);
      setValue("merchantId", data?.merchantId);
    }
  }, [data]);

  useEffect(() => {
    if (id) {
      fetchSingalData(id);
    }
  }, [id]);
  console.log(defaultValue, "sdcshbd");

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-580 addLeadModal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {id !== null && id !== undefined
              ? _("EDIT_MERCHENT")
              : _("ADD_MERCHENT")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label className="form-label">
                      {_("Store Name") + "*"}
                    </label>
                    <Inputfield
                      control={control}
                      name={"name"}
                      placeholder={_("E.g. Walmart Supercenter North Ave..")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("ENTER_NAME"),
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{_("MERCHANT_ID")}</label>
                    <Inputfield
                      control={control}
                      name={"merchantId"}
                      placeholder={_("Enter merchant id")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="text"
                      inputClassName={"form-control"}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">{_("EMAIL") + "*"}</label>
                    <Inputfield
                      control={control}
                      name={"email"}
                      placeholder={_("E.g. example@gmail.com")}
                      normalize={(e) => e.replace(/^\s+/g, "")}
                      type="email"
                      inputClassName={"form-control"}
                      rules={{
                        required: {
                          value: true,
                          message: _("ENTER_EMAIL"),
                        },
                        pattern: {
                          value:
                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                          message: _("INVALID_EMAIL_FORMAT"),
                        },
                      }}
                    />
                  </div>

                  <div className="new-class">
                    <div className="mb-3">
                      <label className="form-label">
                        {_("LOCATION") + "*"}
                      </label>
                      {defaultValue && id && (
                        <AutoCompleteField
                          control={control}
                          name="location"
                          placeholder="Enter location"
                          apiKey={kGoogleKey}
                          defaultValue={defaultValue}
                        />
                      )}

                      {defaultValue === undefined && id === undefined && (
                        <AutoCompleteField
                          control={control}
                          name="location"
                          placeholder="Enter location"
                          apiKey={kGoogleKey}
                        />
                      )}
                    </div>
                  </div>

                  <div className="">
                    <div className="mb-3">
                      <ImageUploaderField
                        control={control}
                        label="Store logo"
                        name="imageId"
                        uploadType="image"
                        setValue={setValue}
                        changeLabel={"logo"}
                        uploadText="Upload logo"
                        extensionTypes={["jpeg", "jpg", "png"]}
                        rules={{
                          required: {
                            value: true,
                            message: "Upload logo",
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting && (
                        <Spinner animation="border spiner-border-style" />
                      )}
                      {id ? <span>{_("UPDATE")}</span> : _("ADD_MERCHENT")}
                    </button>
                    <Link
                      className="btn btn-secondary waves-effect"
                      onClick={() => {
                        onHide();
                      }}
                    >
                      {_("CANCEL")}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MerchentAddEditModal;
