import React, { useEffect, useState } from "react";
import { _ } from "../../locale";
import { getMatchById } from "../../actions/match";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

const LeaderboardMatch = () => {
  const [loader, setLoader] = useState(true);
  const [matchList, setMatchList] = useState({});
  const [matchLeaderBoardDetails, setMatchLeaderBoardDetails] = useState([]);
  const { id } = useParams();

  const getFanDetailListing = async (id) => {
    try {
      const resp = await getMatchById(id);
      setMatchList(resp?.data?.responseData);
      let TeamMatchDetails =
        resp?.data?.responseData?.teams?.length &&
        resp?.data?.responseData?.teams?.map((team_data) => {
          let arr = [];
          let totalContributions = 0;
          resp?.data?.responseData?.leaderboardData?.length > 0 &&
            resp?.data?.responseData?.leaderboardData?.map((lead_data) => {
              if (team_data?.id === lead_data?.teamId) {
                totalContributions = lead_data?.contributions?.reduce((acc, num)=>{
                  const updatedSum = acc + num.amount;
                  return updatedSum;
                },0);
            lead_data?.contributions?.length > 0 && lead_data?.contributions?.forEach((contr_data)=>{
                  arr.push({
                    amount: contr_data?.amount,
                    contributionBy: contr_data?.fan?.userProfile,
                    rank: contr_data?.rank
                  });
            })
              }
            });
          let obj = {
            team: team_data,
            teamALeaderBoardDetail: [...arr],
            totalContribution: totalContributions,
          };
          setMatchLeaderBoardDetails((prevData) => [...prevData, obj]);
        });
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFanDetailListing(id);
    }
  }, []);

  console.log("matchLeaderBoardDetails", matchLeaderBoardDetails)

  return (
    <>
     { loader == true
                        ?
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        :(
      <div className="page-content">
        <div className="container-fluid">
          <div className="row mb-5">
            <h5 className="fs-3 mb-4">{matchList?.title}</h5>
            <div className="col-md-4 ">
              <div className="d-flex align-items-center matchBox-wrapper">
                <div>
                  <img
                    src={matchLeaderBoardDetails[0]?.team?.attachment?.filePath}
                    alt=""
                    width={72}
                    height={72}
                    className="rounded-circle "
                  />
                </div>
                <div className="ps-3">
                  <h4 className="fs-4 mb-0">
                    {matchLeaderBoardDetails[0]?.team?.name}
                  </h4>
                  <h5 className="fs-5 pt-1">
                    {matchLeaderBoardDetails[0]?.team?.university?.code}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              <img
                src="/assets/images/vs_icon.svg"
                alt=""
                width={"40px"}
                height={"40px"}
              />
            </div>
            <div className="col-md-4">
              <div className="d-flex matchBox-wrapper align-items-center">
                <div>
                  <img
                    src={matchLeaderBoardDetails[1]?.team?.attachment?.filePath}
                    alt=""
                    width={72}
                    height={72}
                    className="rounded-circle"
                  />
                </div>
                <div className="ps-3">
                  <h4 className="fs-4 mb-0">
                    {matchLeaderBoardDetails[1]?.team?.name}
                  </h4>
                  <h5 className="fs-5 pt-1">
                    {matchLeaderBoardDetails[1]?.team?.university?.code}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <h6 className="fs-5 mb-3">
                Total Contributions -{" "}
                <span>{matchLeaderBoardDetails[0]?.totalContribution}</span>
              </h6>
              <h5>Leaderboard</h5>
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap table-hover">
                      <thead className="table-light">
                        <tr>
                          <th scope="col" className="text-uppercase">
                            {_("RANK")}
                          </th>
                          <th scope="col" className="text-uppercase">
                            {_("NAME")}
                          </th>
                          <th scope="col" className="text-uppercase">
                            {_("AMOUNT")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {matchLeaderBoardDetails[0]?.teamALeaderBoardDetail
                          ?.length > 0 ?
                          matchLeaderBoardDetails[0]?.teamALeaderBoardDetail?.map(
                            (tlb_data) => {
                              return (
                                <tr>
                                  <td>{tlb_data?.rank}</td>
                                  <td>{tlb_data?.contributionBy?.name}</td>
                                  <td>{tlb_data?.amount}</td>
                                </tr>
                              );
                            }
                          ): (
                            <tr className="text-center">
                            <td colspan="12">
                              <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                                No Record Found
                              </h5>
                            </td>
                          </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <h6 className="fs-5 mb-3">
                Total Contributions -{" "}
                {matchLeaderBoardDetails[1]?.totalContribution}
              </h6>
              <h5>Leaderboard</h5>
              <div className="card">
                <div className="card-body">
              <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" className="text-uppercase">
                        {_("RANK")}
                      </th>
                      <th scope="col" className="text-uppercase">
                        {_("NAME")}
                      </th>
                      <th scope="col" className="text-uppercase">
                        {_("AMOUNT")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {matchLeaderBoardDetails[1]?.teamALeaderBoardDetail
                      ?.length > 0 ?
                      matchLeaderBoardDetails[1]?.teamALeaderBoardDetail?.map(
                        (tlb_data) => {
                          return (
                            <tr>
                              <td>{tlb_data?.rank}</td>
                              <td>{tlb_data?.contributionBy?.name}</td>
                              <td>{tlb_data?.amount}</td>
                            </tr>
                          )
                        }
                      ): (
                        <tr className="text-center">
                        <td colspan="12">
                          <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                            No Record Found
                          </h5>
                        </td>
                      </tr>
                      )}
                  </tbody>
                </table>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        )}
    </>
  );
};

export default LeaderboardMatch;
