import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  deleteUniversity,
  updateUiversityStatus,
} from "../../actions/University";
import { useState } from "react";
import { _ } from "../../locale";
import Form from "react-bootstrap/Form";
import UniversityAddModal from "../../components/Modal/UniversityAddModal";
import toast from "react-hot-toast";

function UniversitySlide({
  slideData,
  index,
  srno,
  fetchData,
  activePage,
  filtersData,
}) {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  console.log(fetchData, "sdcvhsdcvjsd");
  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteUniversity(slideData?.id);
              fetchData(1);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == "1" ? false : true;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateUiversityStatus(
                { status },
                slideData?.id
              );
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
  };

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td className="text-wrap">
        <Link to={`/dashboard/universities/teams/${slideData?.id}`}>
          {" "}
          {slideData?.name
            ? slideData?.name?.charAt(0)?.toUpperCase() +
              slideData?.name?.slice(1)
            : "----"}
        </Link>
      </td>
      <td className="text-wrap">
        {slideData?.mapAddress ? slideData?.mapAddress : "-"}
      </td>
      <td className="text-wrap">
        {slideData?.contactName ? slideData?.contactName : "-"}
      </td>
      <td className="text-wrap">
        {slideData?.contactEmail ? slideData?.contactEmail : "-"}
      </td>
      <td className="text-wrap">
        {slideData?.contactPhone === null ? "-" : slideData?.contactCountryCode}
        {slideData?.contactPhone}
      </td>
      <td>
        {
          <Form.Check
            type="switch"
            id={`custom-switch-${slideData?.id}`}
            onChange={() => updateStatus(slideData?.id)}
            checked={slideData.status == 1}
          />
        }
      </td>

      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Update"
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
          {/* <li>
            <Link
              onClick={() => {
                deleteRecord(slideData?.id);
              }}
              className="btn btn-sm btn-soft-danger newBtnDel"
              title="Delete"
            >
              <i className="mdi mdi-delete-outline"></i>
            </Link>
          </li> */}
        </ul>
      </td>
      {show && (
        <UniversityAddModal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          id={id}
          fetchData={fetchData}
          activePage={activePage}
          filtersData = {filtersData}
        />
      )}
    </tr>
  );
}

export default UniversitySlide;
