import React from "react";
import { Table } from "react-bootstrap";
import ReactTableSkelton from "./ReactTableSkeleton";

const ReactTable = ({ headers, data, isLoading }) => {
  return (
    <Table striped bordered hover className="mt-4">
      <thead className="table-light">
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="text-center">
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading === true ? (
          <ReactTableSkelton count={5} />
        ) : (
          data?.length > 0 ?
          (data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  className={` ${cellIndex == 1 ? "" : null} w-50 text-center `}
                  key={cellIndex}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))): (
            <>
            <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
            </>
          )
        )}
      </tbody>
    </Table>
  );
};

export default ReactTable;
