import React, { useEffect, useState } from "react";
import { _ } from "../../locale";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import moment from "moment";
import { getContributionByFanId } from "../../actions/athlete";
import { CONTRIBUTION_TYPE, limit } from "../../constants";
import ContributionSkeleton from "../Fan/ContributionSkeleton";
import ResponsivePagination from "react-responsive-pagination";
const ContributionHistory = () => {

  const params = useParams();
  const { control, reset, setValue, handleSubmit } = useForm();
  const [loader, setLoader] = useState(true);
  const [listing, setListing] = useState([]);
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(null);
  const [filtersData, setFiltersData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  let search = searchParams.get("searchText");
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
const [contributionType, setContributionType] = useState('')
const id = params?.id;

  const onSubmit = (data) => {
    setActivePage(1);
    let obj = {};
    if (Object.keys(data)?.length > 0) {
      for (let key in data) {
        if (key === "startDate" || key === "endDate") {
          if (data[key]) {
            obj[`${key}`] =
              key === "startDate"
                ? moment(data[key]).startOf("day").format()
                : moment(data[key]).endOf("day").format();
          }
        }
      }
    }
    console.log("obj+++", obj);
    setFiltersData(obj);
  };

  const constructSearchParams = () => {
    const queryParams = [];
    queryParams.push(`page=${activePage}`);

    if (Object.keys(filtersData)?.length > 0) {
      for (let key in filtersData) {
        if (key === "startDate" || key === "endDate") {
          queryParams.push(`${key}=${filtersData[key]}`);
        }
      }
    }
    const queryString = queryParams.join("&");
    setSearchParams(queryString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  const handleChange = (value) => {};
  const handlePageChange = (page) => {
    setActivePage(page);
  };
  // const handleResetFilter = () => {
  //   reset();
  //   setActivePage(1);
  //   setFiltersData({});
  //   setStartDates(null);
  //   setEndDates(null);
  // };
  const fetchRecord = async (page, filters) => {
    try {
      const params = {
        ...filters,
        page,
        perPage: 20,
      };
      const response = await getContributionByFanId(id, params);
      const data = response?.data?.responseData;
      setListing(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  

  useEffect(() => {
    if (id) {
      fetchRecord(activePage, filtersData);
    }
  }, [activePage, filtersData]);


  const handleResetFilter = () => {
    reset();
    setValue("type", "");
    setValue("userType", "");
    setActivePage(1);
    setFiltersData({});
    setStartDates(null);
    setEndDates(null);
  };

  console.log(params, "params");

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12"></div>
            <h5 className="fs-4 mb-3">{_("CONTRIBUTION_HISTORY")}</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex align-items-center gap-4">
                <div className="d-flex w-50 justify-content-between align-items-center gap-3">
                  <div className="mb-3 w-50">
                    <ReactDatePicker
                      control={control}
                      name="startDate"
                      label="Start Date"
                      placeholder="MM DD, YYYY"
                      onChangeDate={(selectedDate) => {
                        handleChange(selectedDate);
                      }}
                      rules={{
                        required: {
                          value: true,
                          message: "Enter start date",
                        },
                      }}
                      minDate={new Date()}
                      showTimeSelect={false}
                    />
                  </div>
                  -
                  <div className="mb-3 w-50">
                    <ReactDatePicker
                      control={control}
                      name="endDate"
                      label="End Date"
                      placeholder="MM DD, YYYY"
                      onChangeDate={(val) => {
                        setEndDates(moment(val));
                        handleChange(val);
                      }}
                      selectedEndDate={endDates}
                      rules={{
                        required: {
                          value: true,
                          message: "Enter end date",
                        },
                        validate: (value) => {
                          // Custom validation rule to check if rules are met
                          const selectedMoment = moment(value);
                          if (
                            selectedMoment.isSameOrBefore(startDates) ||
                            (selectedMoment.isSame(startDates, "day") &&
                              selectedMoment.format("HH:mm") ===
                                startDates.format("HH:mm"))
                          ) {
                            return "End date should be after start date";
                          }
                          return true;
                        },
                      }}
                      minDate={startDates ? startDates.toDate() : new Date()}
                      customError={
                        startDates &&
                        endDates &&
                        (endDates.isSameOrBefore(startDates) ||
                          (endDates.isSame(startDates, "day") &&
                            endDates.format("HH:mm") ===
                              startDates.format("HH:mm"))) &&
                        "End date should be after start date"
                      }
                      showTimeSelect={false}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center gap-4 pt-1">
                  <button
                    className="btn newBtn btn-dark applyBtn"
                    type="submit"
                  >
                    Apply Now
                  </button>
                  <button
                    className="btn btn-danger applyBtn resetButton"
                    type="reset"
                    onClick={handleResetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
            <div className="py-3">
              <h5 className="d-flex">
                Total Amount Contributed:<h5 className="ms-4">0</h5>{" "}
              </h5>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          {/* <tr>
                            <th scope="col" className="text-uppercase">
                              {_("DATE")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("AMOUNT")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("CONTRIBUTED_TO")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("ATHLETES")}
                            </th>
                          </tr> */}
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col" className="text-uppercase">
                              {_("DATE")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("TRANSACTION_ID")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("CONTRIBUTION_VIA")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("AMOUNT")}
                            </th>
                            <th scope="col" className="text-uppercase">
                              {_("CONTRIBUTED_TO")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                           {loader ? (
                            <ContributionSkeleton count={5} />
                          ) : listing?.data?.length > 0 ? (
                            listing?.data?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {parseInt(
                                      (activePage - 1) * limit +
                                        parseInt(index + 1)
                                    )}
                                  </td>
                                  <td>
                                    {data?.createdAt
                                      ? moment(data?.createdAt).format("lll")
                                      : "-"}
                                  </td>
                                  <td className="text-wrap">
                                    {data?.transactionId
                                      ? data?.transactionId
                                      : "----"}
                                  </td>
                                  <td>{CONTRIBUTION_TYPE[`${data?.type}`]}</td>
                                  <td>{data?.amount ? data?.amount.toFixed(2) : "-"}</td>
                                  <td>
                                    {" "}
                                    <Link
                                      onClick={() =>{}}
                                      className="d-flex align-items-center gap-2"
                                      title="View detail"
                                    >
                                      <i className="mdi mdi-eye-outline"></i>
                                      <span>view</span>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="12" className="fs-5 text-center">
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {listing && listing?.totalRecords > 20 && (
                  <ResponsivePagination
                    current={activePage}
                    total={listing?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                    maxWidth={20}
                  />
                 )} 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContributionHistory;
