import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { _ } from "../../locale";
import { getPostSpamListing } from "../../actions/posts";

function FlaggedPost({ show, onHide, id, slideData }) {
  const [loader, setLoader] = useState(false);
  const [records, setRecords] = useState(null);

  const getFlaggedPostListing = async (id) => {
    setLoader(true);
    try {
      const resp = await getPostSpamListing(id);
      setRecords(resp?.data?.responseData);
      setLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFlaggedPostListing(id);
    }
  }, [id]);

  console.log("8888888", records);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="right-modal followup-modal max-with-900 addLeadModal"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <div className="userimg-name">
              <div>
                <h2>
                  Posted by: {slideData?.user?.userProfile?.name ?? null}{" "}
                </h2>
                <h6>Email: #{slideData?.user?.email ?? null}</h6>
              </div>
            </div>
            <h6 onClick={onHide}>
              <img src="/assets/svg/cross.png" />
            </h6>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="right-modal ">
            <div className="popup-header"></div>

            <div>
              <table className="user-details postsDetails">
                <tbody className="d-flex col-md-12">
                  <tr className="posts-tr">
                    <td>
                      <div className="d-flex col-md-12">
                        <div className="col-md-12 user-info-data">
                          {records &&
                            records.length > 0 &&
                            records.map((obj, index) => (
                              <>
                                <div className="flagged-panel">
                                  {obj?.reason ? (
                                    <tr>
                                      <td className="details-heading">
                                        {_("REASON")}:
                                      </td>
                                      <td className="mw-100 details-data">
                                        {obj?.reason ?? null}
                                      </td>
                                    </tr>
                                  ) : null}

                                  {obj?.markedReason ? (
                                    <tr>
                                      <td className="details-heading">
                                        {_("MARKED_REASON")}:
                                      </td>
                                      <td className="mw-100 details-data">
                                        {obj?.markedReason ?? null}
                                      </td>
                                    </tr>
                                  ) : null}

                                  <tr>
                                    <td className="details-heading">
                                      {_("COMMENTED_BY")}:
                                    </td>
                                    <td className="mw-100 details-data">
                                      {obj?.user?.userProfile?.name ?? null} (
                                      {obj?.user?.email ?? null})
                                    </td>
                                  </tr>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default FlaggedPost;
