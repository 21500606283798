import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  deleteUniversity,
  updateUiversityStatus,
} from "../../actions/University";
import { useState } from "react";
import { _ } from "../../locale";
import Form from "react-bootstrap/Form";
import AthleteAddEditModal from "../../components/Modal/AthleteAddEditModal";
import toast from "react-hot-toast";
import moment from "moment";
import { deleteAthlete, updateAthleteStatus } from "../../actions/athlete";
import { Dropdown } from "react-bootstrap";

function AthleteSlide({
  slideData,
  index,
  srno,
  fetchData,
  modalProperty,
  activePage,
  filtersData,
}) {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  console.log(slideData, "sdcvhsdcvjsd");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteAthlete(slideData?.id);
              fetchData(activePage);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == "1" ? false : true;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateAthleteStatus(slideData?.id, {
                status,
              });
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleAthleteShow = (id) => {
    setId(id);
    modalProperty(id);
  };

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td className="text-wrap">
        {slideData?.userProfile?.name !== null
          ? (slideData?.userProfile?.name).charAt(0).toUpperCase() +
            (slideData?.userProfile?.name).slice(1)
          : "-" ?? null ?? null}
      </td>
      <td className="text-wrap">
        {slideData?.email !== null ? slideData?.email : "-"}
      </td>
      <td>
        {
          <Form.Check
            type="switch"
            id={`custom-switch-${slideData?.id}`}
            onChange={() => updateStatus(slideData?.id)}
            checked={slideData.status == 1}
          />
        }
      </td>
      <td>
        {slideData?.userProfile
          ? (slideData?.userProfile?.contributionReceived -
            slideData?.userProfile?.contributionHold -
            slideData?.userProfile?.contributionSettled).toFixed(2)
          : "-"}
      </td>
      <td>{moment(slideData.createdAt).format("lll")}</td>

      <td>
        <Dropdown placement="left" drop={"start"}>
          <Dropdown.Toggle variant="link" className="p-0 dropdownToggle">
            <img
              src="/assets/images/menu-icon.svg"
              alt=""
              id="myDIV"
              width={35}
              height={35}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropDownMenu dropdown-menu-star">
            <Dropdown.Item
              onClick={() => {
                handleAthleteShow(slideData?.id);
              }}
            >
              <div className="menus d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-12 grey3">View</p>
                <div>
                  <img
                    src="/assets/images/view-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
            >
              <div className="menus d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-12 grey3">Edit</p>
                <div>
                  <img
                    src="/assets/images/edit-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                deleteRecord(slideData?.id);
              }}
            >
              <div className="menus d-flex align-items-center justify-content-between">
                <p className="mb-0 fs-12 grey3">Delete</p>
                <div>
                  <img
                    src="/assets/images/delete-icon.svg"
                    alt=""
                    width={16}
                    height={16}
                  />
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link
                to={`/dashboard/athlete/nil-deals/${slideData?.id}`}
                title="History"
              >
                <div className="menus d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-12 grey3">{_("NILDEALS")}</p>
                  <div>
                    <img
                      src="/assets/images/contribution-history.svg"
                      alt=""
                      width={16}
                      height={16}
                    />
                  </div>
                </div>
              </Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link
                to={`/dashboard/athlete/transaction-details/${slideData?.id}`}
                title="Detail"
              >
                <div className="menus d-flex align-items-center justify-content-between">
                  <p className="mb-0 fs-12 grey3">Transaction</p>
                  <div>
                    <img
                      src="/assets/images/transaction-icon.svg"
                      alt=""
                      width={16}
                      height={16}
                    />
                  </div>
                </div>
              </Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      {show && (
        <AthleteAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          id={slideData?.id}
          activePage={activePage}
          filtersData={filtersData}
        />
      )}
    </tr>
  );
}

export default AthleteSlide;
