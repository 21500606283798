import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useController } from "react-hook-form";
import moment from "moment";

import { errorType } from "../../constants";

function ReactDatePicker({
  name,
  control,
  rules,
  label,
  errorClass,
  onChangeDate, // Add this prop for handling external onChange logic
  minDate,
  maxDate,
  placeholder,
  showTimeSelect=true,
  minTime,
  maxTime,
  customError

}) {
  const {
    field,
    fieldState: { error },
    field: { onChange },
  } = useController({ name, control, rules });

  const [date, setDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);


  const handleChangeDate = (selectedDate) => {
    setDate(selectedDate);
    if (selectedDate) {
      setSelectedDate(selectedDate);
      field.onChange(moment(selectedDate).format("MM/DD/YYYY HH:mm"));
      // If an external onChange logic is provided, call it here
      if (onChangeDate) {
        onChangeDate(selectedDate);
      }
    }
  };


  useEffect(() => {
    if (field.value) {
      const initialDate = moment(field.value, "MM/DD/YYYY HH:mm", true);
      if (initialDate.isValid()) {
        setDate(initialDate.toDate());
      } else {
        console.error("Invalid initial date value:", field.value);
      }
    }
    
  }, [field.value]);

  return (
    <>
      <div className="form-group">
        {label && <label>{label}</label>}
        <div className="textfield-block">
          <DatePicker


            minDate={minDate ? minDate : null}
            maxDate={maxDate ? maxDate : null}
            minTime={minTime ? minTime : null}
            maxTime={maxTime ? maxTime : null}
            selected={(field.value && new Date(field.value)) || null}
            // onChange={(val) => {
            //   setFieldValue(name, val);
            // }}
            onChange={handleChangeDate}
            placeholderText={placeholder ? placeholder : "MM DD, YYYY HH:MM AM/PM"}
            className="form-control w-100"
            timeFormat="HH:mm"
            showTimeSelect={showTimeSelect}
            timeIntervals={15}
            timeCaption="Time"
            dateFormat={placeholder ? "MMM dd, yyyy" : "MMM d, yyyy h:mm aa"}
            autoComplete="off"

            
          />

          {customError && (
            <span className="error-msg">
              {customError}
            </span>
          )}
          {errorType?.map((type) => {
            if (error?.type === type && error?.message !== "") {
              return (
                <span key={type} className={"error-msg"}>
                  {error?.message}
                </span>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}

export default ReactDatePicker;


// import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { useController } from "react-hook-form";
// import moment from "moment";

// import { errorType } from "../../constants";

// function ReactDatePicker({
//   name,
//   control,
//   rules,
//   label,
//   errorClass,
//   onChangeDate,
//   minDate,
//   maxDate,
// }) {
//   const {
//     field,
//     fieldState: { error },
//   } = useController({ name, control, rules });
//   const customStyles = {
//     width: "300px",
//   };
//   const [date, setDate] = useState(null);
//   const currentDate = new Date();
//   const [selectedDate, setSelectedDate] = useState(null);

//   const handleChangeDate = (selectedDate) => {
//     setDate(selectedDate);
//     if (selectedDate) {
//       setSelectedDate(selectedDate);
//       field.onChange(moment(selectedDate).format("MM/DD/YYYY HH:mm"));
//       if (onChangeDate) {
//         onChangeDate(moment(selectedDate).format("MM/DD/YYYY HH:mm"));
//       }
//     }
//   };

//   useEffect(() => {
//     if (field.value) {
//       const initialDate = moment(field.value, "MM/DD/YYYY HH:mm", true);
//       if (initialDate.isValid()) {
//         setDate(initialDate.toDate());
//       } else {
//         console.error("Invalid initial date value:", field.value);
//       }
//     }
//   }, [field.value]);

//   return (
//     <>
//       <div className="form-group">
//         {label && <label>{label}</label>}
//         <div className="textfield-block">
//           <DatePicker
//             style={customStyles}
//             selected={date}
//             minDate={minDate ? new Date(minDate) : currentDate}
//             maxDate={maxDate && new Date(maxDate)}
//             showTimeSelect
//             timeFormat="HH:mm"
//             timeIntervals={15}
//             dateFormat="MMM d, yyyy h:mm aa"
//             timeCaption="Time"
//             onChange={(selectedDate) => handleChangeDate(selectedDate)}
//             placeholderText="MMM DD, YYYY HH:MM AM/PM"
//             className="form-control w-100"
//             filterDate={(date) => {
//               return (
//                 (minDate ? date >= new Date(minDate) : true) &&
//                 (maxDate ? date <= new Date(maxDate) : true) &&
//                 (selectedDate ? date >= selectedDate : true) &&
//                 date > currentDate
//               );
//             }}
//           />

//           {errorType?.map((type) => {
//             if (error?.type === type && error?.message !== "") {
//               return (
//                 <span key={type} className={"error-msg"}>
//                   {error?.message}
//                 </span>
//               );
//             }
//           })}
//         </div>
//       </div>
//     </>
//   );
// }

// export default ReactDatePicker;


