import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { _ } from "../../locale";
import { contributionModes, contributionTo, limit } from "../../constants";

import { getSuportsListing } from "../../actions/Suports";
import SuportsAddEditModal from "../../components/Modal/SuportsAddEditModal";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import TeamAddEditModal from "../../components/Modal/TeamAddEditModal";
import PageHeading from "../../components/common/PageHeading";
import Pagination from "react-js-pagination";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import moment from "moment";
import setMinutes from "date-fns/setMinutes";
import { getTransactionExportData, getTransactionListing } from "../../actions/transaction";
import TransactionSkelton from "./TransactionSkeleton";
import TransactionSlide from "./TransactionSlide";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

function ManageTransaction() {
  const [list, setlist] = useState([]);
  // const [showUserModal, setShowUserModal] = useState(false);
  const [show, setShow] = useState(false);
  const { control, register, handleSubmit, setValue, reset, setError } =
    useForm();
  const [filtersData, setFiltersData] = useState({});
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(moment(new Date()));
  // const [startTime, setStartTime] = useState();
//  const [contribution, setContribution] = useState({});
//  const [transactionData, setTransactionData] = useState()

  let search = searchParams;
  const initalFetchData = useRef(true);


  const fetchData = async (page, filters) => {
    
    try {
      const params = {
        page,
        perPage: 20,
        ...filters, // Include filters in the request parameters
      };
      const { responseData } = await getTransactionListing(params);
      setlist(responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };

  const exportTransactionData = async (params) => {
    try {   
      const { responseData } = await getTransactionExportData(params);
      // setTransactionData(responseData);
     const expdata =  exportToExcelContributionData(responseData)
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const onSubmit = (data) => {
    setActivePage(1);
    let obj = {};
    if (Object.keys(data)?.length > 0) {
      for (let key in data) {
        if (key === "startDate" || key === "endDate") {
          if (data[key]) {
            obj[`${key}`] = key === "startDate" ? (moment(data[key]).startOf('day')).utc().format() : (moment(data[key]).endOf('day')).utc().format();
          }
        } else {
          if (data[key]) {
            obj[`${key}`] = data[key];
          }
        }
      }
    }
    setFiltersData(obj);
  };

  const constructSearchParams = () => {
    console.log("working....", filtersData);
    const queryParams = [];
    queryParams.push(`page=${activePage}`);

    if (Object.keys(filtersData)?.length > 0) {
      for (let key in filtersData) {
        if (key === "startDate" || key === "endDate") {
          queryParams.push(`${key}=${filtersData[key]}`);
        } else {
          queryParams.push(`${key}=${filtersData[key]}`);
        }
      }
    }
    const queryString = queryParams.join("&");

    setSearchParams(queryString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  // useEffect(()=>{
  //   exportTransactionData()
  // },[])

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  //let isToday = moment(startTime).isSame(moment(), "day");

  // const calculateMinTime = (date, isStartTime) => {
  //   let isToday = moment(date).isSame(moment(), "day");
  //   if (isToday) {
  //     return isStartTime
  //       ? moment().toDate()
  //       : setMinutes(moment().toDate(), 10);
  //   }
  //   return moment().startOf("day").toDate();
  // };

  const handleChange = (value) => {
    console.log("value++", value)
    // setStartTime(value);
    // const minTime = isToday
    //   ? calculateMinTime(value, true)
    //   : moment().startOf("day").toDate();
    // console.log(minTime, "schjbsjc");
  };


  const handleResetFilter = () => {
    reset();
    setValue("type", "");
    setValue("userType", "");
    setActivePage(1);
    setFiltersData({});
    setStartDates(null);
    setEndDates(null);
  };

  const exportToExcelContributionData = (data) => {
    let jsonData = []
    const json = data?.length>0 && data?.map((json_data)=>{
        jsonData.push({['Date']:moment(json_data?.date).format('lll'),['User Type']:json_data?.userType, ['Contribution By']:json_data?.contributedBy, ['Contribution via']:json_data?.contributedVia, ['Amount']:json_data?.amount})
    })
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `TransactionData-${uuidv4()}.xlsx`);
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              {/* <PageHeading heading={'Transaction'} count={list?.totalRecords || 0 }/> */}
              <PageHeading heading={"Transactions"} />
              <div className="flex-shrink-0">
                <Link
                  // onClick={() => exportToExcelContributionData(transactionData)}
                  onClick={() => exportTransactionData(filtersData)}
                  className="btn newBtn btn-dark"
                >
                  {_("EXPORT_TRANSACTION")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="Transaction-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-md-11 p-8">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <div className="d-flex justify-content-between gap-4">
                    <div className="mb-3">
                      <ReactDatePicker
                        control={control}
                        name="startDate"
                        label="Start Date"
                        onChangeDate={(selectedDate) => {
                          const selectedMoment = moment(selectedDate);
                          setStartDates(selectedMoment);
                         // handleChange(selectedDate);
                        }}
                        minDate={new Date("1900/01/01")}
                        maxDate={endDates ? endDates.toDate() : null}
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                    </div>
                    <div className="mb-3">
                      <ReactDatePicker
                        control={control}
                        name="endDate"
                        label="End Date"
                        // onChangeDate={(val) => {
                        //   const endOfDay = val ? moment(val).endOf('day') : null;
                        //   const formattedEndDate = endOfDay ? endOfDay.format('MM/DD/YYYY, HH:mm:ss') : '';
                        //   setEndDates(formattedEndDate);
                        //   handleChange(val);
                        // }}
                        onChangeDate={(val) => {
                          setEndDates(moment(val).endOf('day'));
                          handleChange(val);
                        }}
                        selectedEndDate={endDates}
                        rules={{
                          validate: (value) => {
                            const selectedMoment = moment(value);
                            if (
                              selectedMoment.isBefore(startDates)  &&
                              !selectedMoment.isSame(startDates, "day") 
                            ) {
                              return "End date should be after start date";
                            }

                            return true;
                          },
                        }}
                        minDate={
                          startDates
                            ? startDates.toDate()
                            : new Date("1900/01/01")
                        }
                        // customError={
                        //   startDates &&
                        //   endDates &&
                        //   (endDates.isSameOrBefore(startDates) ||
                        //     (endDates.isSame(startDates, "day") &&
                        //       endDates.format("HH:mm") ===
                        //         startDates.format("HH:mm"))) &&
                        //   "End date should be after start date"
                        // }
                        showTimeSelect={false}
                        placeholder="MM DD, YYYY"
                      />
                    </div>
                  </div>
                </div>

                <div className="select-contribution-mode">
                  <label className="form-label">{_("CONTRIBUTION_BY")}</label>
                  <div className="mb-3 select-box-width">
                    <ReactSelectField
                      control={control}
                      name="userType"
                      label={_("ROLE")}
                      placeholder={_("Select value")}
                      options={contributionTo}
                      optionValue="value"
                      optionLabel="label"
                      onChange={(selectedValue) => console.log("selectedValue", selectedValue)
                        // setContribution((prevState) => ({
                        //   prevState,
                        //   contributionTo: selectedValue,
                        // }))
                      }
                    />
                  </div>
                </div>

                <div className="select-contribution-mode">
                  <label className="form-label">{_("CONTRIBUTION_VIA")}</label>
                  <div className="mb-3 select-box-width">
                    <ReactSelectField
                      control={control}
                      name="type"
                      label={_("ROLE")}
                      placeholder={_("Select Mode")}
                      options={contributionModes}
                      optionValue="value"
                      optionLabel="label"
                      onChange={(selectedValue) => console.log("selectedValue", selectedValue)
                        // setContribution((prevState) => ({
                        //   prevState,
                        //   contributionVia: selectedValue,
                        // }))
                      }
                    />
                  </div>
                </div>
                <div className="my-4 d-flex align-items-center gap-4 pt-1">
                  <button
                    className="btn newBtn btn-dark applyBtn"
                    type="submit"
                  >
                    Apply Now
                  </button>
                  <button
                    className="btn btn-danger applyBtn resetButton"
                    type="reset"
                    onClick={handleResetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("DATE")}</th>
                        <th scope="col">{_("TRANSACTION_ID")}</th>
                        <th scope="col">{_("USER_TYPE")}</th>
                        <th scope="col">{_("CONTRUBUTED_BY")}</th>
                        <th scope="col">{_("CONTRIBUTION_VIA")}</th>
                        <th scope="col">{_("TRANSACTION_AMOUNT")}</th>
                        <th scope="col">{_("CONTRIBUTION_TO")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <TransactionSkelton count={8} />
                      ) : list && list?.data && list?.data?.length > 0 ? (
                        list &&
                        list?.data &&
                        list?.data?.length > 0 &&
                        list.data
                          // ?.filter((obj) => obj.type !== 4)
                          ?.map((obj, index) => (
                            <TransactionSlide
                              slideData={obj}
                              key={obj.id}
                              index={index}
                              srno={(activePage - 1) * limit}
                              fetchData={fetchData}
                              activePage = {activePage}
                              filtersData = {filtersData}
                            />
                          ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {list && list?.totalRecords > 20 && (
                <ResponsivePagination
                  current={activePage}
                  total={list?.totalPages ?? 1}
                  onPageChange={handlePageChange}
                  maxWidth={20}
                  pageRangeDisplayed={6}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <TeamAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          setActivePage={setActivePage}
        />
      )}
    </div>
  );
}
export default ManageTransaction;
