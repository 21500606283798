import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useState } from "react";
import { _ } from "../../locale";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import moment from "moment";
import { managePostSpamStatus } from "../../actions/posts";
import FlagedPost from "./FlagedPost";
import InavtivatePost from "./InavtivatePost";
import RoundUpGalleryModal from "../../components/Modal/RoundUpGalleryModal";

function PostSlide({
  slideData,
  index,
  srno,
  fetchData,
  modalProperty,
  activePage,
  filtersData,
}) {
  const [show, setShow] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // console.log(slideData, "sdcvhsdcvjsd");

  const updateStatus = () => {
    // let status = slideData.status == "1" ? false : true;
    let status = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await managePostSpamStatus(slideData?.id, {
                status,
              });
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const reload = () => {
    fetchData(activePage, filtersData);
  };

  const handleModalShow = (id) => {
    setShow(true);
    setId(id);
  };

  const handleActivateModalShow = (id) => {
    setShowInactive(true);
    setId(id);
  };

  const handleGalleryShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <tr>
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td className="text-wrap">
          {slideData?.user?.userProfile?.name ?? null}{" "}
        </td>
        <td className="text-wrap">{slideData?.user?.email ?? null}</td>
        <td className="text-wrap">{slideData?.description ?? null}</td>
        <td className="text-wrap">
          {slideData?.flaggedCount ? (
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
            >
              {slideData?.flaggedCount}
            </Link>
          ) : (
            0
          )}
        </td>

        <td>
          {slideData?.status == 1 ? (
            <a
              href="javascript:void(0)"
              onClick={() => {
                handleActivateModalShow(slideData?.id);
              }}
            >
              <span>{_("ACTIVE")}</span>
            </a>
          ) : slideData?.status == 2 ? (
            <a
              href="javascript:void(0)"
              onClick={() => updateStatus(slideData?.id)}
            >
              <span>{_("AUTO_HIDE")}</span>
            </a>
          ) : (
            <a
              href="javascript:void(0)"
              onClick={() => updateStatus(slideData?.id)}
            >
              <span>{_("INACTIVE")}</span>
            </a>
          )}

          {/* {
            <Form.Check
              type="switch"
              id={`custom-switch-${slideData?.id}`}
              onChange={() => updateStatus(slideData?.id)}
              checked={slideData?.status == 1}
            />
          } */}
        </td>

        <td>
          <Link
            onClick={() => handleGalleryShow()}
            className="d-flex align-items-center gap-2"
            title="View detail"
          >
            <i className="mdi mdi-eye-outline"></i>
            <span>view</span>
          </Link>
        </td>

        <td>{moment(slideData.updatedAt).format("lll")}</td>
      </tr>

      {show && (
        <FlagedPost
          show={show}
          onHide={() => setShow(false)}
          id={id}
          slideData={slideData}
        />
      )}

      {showInactive && (
        <InavtivatePost
          show={showInactive}
          onHide={() => setShowInactive(false)}
          id={id}
          slideData={slideData}
          reload={reload}
        />
      )}

      {showModal && (
        <RoundUpGalleryModal
          show={showModal}
          detail={slideData?.postAttachments}
          onHide={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
}

export default PostSlide;
