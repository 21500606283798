import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { updateUiversityStatus } from "../../actions/University";
import { useState } from "react";
import { _ } from "../../locale";
import toast from "react-hot-toast";
import MerchentAddEditModal from "../../components/Modal/MerchentAddEditModal";
import { deleteAthlete, updateAthleteStatus } from "../../actions/athlete";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";

function MerchentSlide({ slideData, index, srno, fetchData, modalProperty, filtersData, activePage }) {
  const [status, setStatus] = useState(slideData?.status);
  const [show, setShow] = useState(false);
  const [showContribution, setShowContribution] = useState(false);
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [id, setId] = useState(null);
  // console.log(slideData?.id, "sdcvhsdcvjsd");
  const [userName] = useState(slideData?.userProfile?.name);

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteAthlete(slideData?.id);
              fetchData(1);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const updateStatus = () => {
    let status = slideData.status == "1" ? false : true;
    let chkStatus = slideData.status == "1" ? 0 : 1;
    confirmAlert({
      title: "Confirm",
      message:
        slideData.status == "1"
          ? _("CONFIRM_DEACTIVATE")
          : _("CONFIRM_ACTIVATE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              const response = await updateAthleteStatus(slideData?.id, {
                status,
              });
              fetchData(activePage, filtersData);
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);

    setId(id);
  };
  const handleMerchantView = (id) => {
    modalProperty(id);
  };

  const handleContributionHistory = (id) => {
    setShowContribution(true);
  };
  const handleTransactionDetails = () => {
    setShowTransactionDetail(true);
  };

  return (
    <tr>
      <td className="mw-100">{parseInt(srno + parseInt(index + 1))}</td>
      <td className="mw-100">
        {slideData?.userProfile?.name?.charAt(0).toUpperCase() +
          slideData?.userProfile?.name?.slice(1) ?? null}
      </td>
      <td className="mw-100">
        {slideData?.email !== null ? slideData?.email : "-"}
      </td>
      <td>
        {slideData?.userProfile?.managerName !== null
          ? slideData?.userProfile?.managerName
          : "-"}
      </td>
      <td className="mw-100">
        {slideData?.mobile !== null ? slideData?.mobile : "-"}
      </td>
      <td className="mw-100">
        {slideData?.userPreference !== null
          ? slideData?.userPreference?.isRoundUpEnabled === false
            ? "Disabled"
           : "Enabled"
          : null}
      </td>
      <td className="mw-100">{0}</td>
      <td>{moment(slideData.createdAt).format("lll")}</td>
      <td>
        {
          <Form.Check
            type="switch"
            id={`custom-switch-${slideData?.id}`}
            onChange={() => updateStatus(slideData?.id)}
            checked={slideData.status == 1}
          />
        }
      </td>
      <td className="mw-100">
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
            <Link
              onClick={() => {
                handleMerchantView(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="View detail"
            >
              <i className="mdi mdi-eye-outline eyeIcon"></i>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Update"
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                deleteRecord(slideData?.id);
              }}
              className="btn btn-sm btn-soft-danger newBtnDel"
              title="Delete"
            >
              <i className="mdi mdi-delete-outline"></i>
            </Link>
          </li>
          <li>
            <Link
              // to={`/dashboard/merchant/contribution-history/${slideData?.id}/${slideData?.userProfile?.name}`}
              to={`/dashboard/merchant/contribution-history/${slideData?.id}`}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="History"
            >
              <i className="mdi mdi-history"></i>
            </Link>
          </li>
          <li>
            <Link
              to={`/dashboard/merchant/transaction-details/${slideData?.id}/${slideData?.userProfile?.name}`}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Detail"
            >
              <i className="mdi mdi-receipt"></i>
            </Link>
          </li>
        </ul>
      </td>
      {show && (
        <MerchentAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          id={slideData?.id}
          activePage = {activePage}
          filtersData = {filtersData}
        />
      )}
    </tr>
  );
}

export default MerchentSlide;
