import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { updateUiversityStatus } from "../../actions/University";
import { useState } from "react";
import { _ } from "../../locale";
import toast from "react-hot-toast";
import MerchentAddEditModal from "../../components/Modal/MerchentAddEditModal";
import { deleteAthlete, updateAthleteStatus } from "../../actions/athlete";
import Form from "react-bootstrap/Form";
import AddEditMatches from "../../components/Modal/AddEditMatches";
import moment from "moment";
import { deleteMatch } from "../../actions/match";
import MatchViewModal from "../../components/Modal/MatchViewModal";

function MatchSlide({ slideData, index, srno, fetchData, modalProperty }) {
  const [status, setStatus] = useState(slideData?.status);
  const [isChecked, setIsChecked] = useState(slideData.status == "1");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [showMatch, setShowMatch] = useState(false);

  console.log(slideData?.id, "sdcvhsdcvjsd");

  const deleteRecord = (id) => {
    console.log(id, "sdhbcsdhjbcs");
    confirmAlert({
      title: "Confirm",
      message: _("CONFIRM_DELETE"),
      buttons: [
        {
          label: _("YES"),
          onClick: async () => {
            try {
              await deleteMatch(slideData?.id);
              fetchData(1);
              // toast.info("Category type has been deleted successfully");
            } catch ({ request, response }) {
              if (response) {
                toast.error(response?.data?.message);
              } else if (request) {
                toast.error(_("NO_INTERNET"));
              }
            }
          },
        },
        {
          label: _("NO"),
        },
      ],
    });
  };

  const handleModalShow = (id) => {
    setShow(true);

    setId(id);
  };
  const handleMerchantView = (id) => {
    modalProperty(id);
  };

  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td className="text-wrap">
        {slideData?.title?.charAt(0).toUpperCase() +
          slideData?.title?.slice(1) ?? null}
      </td>

      <td className="text-wrap">
        {slideData?.sport !== null ? slideData?.sport?.name : "-"}
      </td>

      <td className="text-wrap">
        {slideData?.mapAddress !== null ? slideData?.mapAddress : "-"}
      </td>
      <td>
        {slideData?.startDate !== null
          ? moment(slideData?.startDate).format("lll")
          : "-"}
      </td>
      <td>
        {slideData?.endDate !== null
          ? moment(slideData?.endDate).format("lll")
          : "-"}
      </td>

      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
            <Link
              onClick={() => {
                setShowMatch(true);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="View detail"
            >
              <i className="mdi mdi-eye-outline eyeIcon"></i>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                handleModalShow(slideData?.id);
              }}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Update"
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                deleteRecord(slideData?.id);
              }}
              className="btn btn-sm btn-soft-danger newBtnDel"
              title="Delete"
            >
              <i className="mdi mdi-delete-outline"></i>
            </Link>
          </li>
          <li>
            <Link
              to={`/dashboard/match/leaderboard/${slideData?.id}`}
              className="btn btn-sm btn-soft-info newBtnEdit"
              title="Leaderboard"
            >
              <img
                src="/assets/images/leaderboard-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </Link>
          </li>
        </ul>
      </td>
      {show && (
        <AddEditMatches
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          id={slideData?.id}
        />
      )}
      {showMatch && (
        <MatchViewModal
          fetchData={fetchData}
          show={showMatch}
          onHide={() => {
            setShowMatch(false);
          }}
          id={slideData?.id}
        />
      )}
    </tr>
  );
}

export default MatchSlide;
