import { useController } from "react-hook-form";
import { errorType } from "../../constants";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { getAthleteListing, getAthleteListingData } from "../../actions/athlete";
import { Toast } from "react-bootstrap";
import toast from "react-hot-toast";

function EventMultiSelectCheckbox({
  name,
  control,
  rules,
  defaultValue,
  options,
  onChange,
  selectedAthleteIds,
  selectedAthlete,
  classname='',
 // setPage=null,
//  page=null,
setAthleteList=[],
  athleteList=null,
  universitySelectore=null,
  sportsSelector=null,
   universityId=null,
   sportId=null,
  section='',
  setSelectedAthletes=[]
}) {

  const {
    field,
    fieldState: { error },
    
  } = useController({ name, control, rules,  rules: {
    required: selectedAthleteIds ? false : 'select atleast one athlete',
  }, defaultValue: defaultValue || [] });
  const [selectedIds, setSelectedIds] = useState(selectedAthleteIds || []);
  const [searchInput, setSearchInput] = useState('');
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(2)
  const [filteredAthlete, setFilteredAthlete] = useState([])
  const [filteredPagination, setFilteredPagination] = useState(1)
  console.log(options, "zcbxzc")
  const onInputChange = (_value) => {
    const newValue = selectedIds.includes(_value)
      ? selectedIds.filter((item) => item !== _value)
      : [...selectedIds, _value];
    console.log(newValue, "sdvjhbsd")
    setSelectedIds(newValue);
    onChange(newValue);
    if (onChange) onChange(newValue);
    field.onChange(newValue);
  };

  useEffect(() => {
    setSelectedIds(selectedAthleteIds || []);
  }, [selectedAthleteIds]);

  const getMoreEventAthleteList= async(params)=>{
      setLoading(true)
      const resp = await getAthleteListingData(params);
          const newAthleteList = resp?.data?.responseData?.data?.map((elm) => ({
            profileImage: elm?.userProfile?.profileImage?.filePath,
            name: elm?.userProfile?.name,
            id: elm?.id
          }));
         setFilteredAthlete([...newAthleteList]) 
         setFilteredPagination(prevData=>(prevData+1))
          setLoading(false)
  }
  const getMoreGamesAthleteList = async(params)=>{
    setLoading(true)
    try {
      
      const resp = await getAthleteListing(params);
     
      const newAthleteList = resp?.data?.responseData?.data?.map((elm) => {
          const flag = selectedAthlete ? selectedAthlete.includes(elm?.id) : false;

          return {
              profileImage: elm?.userProfile?.profileImage?.filePath,
              name: elm?.userProfile?.name,
              id: elm?.id,
              flag: flag
          };
      });
     setFilteredAthlete([ ...newAthleteList]) 
     setFilteredPagination(prevData=>(prevData+1))
        setLoading(false)
  } catch ({ response, request }) {
    setLoading(false)
      if (response) {
        
          toast.error(response?.data?.message);
      } else if (request) {
          toast.error('No Internet');
      }


  }
  }


  // const filteredAthleteList = options?.filter((athlete) =>
  //   athlete?.name.toLowerCase().includes(searchInput.toLowerCase())
  // );
  // console.log(filteredAthleteList,"dsvcsdgyv")
  const observerTarget = useRef(null);
const handleObserver=async(page)=>{

  if(section.length > 0 && section === 'addGames'){
    setLoading(true)
    let params = {}
    try {
      if(searchInput?.length > 0){
        params = {
          page:filteredPagination,
          perPage: 20,
          isRegisteredUser: 1,
          sportId: sportId ? sportId : sportId?.value,
          universityId: universityId ? universityId : universitySelectore?.value,
          role: "athlete",
          searchText:searchInput
      };
      }else{
         params = {
          page:page,
          perPage: 20,
          isRegisteredUser: 1,
          sportId: sportId ? sportId : sportId?.value,
          universityId: universityId ? universityId : universitySelectore?.value,
          role: "athlete",
      };
      }
      
      const resp = await getAthleteListing(params);
     
      const newAthleteList = resp?.data?.responseData?.data?.map((elm) => {
          const flag = selectedAthlete ? selectedAthlete.includes(elm?.id) : false;

          return {
              profileImage: elm?.userProfile?.profileImage?.filePath,
              name: elm?.userProfile?.name,
              id: elm?.id,
              flag: flag
          };
      });
        setAthleteList(prevData=>([...prevData, ...newAthleteList]))  
        setLoading(false)
       if(page <= resp?.data?.responseData?.totalPages){
        searchInput?.length > 0 ?setFilteredPagination(prevData=>(prevData+1)) : setPage(prevData=>(prevData+1))
       }
  } catch ({ response, request }) {
    setLoading(false)
      if (response) {
        
          toast.error(response?.data?.message);
      } else if (request) {
          toast.error('No Internet');
      }


  }
  }
  else{
    let params = {}
     setLoading(true)
    if(searchInput?.length>0){
       params = {
        role: "athlete",
        isRegisteredUser: 1,
        page:filteredPagination,
        perPage:10,
       searchText: searchInput
      };
    }else{
      params = {
        role: "athlete",
        isRegisteredUser: 1,
        page:page,
        perPage:10,
      };
    }
  
    const resp = await getAthleteListingData(params);
        const newAthleteList = resp?.data?.responseData?.data?.map((elm) => ({
          profileImage: elm?.userProfile?.profileImage?.filePath,
          name: elm?.userProfile?.name,
          id: elm?.id
        }));
        if(page <= resp?.data?.responseData?.totalPages){
          searchInput?.length > 0 ?setFilteredPagination(prevData=>(prevData+1))  :setPage(prevData=>(prevData+1))
        }
       if(newAthleteList?.length > 0){
        setAthleteList(prevData=>([...prevData, ...newAthleteList]))
       }
        setLoading(false)
  }
 
}
  useEffect(() => {

    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          handleObserver(page)
        }
      },
      { threshold: 1 }
    );
  
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
  
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, page]);

useEffect(()=>{
  if(searchInput?.length > 0){
  if(section.length > 0 && section === 'addGames'){
    const params = {
      page:1,
      perPage: 20,
      isRegisteredUser: 1,
      sportId: sportId ? sportId : sportId?.value,
      universityId: universityId ? universityId : universitySelectore?.value,
      role: "athlete",
      searchText:searchInput
  };
  const gamesAthleteData = getMoreGamesAthleteList(params)
  }else{
  const params = {
    role: "athlete",
    isRegisteredUser: 1,
    page:1,
    perPage:10,
    searchText:searchInput
  };
 const eventAthleteData = getMoreEventAthleteList(params)
}
  }
  setFilteredPagination(1)
},[searchInput])

useEffect(()=>{
  if(section === 'addGames'){
const totlaSelectedAthlete = options?.length > 0 && options?.filter((item)=> selectedAthleteIds?.includes(item?.id)).map((data)=> data?.id)
setSelectedAthletes(totlaSelectedAthlete)
  }
},[])



  return (
    <>
      <div className="inline-field-wrpr">
        <div className={`form-in ${error ? "required-field" : ""}`}>
          <div className="position-relative d-flex align-items-center search_input  mt-2">
            <span className="search-icon">
              <img src="/assets/svg/search-normal.svg" alt="" />
            </span>
            <input
              className="form-control form-control-lg w-100 p-0  fw-600 h4_1"
              type="text"
              placeholder="Search athlete..."
              value={searchInput}
              onChange={(e) => {
                console.log("value++++", e.target.value)
                if(e.target.value == ''){
                  setFilteredAthlete([])
                }
                setSearchInput(e.target.value)
              }}
            />
          </div>

          <ul className="ps-0 pt-3 select-dropdown-style">
            {/* <div className="athleteViewBox">
            {filteredAthleteList?.map((_option, index) => (
              <li key={index} className="my-2 checkbox-li">
                <div
                  className="d-flex justify-content-evenly align-items-center"
                  key={index}
                  onClick={() => onInputChange(_option.id)} // Make the entire card clickable
                >
                  <img
                    width="50px"
                    height="50px"
                    src={_option.profileImage || "/assets/images/user-default-image.webp"}
                    alt=""
                    className="rounded-circle"
                  />
                  <span className="">{_option?.name}</span>
                  <label
                    className="form-check-label style-checkbox"
                    onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the outer click
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedIds?.includes(_option.id)}
                      onChange={() => onInputChange(_option.id)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </li>
            ))}
          </div> */}
            <div className="athleteViewBox">
              {searchInput.length > 0 ? (filteredAthlete?.length > 0 ?(
                filteredAthlete?.map((_option, index)=>(
                  <li key={index} className="my-2 checkbox-li">
                  <div
                    className={`d-flex justify-content-between align-items-center ${_option?.flag ? 'disabled gray' : ''} ${classname ? 'gap-2' : ''}`}
                    key={index}
                    onClick={() => _option?.flag ? null : onInputChange(_option.id)} // Disable click if flag is true
                  >
                    <img
                      width="50px"
                      height="50px"
                      src={_option.profileImage || "/assets/images/user-default-image.webp"}
                      alt=""
                      className="rounded-circle"
                    />
                    <span className={`${classname}` ? "select-card-text" : ''}>{_option?.name}</span>
                    <label
                      className="form-check-label style-checkbox"
                      onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the outer click
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedIds?.includes(_option.id)}
                        onChange={() => _option?.flag ? null : onInputChange(_option.id)} // Disable checkbox if flag is true
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li>
                ))
              ): 'no records available'):
              (options?.map((_option, index) => (
                <li key={index} className="my-2 checkbox-li">
                  <div
                    className={`d-flex justify-content-between align-items-center ${_option?.flag ? 'disabled gray' : ''} ${classname ? 'gap-2' : ''}`}
                    key={index}
                    onClick={() => _option?.flag ? null : onInputChange(_option.id)} // Disable click if flag is true
                  >
                    <img
                      width="50px"
                      height="50px"
                      src={_option.profileImage || "/assets/images/user-default-image.webp"}
                      alt=""
                      className="rounded-circle"
                    />
                    <span className={`${classname}` ? "select-card-text" : ''}>{_option?.name}</span>
                    <label
                      className="form-check-label style-checkbox"
                      onClick={(e) => e.stopPropagation()} // Prevent checkbox click from triggering the outer click
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedIds?.includes(_option.id)}
                        onChange={() => _option?.flag ? null : onInputChange(_option.id)} // Disable checkbox if flag is true
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </li>
              )))}
              {/* {error && <p>Error: {error.message}</p>} */}
             <div ref={observerTarget}></div>
            </div>
          </ul>
          {loading && <p>Loading...</p>}
        </div>

      </div>
      {errorType?.map((type) =>
        error?.type === type && error?.message !== "" ? (
          <p key={type} className={"error-msg error-multi-selector"}>
            {error?.message}
          </p>
        ) : null
      )}
    </>
  );
}

EventMultiSelectCheckbox.defaultProps = {
  defaultValue: [],
  rules: {},
  errorClass: "error",
  onChange: (value) => value,
  optionLabel: "name",
  optionValue: "id",
};

export default EventMultiSelectCheckbox;

