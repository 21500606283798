import React, { useState, useRef } from 'react';
import { updateEventStatus } from '../../actions/events';
import toast from 'react-hot-toast';
import { _ } from '../../locale';
function MessageModal({showMsgModel, setShowMsgModel, eventId,fetchData, activePage}) {
 const [reason, setReason] = useState('')
const handleCancelText = () =>{
  setShowMsgModel(prevState=>({...prevState, showCancelMsgModel:false, showRejectMsgModel:false}))
  setReason('')
}

const handleEventProofMessage = async(eventId) =>{
  let obj;
    try {
      obj = {
        status : showMsgModel?.showRejectMsgModel ? 6 : 8,
        message : reason
      }
      setShowMsgModel(prevState=>({...prevState, showRejectMsgModel:false, showCancelMsgModel:false}))
  
      await updateEventStatus(eventId, obj)
        fetchData(activePage);
    } catch ({request,response}) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
       toast.error(_('Something went wrong, please try again later'));
      }
    }
}

const handleMessageChange = (e)=>{
  let value = e.target.value
    setReason(value)
}

 if (!showMsgModel?.showCancelMsgModel && !showMsgModel?.showRejectMsgModel) return null;

return (
   <div className="model-overlay">
     <div className="file-upload-popup">
       <h2>Add Reason</h2>
       <textarea className="form-control" rows="3" onChange={handleMessageChange} placeholder={`Please enter reason for ${showMsgModel?.showRejectMsgModel ? 'rejection' : 'cancellation' }`}></textarea>
        <div className="file-upload-button-container">
        <button onClick={()=>handleEventProofMessage(eventId)}>Save</button>
       <button onClick={handleCancelText}>Cancel</button>
        </div>  
        </div>
   </div>
);

}

export default MessageModal