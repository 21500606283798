import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ReactTableSkelton = ({ count }) => {
  const items = new Array(count).fill(0);
  return (
    <>
      {items.map((item, index) => (
        <tr>
          <td className="text-center">
            <Skeleton width={75} height={15} />
          </td>
          <td className="text-center">
            <Skeleton width={75} height={15} />
          </td>
        </tr>
      ))}
    </>
  );
};

export default ReactTableSkelton;
