import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StaffSkeleton = ({ count }) => {
  const items = new Array(count).fill(0);
  return (
    <>
      {items.map((item, index) => (
        <tr>
          <td>
            <p className="font-size-14 mb-1">
              <Skeleton width={15} height={15} />
            </p>
          </td>

          <td>
            <Skeleton width={75} height={15} />
          </td>
          <td>
            <Skeleton width={75} height={15} />
          </td>
          <td>
            <Skeleton width={75} height={15} />
          </td>
          <td>
            <Skeleton width={75} height={15} />
          </td>
          <td>
            <Skeleton width={75} height={15} />
          </td>

          <td>
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <a
                  className="btn btn-sm btn-soft-info"
                  href="javascript:void(0)"
                >
                  <Skeleton width={15} height={15} />
                </a>
              </li>
              {/* <li>
                <a
                  className="btn btn-sm btn-soft-info"
                  href="javascript:void(0)"
                >
                  <Skeleton width={15} height={15} />
                </a>
              </li> */}
              <li>
                <a
                  className="btn btn-sm btn-soft-info"
                  href="javascript:void(0)"
                >
                  <Skeleton width={50} height={15} />
                </a>
              </li>
            </ul>
          </td>
        </tr>
      ))}
    </>
  );
};

export default StaffSkeleton;
