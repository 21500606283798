import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Inputfield from "../../components/FormFields/InputField";
import { _ } from "../../locale";
import NewPhoneInputField from "../../components/FormFields/NewPhoneInputFiled";
import PasswordInputfield from "../../components/FormFields/PasswordInputFiled";
import Spinner from "react-bootstrap/esm/Spinner";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import { getRolesList } from "../../actions/roles";
import toast from "react-hot-toast";
import { addNewStaff, getStaffById } from "../../actions/staff";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatPhone } from "../../constants";

const AddStaff = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;
  const [roleListing, setRoleListing] = useState([]);
  const [getPassword, setGetPassword] = useState(true);

  const showPassword = () => {
    setGetPassword(!getPassword);
  };
  const onSubmit = async (values) => {
    values.countryCode = "+1";
    values = Object.assign(values, {
      mobile: formatPhone(values?.mobile),
    });
    if (id) {
      values = Object.assign(values, { id: id });
    }
    try {
      await addNewStaff(values);
      navigate("/dashboard/staff");
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.message);
      } else if (error) {
        toast.error("You do not have internet connection");
      }
      console.log(error, "error");
    }
  };
  const fetchRolesData = async (page) => {
    try {
      const params = {
        page,
        perPage: 50,
      };
      const resp = await getRolesList(params);
      const newArray = resp?.data?.responseData?.data
        ?.filter((item) => item.isDefault === false)
        .map((item) => ({
          label: `${item?.name}`,
          value: item?.code,
          isDefault: item?.isDefault,
        }));
      setRoleListing(newArray);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };

  const fetchSingalRecord = async () => {
    try {
      const res = await getStaffById(id);
      const data = res?.data?.responseData;
      let roleList = [];
      if (data?.Roles?.length) {
        data?.Roles &&
          data?.Roles?.map((item, index) => {
            roleList.push(item?.code);
          });
      }
      setValue("name", data?.userProfile?.name);
      setValue("email", data?.email);
      setValue("universityId", data?.userProfile?.university?.id);
      setValue("mobile", data?.mobile);
      setValue("roles", roleList);
    } catch (error) {}
  };
  useEffect(() => {
    fetchRolesData();
    if (id) {
      fetchSingalRecord();
    }
  }, []);
  console.log(roleListing, "llll");
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center">
              <h3>
                {id !== null && id !== undefined
                  ? _("Edit Staff")
                  : _("ADD_NEW_STAFF")}
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-5 mb-3">
                      <label className="form-label">{_("NAME") + "*"}</label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_("E.g. John Smith")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("Enter name"),
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-5 mb-3">
                      <label className="form-label">{_("EMAIL") + "*"}</label>
                      <Inputfield
                        control={control}
                        name={"email"}
                        placeholder={_("E.g. example@gmail.com")}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="email"
                        inputClassName={"form-control"}
                        rules={{
                          required: {
                            value: true,
                            message: _("ENTER_EMAIL"),
                          },
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                            message: _("INVALID_EMAIL_FORMAT"),
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-5 ">
                      <label className="form-label">
                        {_("Phone Number") + "*"}
                      </label>

                      <div className="d-flex">
                        <div>
                          <Inputfield
                            control={control}
                            name={"countryCode"}
                            placeholder={_("+1")}
                            normalize={(e) => e.replace(/^\s+/g, "")}
                            type="text"
                            disabled={true}
                            value={"+1"}
                            inputClassName={"form-control country-code-style"}
                            rules={{
                              required: {
                                value: false,
                                message: _("Country code is required"),
                              },
                            }}
                          />
                          <div>
                            <img
                              className="country-code-icon-stle"
                              src="/assets/images/usicon.webp"
                            />
                          </div>
                        </div>
                        <NewPhoneInputField
                          control={control}
                          // labelText={_("mobileNumber")}
                          name="mobile"
                          inputType="text"
                          placeholder={_("E.g. (123) 234-1234")}
                          formInWidth="w-100"
                          maxLength="14"
                          rules={{
                            required: {
                              value: true,
                              message: _("Enter phone number"),
                            },
                          }}
                        />
                      </div>
                    </div>
                    {id ? (
                      ""
                    ) : (
                      <div className="col-md-5 mb-3">
                        <label className="form-label">{_("PASSWORD")}</label>
                        <div className="input-group auth-pass-inputgroup">
                          <PasswordInputfield
                            control={control}
                            name={"password"}
                            label={_("PASSWORD")}
                            placeholder={_("Enter password")}
                            normalize={(e) => e.replace(/^\s+/g, "")}
                            type={getPassword === true ? "password" : "input"}
                            inputClassName={"form-control"}
                            showPassword={showPassword}
                            rules={{
                              required: {
                                value: true,
                                message: _("PASSWORD_REQUIRED"),
                              },
                              minLength: {
                                value: 4,
                                message: _("PASSWORD_VALIDATE"),
                              },
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-md-5 mb-3">
                      <label className="form-label">{"Role(s)*"}</label>
                      <div className="mb-3">
                        <ReactSelectField
                          control={control}
                          name="roles"
                          label={_("ROLE")}
                          placeholder={"Select Role"}
                          options={roleListing}
                          optionValue="value"
                          optionLabel="label"
                          multi="true"
                          maxHeight={"150px"}
                          rules={{
                            required: {
                              value: true,
                              message: _("Enter role(s)"),
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap gap-2 mt-1 mb-5">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn newBtn btn-dark  waves-light ${
                        isSubmitting && "btn-loader"
                      }`}
                    >
                      {isSubmitting && (
                        <Spinner animation="border spiner-border-style" />
                      )}
                      {id ? (
                        <span>{_("UPDATE")}</span>
                      ) : (
                        <span>{_("ADD_STAFF")}</span>
                      )}
                    </button>
                    <Link to={"/dashboard/staff"} className="btn btn-secondary">
                      {_("CANCEL")}
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
