import React, { useRef, useState } from "react";
import { _ } from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import PageHeading from "../../components/common/PageHeading";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPotentialMerchantList } from "../../actions/potentialMerchants";
import { useEffect } from "react";
import PotentialMerchentSlide from "./PotentialMerchantSllide";
import { limit } from "../../constants";
import ResponsivePagination from "react-responsive-pagination";
import PotentialMerchantSkelton from "./PotentialMerchantSkeleton";
import PotentialMerchantViewModal from "../../components/Modal/PotentialMerchantViewModal";

const ManagePotentialMerchant = () => {
  const [list, setList] = useState([]);
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  const { control, setValue, handleSubmit } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  let search = searchParams.get("searchText");
  const initalFetchData = useRef(true);
  const navigate = useNavigate();

  const fetchRecord = async (page, filters) => {
    try {
      const params = {
        page,
        perPage: 20,
        ...filters, // Include filters in the request parameters
      };
      const res = await getPotentialMerchantList(params);
      const resp = res?.data?.responseData;
      initalFetchData.current = false;
      setList(resp);
    } catch (error) {
      console.log(error);
      initalFetchData.current = false;
    }
  };

  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };
  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };
  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  const handlePageChange = (page) => {
    setActivePage(page);
  };
  const modalProperty = (id) => {
    setId(id);
    setShow(true);
  };
  const modalCloseHandler = () => {
    setShow(false);
    navigate("/dashboard/potential");
  };

  console.log(list, "sss");
  useEffect(() => {
    fetchRecord(activePage, filtersData);
  }, [activePage, filtersData]);
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading
                heading={"POTENTIAL_MERCHANT"}
                count={list?.totalRecords || 0}
              />
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by merchant name...")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>
            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover table-responsive">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("MERCHANT_ID")}</th>
                        <th scope="col">{_("MERCHANT_NAME")}</th>
                        <th scope="col">{_("TRANSACTION_COUNT")}</th>
                        <th scope="col">{_("CREATED_DATE")}</th>
                        <th scope="col">{_("ACTIONS")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ? (
                        <PotentialMerchantSkelton count={8} />
                      ) : list && list?.data && list?.data?.length > 0 ? (
                        list &&
                        list?.data &&
                        list?.data?.length > 0 &&
                        list?.data?.map((obj, index) => (
                          <PotentialMerchentSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchRecord={fetchRecord}
                            activePage={activePage}
                            filtersData={filtersData}
                            show={show}
                            setShow={setShow}
                            modalProperty={modalProperty}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {list && list?.totalRecords > 20 && (
                <ResponsivePagination
                  current={activePage}
                  total={list?.totalPages ?? 1}
                  onPageChange={handlePageChange}
                  maxWidth={20}
                  totalItemsCount={list?.totalRecords ?? 1}
                  itemsCountPerPage={20}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <PotentialMerchantViewModal
          show={show}
          onHide={modalCloseHandler}
          data={list?.data?.filter((elm) => elm.id === id)}
          id={id}
        />
      )}
    </div>
  );
};

export default ManagePotentialMerchant;
