import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { _ } from "../../locale";
import Inputfield from "../FormFields/InputField";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useEffect, useRef, useState } from "react"
import ImageUploaderField from "../FormFields/ImageUploderFiled";
import AutoCompleteField from "../../actions/AutoComplete";
import ReactDatePicker from "../FormFields/ReactDatePicker";
import ReactSelectField from "../FormFields/ReactSelectField";
import { getAthleteListingData, userListing } from "../../actions/athlete";
import InputTextAreaField from "../FormFields/InputTextAreaField";
import moment from "moment";
import AthleteViewDropdown from "../../pages/Athlete/AthleteViewDropdown";
import { createEvent, getSingalEvent, updateEvent } from "../../actions/events";
import InputNumberField from "../FormFields/InputNumberFiled";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { isSameDay } from "date-fns";
import MultiSelectCheckboxField from "../FormFields/MultiSelectCheckboxField";
import EventMultiSelectCheckbox from "../FormFields/EventMultiSelectCheckbox";
import { useCallback } from "react";
const minDate = moment().add(48, 'hours').toDate();
function EventAddEditModal({ fetchData, show, onHide, id, setActivePage , activePage, filtersData}) {

	// console.log(fetchData, onHide, "dsjhbcsdhjcs")
	const {
		control,
		handleSubmit,
		setValue,
		reset,
		setError
		// formState: { isSubmitting },
	} = useForm({
	});
	const [defaultValue, setDefaultValue] = useState()
	const [defaultLatLng, setDefaultLatLng] = useState()
	const kGoogleKey = 'AIzaSyBj2sDQqTe7MWv6F71KfmfUsh3LyaXhxdg';
	const [athleteList, setAthleteList] = useState([])
	const [athlete1, setAthlete1] = useState()
	const [athleteId1, setAthleteId1] = useState()
	const [isSubmitting, setIsSubmitting] = useState()
	const [merchantId, setMerchantId] = useState()
	const [merchantList, setMerchantList] = useState()
	const [startTime, setStartTime] = useState();
	const [selectedAthlete, setSelectedAthlete] = useState(0)
	const [startDates, setStartDates] = useState(null);
	const [endDates, setEndDates] = useState(null);
	const [options, setOptions] = useState([]);
    const [locationData, setLocationData] = useState([])

	const onSubmit = async (formData) => {
		const payload = {
			...formData,
			attachmentId: formData?.attachmentId?.id,
			mapAddress: formData?.location?.label ? formData?.location?.label : defaultValue,
			latitude: defaultLatLng?.lat ? defaultLatLng?.lat?.toString() : formData?.location?.latitude?.toString(),
			longitude: defaultLatLng?.lng ? defaultLatLng?.lng?.toString() : formData?.location?.longitude?.toString(),
			startDate: moment(formData?.startDate).toISOString(),
			endDate: moment(formData?.endDate).toISOString(),
			amount: formData?.amount.toString()
		}


		delete payload?.location;
		delete payload?.imageId
		setIsSubmitting(true)
		try {
			let resp;
			if (id) {
				resp = await updateEvent(id, payload);
			} else {
				resp = await createEvent(payload);
			}

			if (resp?.status === 200) {
				console.log("hiiiiii")
				onHide();
				// fetchData(1);
				fetchData(activePage, filtersData)
				setActivePage(1)
			}
			setIsSubmitting(false)
		} catch ({ response, request }) {
			if (response) {
				toast.error(response?.data?.message);
			} else if (request) {
				toast.error("You do not have internet connection");
			}
			setIsSubmitting(false)
		}
	};

	const fetchMerchantData = async (page, filters) => {

		try {
			const params = {
				role: "merchant"
			};
			const resp = await userListing(params);
			console.log(resp, "dsjhbsd")
			setLocationData(resp?.data?.responseData)
			const location = resp?.data?.responseData?.filter((elm) => {
				return elm?.id === merchantId?.value;
			});
			setDefaultValue(location?.[0]?.address?.[0]?.mapAddress)
			const locationDefaultValue = {
				lat: location?.[0]?.address?.[0]?.latitude,
				lng: location?.[0]?.address?.[0]?.longitude,
			};
			console.log(locationDefaultValue, location, "sdchjbsd")

			setDefaultLatLng(locationDefaultValue)

			const merchantArray = resp?.data?.responseData?.map((elm) => {
				return {
					label: elm?.userProfile?.name,
					value: elm?.userProfile?.id
				};
			});

			console.log(merchantArray, "Vsdhb")

			setMerchantList(merchantArray)

		} catch ({ response, request }) {
			if (response) {
				toast.error(response?.data?.message);
			} else if (request) {
				toast.error(_('NO_INTERNET'));
			}

		}
	};


	const fetchAthleteData = async (currPage) => {
		try {
			// const params = {
			// 	role: "athlete"
			// };
			// const resp = await userListing(params);
			// console.log(resp, "sdvjhbsd")
			// const athleteList = resp?.data?.responseData?.map((elm) => ({
			// 	profileImage: elm?.userProfile?.profileImage?.filePath,
			// 	name: elm?.userProfile?.name,
			// 	id: elm?.id
			// }));
			// setAthleteList(athleteList)
			const params = {
				role: "athlete",
				isRegisteredUser:1,
				page:currPage,
				perPage:10,
			};
			const resp = await getAthleteListingData(params);
			console.log(resp, "sdvjhbsd")
			const newAthleteList = resp?.data?.responseData?.data?.map((elm) => ({
				profileImage: elm?.userProfile?.profileImage?.filePath,
				name: elm?.userProfile?.name,
				id: elm?.id
			}));
			setAthleteList([...athleteList, ...newAthleteList])

		} catch ({ response, request }) {
			if (response) {
				toast.error(response?.data?.message);
			} else if (request) {
				toast.error(_('NO_INTERNET'));
			}

		}
	};

	const fetchSingalData = async (id) => {
		try {
			const resp = await getSingalEvent(id);
			console.log(resp?.data?.responseData, "dcjhbsd")
			const data = resp?.data?.responseData
			setValue("name", data?.name)
			const locationDefaultValue = {
				lat: data?.latitude,
				lng: data?.longitude,
			};

			setDefaultLatLng(locationDefaultValue)
		    setDefaultValue(data?.mapAddress);

			setAthleteId1(data?.athlete)

			reset({
				"title": data?.title,
				"startDate": data?.startDate ? new Date(data?.startDate) : "-",
				"endDate": data?.endDate ? new Date(data?.endDate) : "-",
				"sportsId": data?.sportsId,
				"description": data?.description,
				"amount": data?.amount,
				"attachmentId": data?.attachment,
				"merchantId": data?.merchantId
			})
		} catch ({ response, request }) {
			if (response) {
				toast.error(response?.data?.message);
			} else if (request) {
				toast.error(_('NO_INTERNET'));
			}
		}
	};

	let isToday = moment(startTime).isSame(moment(), "day");

	const calculateMinTime = (date, isStartTime) => {
		let isToday = moment(date).isSame(moment(), "day");
		if (isToday) {
			return isStartTime ? moment().toDate() : setMinutes(moment().toDate(), 10);
		}
		return moment().startOf("day").toDate();
	};

	const handleChange = (value) => {
		setStartTime(value);
		const minTime = isToday ? calculateMinTime(value, true) : moment().startOf("day").toDate();
		console.log(minTime, "schjbsjc");
	};


	useEffect(() => {
		if (id ) {
			fetchSingalData(id)
		}
	}, [id, locationData])

	useEffect(() => {
		fetchAthleteData(1)
		fetchMerchantData()
	}, [])

	return (
		<>
			<Modal
				size="lg"
				show={show}
				onHide={onHide}
				aria-labelledby="contained-modal-title-vcenter"
				className="right-modal followup-modal max-with-560 addLeadModal"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">{(id !== null && id !== undefined) ? _("EDIT_EVENT") : _("ADD_EVENT")}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="container-fluid">
						<div className="card">
							<div className="card-body">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="col-md-12">
										<div className="mb-3">
											<label className="form-label">{_("TITLE") + '*'}</label>
											<Inputfield
												control={control}
												name={"title"}
												placeholder={_("E.g. Sign autograph..")}
												normalize={(e) => e.replace(/^\s+/g, "")}
												type="text"
												inputClassName={"form-control"}
												rules={{
													required: {
														value: true,
														message: _("Enter title"),
													},
												}}
											/>
										</div>
									</div>
									<div className="mb-3">
										<label className="form-label">{_("SELECT_MERCHANT")}</label>
										<ReactSelectField
											control={control}
											name="merchantId"
											label={_("ROLE")}
											placeholder={_("SELECT_MERCHANT")}
											options={merchantList}
											optionValue="value"
											optionLabel="label"
											onChange={(selectedValue) => {
												setMerchantId(selectedValue)
												const location = locationData?.filter((elm) => {
													return elm?.id === selectedValue?.value;
												});
												setDefaultValue(location?.[0]?.address?.[0]?.mapAddress)
													const locationDefaultValue = {
														lat: location?.[0]?.address?.[0]?.latitude,
														lng: location?.[0]?.address?.[0]?.longitude,
													};
													setDefaultLatLng(locationDefaultValue)
											}}
											rules={{
												required: {
													value: false,
													message: _("Select merchant"),
												},
											}}
											section='add merchant'
											maxHeight={"250px"}
										/>
									</div>

									<div className="new-class">
										<div className="mb-3">
											<label className="form-label">{_("EVENT_LOCATION") + '*'}</label>
											{
												// ((defaultValue && id) || (merchantId && defaultValue)) && (
													<AutoCompleteField
														key={defaultValue}
														control={control}
														name="location"
														placeholder="Enter location"
														apiKey={kGoogleKey}
														defaultValue={defaultValue}
													/>
												// )
											}

											{/* {
												(defaultValue === undefined && id === undefined) && <AutoCompleteField
													control={control}
													name="location"
													placeholder="Enter location"
													apiKey={kGoogleKey}
												/>
											} */}

										</div>
									</div>


									{/* <div className="d-flex w-100 justify-content-between gap-3">


										<div className="mb-3 w-50">
											<ReactDatePicker
												control={control}
												name="startDate"
												label="Start Date & Time"
												inputClassName={"form-control"}
												errorClass="Your Error Class"
												dateFormat="MM/DD/YYYY"
												rules={{
													required: {
														value: true,
														message: "Enter start date & time",
													},
												}}
												minDate={new Date()}
												// maxDate={endDates ?? null}
												onChangeDate={(selectedDate) => {
													setStartDates(selectedDate);
													handleChange(selectedDate);
												}}
												// maxTime={
												// 	startDates &&
												// 		isSameDay(startDates, endDates)
												// 		? setMinutes(endDates, endDates.getMinutes() - 1)
												// 		: setHours(setMinutes(new Date(), 45), 23)
												// }
												// minTime={
												// 	isToday
												// 		? new Date()
												// 		: moment().startOf("day").toDate()
												// }
											/>
										</div>
										<div className="mb-3 w-50">
											<ReactDatePicker
												control={control}
												name="endDate"
												label="End Date & Time"
												inputClassName={"form-control"}
												errorClass="Your Error Class"
												dateFormat="MM/DD/YYYY"
												rules={{
													required: {
														value: true,
														message: "Enter end date & time",
													},
												}}
												onChangeDate={(val) => {
													setEndDates(val);
													handleChange(val);
												}}
												selectedEndDate={endDates}
												minDate={startDates ? startDates : new Date()}
												// minTime={
												// 	startDates
												// 		? setMinutes(startDates, startDates.getMinutes() + 15)
												// 		: isToday
												// 			? setMinutes(new Date(), 15)
												// 			: moment().startOf("day").toDate()
												// }
												// maxTime={setHours(setMinutes(new Date(), 45), 23)}
											/>
										</div>

									</div> */}

									<div className="d-flex w-100 justify-content-between gap-3">
										<div className="mb-3 w-50">
											<ReactDatePicker
												control={control}
												name="startDate"
												label="Start Date & Time*"
												onChangeDate={(selectedDate) => {
													const selectedMoment = moment(selectedDate);
                                                    const minDateTime = moment().add(48, 'hours');
													if (selectedMoment.isBefore(minDateTime)) {
														setError("startDate", {
															type: "manual",
															message: "Start time should be 48 hours after current time",
														});
													} else {
														setError("startDate", null);
														setStartDates(selectedMoment);
														handleChange(selectedDate);
													}
												}}
												rules={{
													required: {
														value: true,
														message: "Enter start date & time",
													},
													validate: (value) => {
														// Custom validation rule to check if rules are met
														const selectedMoment = moment(value);
														if (selectedMoment.isSameOrBefore(moment())) {
															return "Start date & time should be 48 hours after current time";
														}
														return true;
													},
												}}
												minDate={new Date()}
											/>
                                             {/* <ReactDatePicker
												control={control}
												name="startDate"
												label="Start Date & Time"
												onChangeDate={(selectedDate) => {
													const selectedMoment = moment(selectedDate);

													console.log("selectedMoment+++++", selectedMoment)
													setStartDates(moment(selectedDate))
												}}
												// select={minDate}
												minDate={minDate}
												 selected={minDate}
											/>  */}

										</div>
										<div className="mb-3 w-50">
											<ReactDatePicker
												control={control}
												name="endDate"
												label="End Date & Time*"
												onChangeDate={(val) => {
													setEndDates(moment(val));
													handleChange(val);
												}}
												selectedEndDate={endDates}
												rules={{
													required: {
														value: true,
														message: "Enter end date & time",
													},
													validate: (value) => {
														// Custom validation rule to check if rules are met
														const selectedMoment = moment(value);
														if (selectedMoment.isSameOrBefore(startDates) ||
															(selectedMoment.isSame(startDates, "day") &&
																selectedMoment.format("HH:mm") === startDates.format("HH:mm"))) {
															return "End date & time should be after start date & time";
														}
														return true;
													},
												}}
												minDate={startDates ? startDates.toDate() : new Date()}
												customError={
													startDates &&
													endDates &&
													(endDates.isSameOrBefore(startDates) ||
														(endDates.isSame(startDates, "day") &&
															endDates.format("HH:mm") === startDates.format("HH:mm"))) &&
													"End date & time should be after start date & time"
												}
											/>

										</div>
									</div>

									<div className="">
										<label className="form-label">{_("DESCRIPTION") + '*'}</label>
										<div className="mb-3">
											<InputTextAreaField
												control={control}
												name="description"
												label={_("example 1234")}
												fieldClass={"form-control"}
												rules={{
													required: {
														value: true,
														message: _("Enter description"),
													},
												}}

											/>
										</div>
									</div>
									{
										id ? null : <div className="">
											<div className="d-flex justify-content-between">
											<label className="form-label">{_("SELECT_ATHLETE") + '(s)*'}</label><label className="ml-3-style">Total ({selectedAthlete?.length || 0})</label>
											</div>
											<div className="mb-3 athlete-select-style">
												{/* <MultiSelectCheckboxField
													control={control}
													name={`athletes`}
													label={"Message type"}
													placeholder="Message Type"
													options={athleteList}
													optionValue="value"
													optionLabel="label"
													// selectedAthleteIds={athleteId1}
													onChange={(val) => { setSelectedAthlete(val) }}

													rules={{
														required: {
															value: true,
															message: _("Please select atleast 1 athleate"),
														},

													}}
													classname = 'select-multi-athlete-card'	
												/> */}
												<EventMultiSelectCheckbox
													control={control}
													name={`athletes`}
													label={"Message type"}
													placeholder="Message Type"
													options={athleteList}
													optionValue="value"
													optionLabel="label"
													// selectedAthleteIds={athleteId1}
													onChange={(val) => { setSelectedAthlete(val) }}

													rules={{
														required: {
															value: true,
															message: _("Please select atleast 1 athleate"),
														},

													}}
													classname = 'select-multi-athlete-card'	
													fetchData={fetchAthleteData}
													setAthleteList={setAthleteList}
													athleteList={athleteList}
												/>
											</div>


										</div>
									}

									<div className="mb-3">
										<label className="form-label">{_("Amount($)") + '*'}</label>
										<InputNumberField
											control={control}
											name={"amount"}
											placeholder={_("E.g. 20")}
											normalize={(value) => {
												let sanitizedValue = value.replace(/[^\d.]/g, '');
												let parts = sanitizedValue.split('.');
												if (parts.length > 1) {
												  parts[1] = parts[1].slice(0, 2);
												}
												return parts.join('.');
												
											  }}
											type="text"
											inputClassName={"form-control"}
											maxLength={10}
											rules={{
												required: {
													value: true,
													message: _("Enter Amount"),
												},
											}}
										/>
									</div>

									<div className="">
										<div className="mb-3">

											<ImageUploaderField
												control={control}
												label="Image*"
												name="attachmentId"
												uploadType="image"
												setValue={setValue}
												changeLabel={"image"}
												uploadText="Upload image"
												extensionTypes={["jpeg", "jpg", "png"]}
												rules={{
													required: {
														value: true,
														message: "Upload image",
													},
												}}
											/>
										</div>
									</div>

									{
										athleteId1 && <div className="">
											<div className="mb-3">
												<label className="form-label">{_("Athlete")}</label>
												<li className="d-flex align-items-center my-2">
													<img width={"50px"} height={"50px"} src={athleteId1?.userProfile?.profileImage !== null ? athleteId1?.userProfile?.profileImage?.filePath : "/assets/images/user-default-image.webp"} alt={``} className="rounded-circle" />
													<span className=" ms-3">{athleteId1?.userProfile?.name}</span>

												</li>
											</div>

										</div>

									}




									<div className="d-flex flex-wrap gap-2">
										<button
											type="submit"
											disabled={isSubmitting}
											className={`btn newBtn btn-dark  waves-light ${isSubmitting && "btn-loader"
												}`}
										>
											{isSubmitting && <Spinner animation="border spiner-border-style" />}
											{id ? (
												<span>{_("UPDATE")}</span>
											) : (
												<span>{_("ADD_EVENT")}</span>
											)}

										</button>
										<Link onClick={onHide} className="btn btn-secondary waves-effect">
											{_("CANCEL")}

										</Link>
									</div>
								</form>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default EventAddEditModal;
