import { useController } from "react-hook-form";
import Select from "react-select";
import { errorType } from "../../constants";
// import WindowedSelect from "react-windowed-select";
// import { components, createFilter } from "react-windowed-select";
import { useState } from "react";
import { useEffect } from "react";
import { getUniversityListing } from "../../actions/University";
import toast from "react-hot-toast";
import { _ } from "../../locale";
import { getAthleteListing } from "../../actions/athlete";

function ReactSelectWithInfiniteScroll({
  name,
  control,
  rules,
  defaultValue,
  label,
  multi,
  prefixClass,
  containerClass,
  optionValue,
  optionLabel,
  readOnly,
  options,
  onSelect,
  placeholder,
  normalize,
  onChange,
  setSportListing = [],
  setDataListing = [],
  fetchApiData,
  section = "",
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [filterPage, setFilterPage] = useState(1)
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredOptionsAthlete, setFilteredOptionsAthlete] = useState([]);
  const [filteredOptionsMerchant, setFilteredOptionsMerchant] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setShowLoader] = useState(false);

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  // handle value on change
  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    console.log(value, "Sdcsbdhj");
    if (multi) {
      let multiData = value.map((_value) =>
        optionValue !== undefined ? _value[optionValue] : _value?.value
      );
      field.onChange(multiData);
    } else {
      console.log("vvvv", value);

      if (onSelect) {
        if (normalize) onSelect(normalize(value?.value));
        else onSelect(value?.value);
      }

      // send value to hook form
      if (normalize) field.onChange(normalize(value?.value));
      else field.onChange(value?.value);
    }
  };

  // handling value according to selected one
  const handleValue = (value) => {
    console.log(value, "sdchjbdc");

    if (field?.value !== "") {
      if (multi) {
        return options?.filter((c) =>
          field?.value?.includes?.(
            optionValue !== undefined ? c[optionValue] : c.value
          )
        );
      } else {
        return options?.find((c) =>
          optionValue
            ? c[optionValue] === field?.value
            : c.value === field?.value
        );
      }
    } else {
      return "";
    }
  };

  const handleSelect = (val) => {
    alert(val, "Hello");
  };
  const fetchUniversityList = async (page, searchText) => {
    setShowLoader(true);
    try {
      const resp = await getUniversityListing({ page, perPage: 10, searchText });
      const newArray = resp?.data?.responseData?.data.map(({ name, id }) => ({
        label: name,
        value: id,
      }));
      setFilteredOptions(newArray);
      setFilterPage(prevData=> prevData+1)
      setShowLoader(false);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setShowLoader(false);
    }
  };
  const fetchAthleteData = async (page, filters) => {
    try {
      const params = {
        page,
        perPage: 10,
        role: "athlete",
        ...filters,
      };
      const resp = await getAthleteListing(params);
      const athleteArray = resp?.data?.responseData?.data?.map((elm) => {
        return {
          label: elm?.userProfile?.name,
          value: elm?.userProfile?.id,
        };
      });
      setFilteredOptionsAthlete(athleteArray);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };
  const fetchMerchantData = async (page, filters) => {
    try {
      const params = {
        page,
        perPage: 10,
        role: "merchant",
        ...filters,
      };
      const resp = await getAthleteListing(params);
      const merchantArray = resp?.data?.responseData?.data?.map((elm) => {
        return {
          label: elm?.userProfile?.name,
          value: elm?.userProfile?.id,
        };
      });
      console.log(merchantArray, "aaaa");
      setFilteredOptionsMerchant(merchantArray);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }
  };
  const loadMoreOptions = async () => {
    if (isLoading) return;

    setIsLoading(true);
    if(searchText){
      const newOptions = await fetchApiData({ page, perPage: 10, searchText });
      const newArray = newOptions?.data?.responseData?.data.map(
        ({ name, id }) => ({ label: name, value: id })
      );
      setFilteredOptions((prevOptions) => [...prevOptions, ...newArray]);
    }else{
      const newOptions = await fetchApiData({ page, perPage: 10 });
      const newArray = newOptions?.data?.responseData?.data.map(
        ({ name, id }) => ({ label: name, value: id })
      );
      setDataListing((prevOptions) => [...prevOptions, ...newArray]);
    }
    setPage((prevPage) => prevPage + 1);
    setIsLoading(false);
  };
  const customLoader = () => (
    <div style={{ padding: "8px", textAlign: "center" }}>Loading...</div>
  );

  // for react window select
  //   const customFilter = createFilter({ ignoreAccents: false });
  //   const customComponents = {
  //     ClearIndicator: (props) => (
  //       <components.ClearIndicator {...props}>clear</components.ClearIndicator>
  //     ),
  //   };
  const handleInputChange = (inputValue, { action }) => {
    // Log the input value when the user types something
    if (action === "input-change") {
      setSearchText(inputValue);
    }
  };

  useEffect(() => {
    setPage(2);
    const page=1
    if(searchText?.length > 0){
      fetchUniversityList(page,searchText);
    } 
    if(searchText.length > 0){
      fetchAthleteData(page, searchText);
    }
    if(searchText.length > 0){
      fetchMerchantData(page, searchText);
    }
  }, [searchText]);

  return (
    <>
      <Select
        onInputChange={handleInputChange}
        inputRef={field.ref}
        isDisabled={readOnly}
        value={handleValue()}
        onChange={(val) => handleChange(val)}
        onSelect={(val) => console.log(val, "HeuJh")}
        options={searchText?.length > 0 ? filteredOptions : options}
        placeholder={placeholder ? placeholder : label}
        isMulti={multi}
        getOptionLabel={(opt) => opt[optionLabel]}
        getOptionValue={(opt) => opt[optionValue]}
        className={containerClass || ""}
        classNamePrefix={prefixClass || ""}
        isLoading={isLoading}
        onMenuScrollToBottom={loadMoreOptions}
        components={{ LoadingIndicator: customLoader }}
      />
      {/* <WindowedSelect
        components={customComponents}
        filterOption={customFilter}
        inputRef={field.ref}
        isDisabled={readOnly}
        value={handleValue()}
        onChange={(val) => handleChange(val)}
        onSelect={(val) => console.log(val, "HeuJh")}
        options={options}
        placeholder={placeholder ? placeholder : label}
        isMulti={multi}
        getOptionLabel={(opt) => opt[optionLabel]}
        getOptionValue={(opt) => opt[optionValue]}
        className={containerClass || ""}
        classNamePrefix={prefixClass || ""}
        isClearable={true}
      /> */}
      {errorType?.map((type) => {
        if (error?.type === type && error?.message !== "")
          return (
            <span key={type} className="error-msg">
              {error?.message}
            </span>
          );
      })}
    </>
  );
}

export default ReactSelectWithInfiniteScroll;
