import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { _ } from "../../locale";

const RoundUpGalleryModal = ({ show, onHide, detail }) => {
  let gallery = [];
  //   const [loader, setLoader] = useState(true);
  detail &&
    detail.length > 0 &&
    detail.map((obj, index) =>
      gallery.push({ original: obj?.filePath, thumbnail: obj?.filePath })
    );

  //   console.log("77777777", gallery);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="max-with-580 modal-dialog-margin"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <h3>Attachments</h3>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          {/* <h5 className="mb-3">Gallery</h5> */}
          <div className="d-flex gap-3 mb-4">
            <ImageGallery items={gallery} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RoundUpGalleryModal;
