import React, { useEffect, useState } from "react";
import { _ } from "../../locale";
import { useForm } from "react-hook-form";
import moment from "moment";
import ReactDatePicker from "../../components/FormFields/ReactDatePicker";
import AthleteContributionSkeleton from "./ContributionSkeleton";
import { getTransactionByAthleteId } from "../../actions/athlete";
import { useParams, useSearchParams } from "react-router-dom";
import { CONTRIBUTION_TYPE, USER_TYPE, limit } from "../../constants";
import ResponsivePagination from "react-responsive-pagination";
import PageHeading from "../../components/common/PageHeading";
import Skeleton from "react-loading-skeleton";

const AthleteTransactionDetails = () => {
  const params = useParams();
  const id = params?.id;
  const { control, handleSubmit, setError, reset } = useForm();
  const [filtersData, setFiltersData] = useState({});
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(moment(new Date()));
  const [loader, setLoader] = useState(true);
  const [transactionList, setTransactionList] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );

  const onSubmit = (data) => {
    // console.log(data, "ddd");
    setActivePage(1);
    let obj = {};
    if (Object.keys(data)?.length > 0) {
      for (let key in data) {
        if (key === "startDate" || key === "endDate") {
          if (data[key]) {
            obj[`${key}`] =
              key === "startDate"
                ? moment(data[key]).startOf("day").format()
                : moment(data[key]).endOf("day").format();
          }
        }
      }
    }
    console.log("obj+++", obj);
    setFiltersData(obj);
  };

  const constructSearchParams = () => {
    // console.log("working....", filtersData);
    const queryParams = [];
    queryParams.push(`page=${activePage}`);

    if (Object.keys(filtersData)?.length > 0) {
      for (let key in filtersData) {
        if (key === "startDate" || key === "endDate") {
          queryParams.push(`${key}=${filtersData[key]}`);
        }
      }
    }
    const queryString = queryParams.join("&");
    setSearchParams(queryString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);

    // Update state only if there are new filters
    // if (Object.keys(newFilters).length > 0) {
    //   setActivePage(page);
    //   setFiltersData((prevFiltersData) => ({
    //     ...prevFiltersData,
    //     ...newFilters,
    //   }));
    // }
  }, []);

  const handleChange = (value) => {};
  const handlePageChange = (page) => {
    setActivePage(page);
  };
  const handleResetFilter = () => {
    reset();
    setActivePage(1);
    setFiltersData({});
    setStartDates(null);
    setEndDates(null);
  };
  const fetchData = async (page, filters) => {
    try {
      const params = {
        athleteId: id,
        page,
        perPage: 20,
        ...filters,
      };
      const response = await getTransactionByAthleteId(params);
      const data = response?.data?.responseData;
      setTransactionList(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(activePage, filtersData);
    }
  }, [activePage, filtersData]);

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <PageHeading
                  heading={_("Fan / Merchant Transaction Details")}
                />
              </div>
            </div>
            <form name="filter" onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex align-items-center gap-4">
                <div className="d-flex w-50 justify-content-between align-items-center gap-3">
                  <div className="mb-3 w-50">
                    <ReactDatePicker
                      control={control}
                      name="startDate"
                      label="Start Date"
                      onChangeDate={(selectedDate) => {
                        const selectedMoment = moment(selectedDate);
                        setStartDates(selectedMoment);
                        // handleChange(selectedDate);
                      }}
                      minDate={new Date("1900/01/01")}
                      maxDate={endDates ? endDates.toDate() : null}
                      showTimeSelect={false}
                      placeholder="MM DD, YYYY"
                    />
                  </div>
                  -
                  <div className="mb-3 w-50">
                    <ReactDatePicker
                      control={control}
                      name="endDate"
                      label="End Date"
                      onChangeDate={(val) => {
                        setEndDates(moment(val).endOf("day"));
                        handleChange(val);
                      }}
                      selectedEndDate={endDates}
                      rules={{
                        validate: (value) => {
                          const selectedMoment = moment(value);
                          if (
                            selectedMoment.isBefore(startDates) &&
                            !selectedMoment.isSame(startDates, "day")
                          ) {
                            return "End date should be after start date";
                          }

                          return true;
                        },
                      }}
                      minDate={
                        startDates
                          ? startDates.toDate()
                          : new Date("1900/01/01")
                      }
                      showTimeSelect={false}
                      placeholder="MM DD, YYYY"
                    />
                  </div>
                </div>

                <div className="my-4 d-flex align-items-center gap-4 pt-1">
                  <button
                    className="btn newBtn btn-dark applyBtn"
                    type="submit"
                  >
                    Apply Now
                  </button>
                  <button
                    className="btn btn-danger applyBtn resetButton"
                    type="reset"
                    onClick={handleResetFilter}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </form>
            <div className="py-3">
              <h5 className="d-flex">
                Total Amount Contributed:
                <h5 className="ms-4">
                  {loader 
                    ?  <Skeleton width={"50px"} height={"15px"}/>
                    :
                  transactionList?.metaData?.totalAmount
                    ? transactionList?.metaData?.totalAmount
                    : 0}
                </h5>{" "}
              </h5>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive mt-2">
                      <table className="table align-middle table-nowrap table-hover">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">{_("DATE")}</th>
                            <th scope="col">{_("USER_TYPE")}</th>
                            <th scope="col">{_("CONTRUBUTED_BY")}</th>
                            <th scope="col">{_("CONTRIBUTION_VIA")}</th>
                            <th scope="col">{_("TRANSACTION_AMOUNT")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loader ? (
                            <AthleteContributionSkeleton count={5} />
                          ) : transactionList?.data?.length > 0 ? (
                            transactionList?.data?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {parseInt(
                                      (activePage - 1) * limit +
                                        parseInt(index + 1)
                                    )}
                                  </td>
                                  <td>
                                    {data?.createdAt
                                      ? moment(data?.createdAt).format("lll")
                                      : "-"}
                                  </td>
                                  <td>
                                    {USER_TYPE[`${data?.user?.userType}`]}
                                  </td>
                                  <td>{data?.user?.userProfile?.name}</td>
                                  <td>{CONTRIBUTION_TYPE[`${data?.type}`]}</td>
                                  <td>{data?.amount.toFixed(2)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colspan="12" className="fs-5 text-center">
                                No record found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {transactionList && transactionList?.totalRecords > 20 && (
                  <ResponsivePagination
                    current={activePage}
                    total={transactionList?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                    maxWidth={20}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AthleteTransactionDetails;
