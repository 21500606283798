import { useState } from "react";
import { useController } from "react-hook-form";

import { removeFile, uploadFile } from "../../actions/auth";
import { BASE_URL, errorType } from "../../constants";
import Loader from "../common/loader";

function ImageUploaderField({
  control,
  name,
  rules,
  uploadType,
  label,
  uploadText,
  changeLabel,
  setValue,
  extensionTypes,
}) {
  // use hooks
  const {
    field,
    fieldState: { error },
  } = useController({ control, name, rules });

  const [isLoading, setIsLoading] = useState(false);
  const [exntensionError, setExtensionError] = useState(null);

  // on image change
  const onImageChange = async (e) => {
    const formData = new FormData();
    let file = e?.target?.files[0];
    let currentFileExtension = file?.type.split("/")[1];
    formData.append("file", e?.target?.files[0]);
    if (
      extensionTypes?.length > 0 &&
      !extensionTypes.includes(currentFileExtension)
    ) {
      field.onChange("");
      setExtensionError(true);
      return;
    }
    setIsLoading(true);
    setExtensionError(false);

    try {
      const resp = await uploadFile(formData);
      field.onChange(
        resp?.data?.responseData && resp?.data?.responseData
        // resp?.data?.responseData?.length > 0 && resp?.data?.responseData[0]
      );
      setIsLoading(false);
      e.target.value = '';
    } catch ({ request, response }) {
      setIsLoading(false);
      if (response) {
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };

  const onRemoveImage = async () => {
    setIsLoading(true);
    try {
      console.log(field, '44444sssssss', field?.value?.id)
      await removeFile(field?.value?.uniqueName);
      setValue(`${name}`, "");
      setIsLoading(false);
    } catch ({ request, response }) {
      setIsLoading(false);
      if (response) {
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };

  console.log('wwwwwww', field);

  return (
    <>
      <div
        className={`form-in form-group w-100 ${error ? "required-field" : ""}`}
      >
        <label className="label">{label}</label>
        {field?.value?.id ? (
          <div className="edited-fiels w-100 image-uploder">
            {uploadType === "image" ? (
              <div className="upload-image">
                <figure className="img-figure">
                  <img
                    src={`${field?.value?.filePath}`}
                    className="full-width"
                    width={"100%"}
                    height={"100%"}
                  />
                </figure>
                <div className="img-uploaded-btns">
                  <a href="#" className="link fw600 edit-btn">
                    <span className="link-icon dark"> Change{" "}
                    {changeLabel}
                    {uploadType === "image" && <span> (</span>}
                    {`${uploadType === "image" ? extensionTypes : ""}`}
                    {uploadType === "image" && <span>)</span>}
                    </span>

                    {isLoading && <Loader />}

                    <input
                      type="file"
                      placeholder=""
                      className="form-control edit-image"
                      onChange={(e) => {
                        onImageChange(e);
                      }}
                    />
                  </a>
                  <a
                    href="javascript:;"
                    className="link fw600 remove-btn"
                    onClick={() => onRemoveImage()}
                  >
                    Remove
                  </a>
                </div>
              </div>
            ) : (
              <div className="upload-image">
                <div className="document-card">
                  <span className="document-icon">
                    <img
                      src="/assets/images/upload-img.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </span>
                  <span className="document-name text-gray-900">
                    {field?.value?.originalName}
                  </span>
                </div>
                <a href="#" className="link fw600 edit-btn">
                  <span className="link-icon dark">Change {changeLabel}
                  {uploadType === "image" && <span> (</span>}
                  {`${uploadType === "image" ? extensionTypes : ""}`}
                  {uploadType === "image" && <span>)</span>}
                  </span>
                  <input
                    type="file"
                    placeholder=""
                    className="form-control edit-image"
                    onChange={(e) => {
                      onImageChange(e);
                    }}
                  />
                </a>

                <a
                  href="javascript:;"
                  className="link fw600 remove-btn"
                  onClick={() => onRemoveImage()}
                >
                  Remove
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className="f-in w-100 p-0">
            <div className="image-upload mb-1 image-uploder">
              <span className="img-upload">
                <img src="/assets/images/upload-img.svg" />
              </span>
              <span className="img-text">
                {uploadText}
                {uploadType === "image" && <span>(</span>}
                {`${uploadType === "image" ? extensionTypes : ""}`}
                {uploadType === "image" && <span>)</span>}
              </span>
              {/* <span className="img-text">{uploadText}</span> */}

              {isLoading && <Loader />}
              <input
                {...field}
                type="file"
                placeholder=""
                className="form-control"
                onChange={(e) => {
                  onImageChange(e);
                }}
              />
            </div>
          </div>
        )}

        {exntensionError ? (
          <p className="error-msg">
            Invalid file extension. Allowed file extensions are{" "}
            {extensionTypes?.map(
              (_type, index) =>
                `${_type} ${
                  extensionTypes?.length - 1 == index
                    ? ""
                    : extensionTypes?.length - 2 == index
                    ? "and "
                    : ", "
                }`
            )}{" "}
          </p>
        ) : null}
        {errorType?.map((type) => {
          if (error?.type === type && error?.message !== "") {
            return (
              <p key={type} className="error-msg">
                {error?.message}
              </p>
            );
          }
        })}
      </div>
    </>
  );
}

export default ImageUploaderField;