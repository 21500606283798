import React from 'react';
import * as XLSX from 'xlsx';
import { Button, Modal, Table } from "react-bootstrap"
import { _ } from "../../locale";
import { v4 as uuidv4 } from 'uuid';

const ImportErrorModel = ({importModel, setImportModel, fetchData, section})=>{
  const exportToExcel = (data) => {
    let jsonData = []
    const json = data?.length>0 && data?.map((json_data)=>{
        let obj={}
        if(section === 'university'){
          obj['name']= json_data?.data?.A;
                obj['address']=json_data?.data?.B;
                obj['latitude'] = json_data?.data?.C;
                obj['longitude'] = json_data?.data?.D;
                obj['contact name'] = json_data?.data?.E;
                obj['contact email'] = json_data?.data?.F;
                obj['contact phone'] = json_data?.data?.G;
                obj['contact country code'] = json_data?.data?.H;
        }
        if(section === 'team'){
          obj['name']= json_data?.data?.A;
                obj['university name'] = json_data?.data?.B;
                obj['sports'] = json_data?.data?.C;
              
        }
        if(section === 'athlete'){
        if(json_data?.data){    
                obj['name']= json_data?.data?.A;
                obj['email']=json_data?.data?.B;
                obj['university'] = json_data?.data?.C;
                obj['teams'] = json_data?.data?.D;
        }
      }
        obj['message'] = json_data?.message
        jsonData.push(obj)
    })
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(jsonData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `Import${section}Sheet-${uuidv4()}.xlsx`);
  };

  const handleModalClose = () =>{
    setImportModel(prevState=>({...prevState, showImportErrorModel:false, importResponse: []}))
    fetchData(1)
  }

    return(
        <Modal
        show={importModel?.showImportErrorModel}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        className="max-with-580 modal-dialog-margin"
        centered
      >
        <Modal.Header>
          <div className="modal-heade-top">
            <h3>Imports / Athlete</h3>
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <h5 className="mb-3">Athletes</h5>
          <Table striped bordered hover>
            <thead>
              <tr>
               {section === 'university' ? (
                <>
                <th scope="col">{_('ID')}</th>
                <th scope="col">{_('UNIVERSITY_NAME')}</th>
                <th scope="col">{_('MESSAGE')}</th>
                <th scope="col">{_('STATUS')}</th>
                </>
               ): null}
                {section === 'team' ? (
                <>
                <th scope="col">{_('ID')}</th>
                <th scope="col">{_('TEAM_NAME')}</th>
                <th scope="col">{_('MESSAGE')}</th>
                <th scope="col">{_('STATUS')}</th>
                </>
               ): null}
                {section === 'athlete' ? (
                <>
                <th scope="col">{_('ID')}</th>
                <th scope="col">{_('EMAIL')}</th>
                <th scope="col">{_('MESSAGE')}</th>
                <th scope="col">{_('STATUS')}</th>
                </>
               ): null}
              </tr>
            </thead>
            <tbody>
            {importModel?.importResponse?.length >0 && importModel?.importResponse?.map((imp_data, index)=>{
                  console.log("imp_datal123+++", imp_data)
               return(
                <>
                {section === 'university' ? (
                  <tr>
                  <td>{index+1}</td>
                  <td className='gap-1 mw-100'>{imp_data?.data?.A}</td>
                  <td className='gap-1 mw-100'>{imp_data?.message}</td>
                  {imp_data?.status === 400 ?(
                  <td><i class="fa fa-times-circle" aria-hidden="true"></i></td>
                  ):(
                   imp_data?.status === 202 ? (
                     <td><i class="fa fa-info-circle" aria-hidden="true"></i></td>
                   ):(
                  <td><i class="fa fa-check" aria-hidden="true"></i></td>
                  ))}
                </tr>
                ) : null}
                {section === 'team' ? (
                  <tr>
                  <td>{index+1}</td>
                  <td className='gap-1 mw-100'>{imp_data?.data?.A}</td>
                  <td className='gap-1 mw-100'>{imp_data?.message}</td>
                  {imp_data?.status === 400 ?(
                  <td><i class="fa fa-times-circle" aria-hidden="true"></i></td>
                  ):(
                   imp_data?.status === 202 ? (
                     <td><i class="fa fa-info-circle" aria-hidden="true"></i></td>
                   ):(
                  <td><i class="fa fa-check" aria-hidden="true"></i></td>
                  ))}
                </tr>
                ) : null}
                {section === 'athlete' ? (
                  <tr>
                  <td>{index+1}</td>
                  <td className='gap-1 mw-100'>{imp_data?.data?.B}</td>
                  <td className='gap-1 mw-100'>{imp_data?.message}</td>
                  {imp_data?.status === 400 ?(
                  <td><i class="fa fa-times-circle" aria-hidden="true"></i></td>
                  ):(
                   imp_data?.status === 202 ? (
                     <td><i class="fa fa-info-circle" aria-hidden="true"></i></td>
                   ):(
                  <td><i class="fa fa-check" aria-hidden="true"></i></td>
                  ))}
                </tr>
                ) : null}
               </>
               )
            })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={()=> exportToExcel(importModel?.importResponse)}>
            Export Data
          </Button>
        </Modal.Footer>
      </Modal>
    )
}


export default ImportErrorModel;