import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

const ReactTabs = ({ tabs }) => {
  return (
    <Tabs defaultActiveKey={tabs[0].eventKey} id="reusable-tabs" className="border-bottom">
      {tabs.map((tab, index) => (
        <Tab key={index} eventKey={tab.eventKey} title={tab.title}>
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
};

export default ReactTabs;