import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { _ } from "../../locale";
import { limit } from "../../constants";
import { getUniversityListing } from "../../actions/University";
import UniversityAddModal from "../../components/Modal/UniversityAddModal";
import AthleteSlide from "./AthleteSlide";
import AthleteSkelton from "./AthleteSkeleton";
import { getAthleteListing, getSampleImportFile } from "../../actions/athlete";
import Inputfield from "../../components/FormFields/InputField";
import { useForm } from "react-hook-form";
import ResponsivePagination from "react-responsive-pagination";
import AthleteAddEditModal from "../../components/Modal/AthleteAddEditModal";
import AthleteViewModal from "../../components/Modal/AthleteViewModal";
import PageHeading from "../../components/common/PageHeading";
import Pagination from "react-js-pagination";
import FileUploadPopup from "../../components/Modal/FileUploadPopup";
import { uploadFileId } from "../../actions/fileupload";
import ImportErrorModel from "../../components/Modal/ImportErrorModel";
import SuccessModal from "../../components/Modal/SuccessModel";
import { v4 as uuidv4 } from "uuid";
import { DownLoadCSVFile } from "../../actions/common";

function ManageAthlete() {
  // local variables
  const [athleteList, setAthleteList] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showImportCSV, setShowImportCSV] = useState(false);
  const { control, setValue, handleSubmit } = useForm();
  const [filtersData, setFiltersData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activePage, setActivePage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  );
  let search = searchParams.get("searchText");
  const [showAthlete, setShowAthlete] = useState(false);
  const [id, setId] = useState();
  const [importModel, setImportModel] = useState({
    showImportErrorModel: false,
    showSuccessModel: false,
    importResponse: [],
  });
  const { id: teamId } = useParams();
  console.log(activePage, "dsvhbsd");

  const initalFetchData = useRef(true);

  const modalProperty = (id) => {
    setId(id);
    setShowAthlete(true);
  };

  const fetchData = async (page, filters) => {
    
    try {
      const params = {
        page,
        perPage: 20,
        role: "athlete",
        ...filters, // Include filters in the request parameters
      };
      const resp = await getAthleteListing(params);
      setAthleteList(resp?.data?.responseData);
      initalFetchData.current = false;
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      initalFetchData.current = false;
    }
  };
  // console.log(athleteList, "sdcvbsdhjbcjsd");

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  // const handleSetShowUser=()=>{
  //   setShowUserModal(true)
  // }
  const onSubmit = (data) => {
    const filters = {};
    if (data.searchValue !== "") {
      filters.searchText = data.searchValue;
    }
    setActivePage(1);
    setFiltersData(filters);
  };

  const constructSearchParams = () => {
    console.log("working....");
    const queryParams = [];
    queryParams.push(`page=${activePage}`);
    if (filtersData !== null) {
      if (filtersData.searchText) {
        queryParams.push(`searchText=${filtersData?.searchText}`);
      }
    }

    const paramsString = queryParams.join("&");
    console.log(paramsString, "paramsString");
    setSearchParams(paramsString);
  };

  useEffect(() => {
    constructSearchParams();
  }, [activePage, filtersData]);

  useEffect(() => {
    const page = parseInt(searchParams.get("page") || 1);
    const searchText = searchParams.has("searchText")
      ? searchParams.get("searchText")
      : "";

    // Create a new object to accumulate filters without resetting others
    const newFilters = {};

    if (searchText !== "") {
      newFilters.searchText = searchText;
    }

    // Update state only if there are new filters
    if (Object.keys(newFilters).length > 0) {
      setActivePage(page);
      setFiltersData((prevFiltersData) => ({
        ...prevFiltersData,
        ...newFilters,
      }));
    }
  }, []);

  const handleReset = () => {
    console.log("workinggg");
    setActivePage(1);
    setFiltersData(null);
    setValue("searchValue", "");
  };

  const modalCloseHandler = () => {
    setShowAthlete(false);
    // navigate("/dashboard/athlete")
  };

  useEffect(() => {
    setValue("searchValue", search);
  }, [search]);

  useEffect(() => {
    fetchData(activePage, filtersData);
  }, [activePage, filtersData]);

  const handleClosePopup = () => {
    setShowImportCSV(false);
  };
  // file upload on server
  const handleUploadFile = async (fileId) => {
    console.log("Uploading file:", fileId);
    const { data } = await uploadFileId(fileId);
    setShowImportCSV(false);
    const isSuccess =
      data?.responseData?.length > 0 &&
      data?.responseData?.every((res) => res.status === 200);
    if (isSuccess) {
      setImportModel((prevState) => ({
        ...prevState,
        importResponse: [],
        showImportErrorModel: false,
        showSuccessModel: true,
      }));
    } else {
      // setImportModel(prevState=>({...prevState, importResponse: [], showImportErrorModel: false, showSuccessModel: true}))
      setImportModel((prevState) => ({
        ...prevState,
        importResponse: [...data?.responseData],
        showImportErrorModel: true,
        showSuccessModel: false,
      }));
    }
  };

  const handlePopup = (e) => {
    setShowImportCSV((prev) => !prev);
  };
  // download CSVfile functionality
  const handleDownLoadCSV = async () => {

    const Params = {
      type:'athlete'
    }
    const fileUrl = await getSampleImportFile(Params);
    const res = DownLoadCSVFile(fileUrl, 'Athlete')
    // const fileUrl = await getSampleImportFile();

  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <PageHeading
                heading={"ATHLETES"}
                count={athleteList?.totalRecords || 0}
              />
              <div className="flex-shrink-0 g-4">
                {teamId ? (
                  <></>
                ) : (
                  <>
                    <button
                      onClick={handlePopup}
                      className="btn newBtn btn-dark mr-20 "
                    >
                      {_("IMPORT_FILE")}
                    </button>
                    <button
                      onClick={handleDownLoadCSV}
                      className="btn newBtn btn-dark mr-20 "
                    >
                      {_("DOWNLOAD_FILE")}
                    </button>
                  </>
                )}
                <Link
                  onClick={() => setShow(true)}
                  className="btn newBtn btn-dark"
                >
                  {_("ADD_ATHLETE")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="leads-filter">
          <form
            name="filter"
            className="chk-filter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Inputfield
                control={control}
                name={"searchValue"}
                placeholder={_("Search by athlete name,email")}
                normalize={(e) => e.replace(/^\s+/g, "")}
                type="search"
                inputClassName={"form-control input-search"}
                rules={{
                  required: { value: false, message: _("NAME_REQUIRED") },
                }}
              />
            </div>

            <div className="filter-buttn-style">
              <button type="submit" className="btn newBtn btn-dark ">
                Apply Now
              </button>
              <button
                type="button"
                className="btn btn-danger resetButton"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("ATHLETE_NAME")}</th>
                        <th scope="col">{_("EMAIL")}</th>
                        <th scope="col">{_("STATUS")}</th>
                        <th scope="col">{_("FUNDS")}</th>
                        <th scope="col">{_("REGISTRATION_DATE")}</th>
                        <th scope="col">{_("ACTION")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {initalFetchData.current === true ?  (
                        <AthleteSkelton count={8} />
                      ) : athleteList &&
                        athleteList?.data &&
                        athleteList?.data?.length > 0 ? (
                        athleteList &&
                        athleteList?.data &&
                        athleteList?.data?.length > 0 &&
                        athleteList?.data?.map((obj, index) => (
                          <AthleteSlide
                            slideData={obj}
                            key={obj.id}
                            index={index}
                            srno={(activePage - 1) * limit}
                            fetchData={fetchData}
                            modalProperty={modalProperty}
                            activePage={activePage}
                            filtersData={filtersData}
                          />
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colspan="12">
                            <h5 className=" mt-5 noUser-found text-truncate mb-4 mb-lg-5">
                              No Record Found
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pagination">
                {athleteList && athleteList?.totalRecords > 20 && (
                  //   <Pagination
                  //   activePage={activePage}
                  //   itemsCountPerPage={20}
                  //   totalItemsCount={athleteList?.totalRecords ?? 1}
                  //   pageRangeDisplayed={6}
                  //   onChange={handlePageChange}
                  // />
                  <ResponsivePagination
                    current={activePage}
                    total={athleteList?.totalPages ?? 1}
                    onPageChange={handlePageChange}
                    maxWidth={20}
                    totalItemsCount={athleteList?.totalRecords ?? 1}
                    itemsCountPerPage={20}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <AthleteAddEditModal
          fetchData={fetchData}
          show={show}
          onHide={() => {
            setShow(false);
          }}
          // setActivePage={setActivePage}
          activePage={activePage}
          filtersData={filtersData}
        />
      )}
      {showAthlete && (
        <AthleteViewModal
          show={setShowAthlete}
          onHide={modalCloseHandler}
          id={id}
        />
      )}
      {showImportCSV ? (
        <FileUploadPopup
          showImportCSV={showImportCSV}
          onClose={handleClosePopup}
          onUpload={handleUploadFile}
        />
      ) : (
        <></>
      )}
      {importModel?.showImportErrorModel ? (
        <ImportErrorModel
          importModel={importModel}
          setImportModel={setImportModel}
          fetchData={fetchData}
          section='athlete'
        />
      ) : (
        <></>
      )}
      {importModel?.showSuccessModel ? (
        <SuccessModal
          importModel={importModel}
          setImportModel={setImportModel}
          fetchData={fetchData}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
export default ManageAthlete;
